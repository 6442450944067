import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type WorkoutEventType } from "@fitness-app/data-models/domain/services/ProgramService";
import { WorkoutSource } from "@fitness-app/data-models/entities/ClientWorkout";
import { type TrainingFeedback } from "@fitness-app/data-models/entities/Measurement";
import { type WorkoutActivityEventWithFeedback } from "@fitness-app/data-models/entities/ProgramActivity";
import { type ProgramWorkout, type TrainingStatus } from "@fitness-app/data-models/entities/TrainingProgram";
import { createProgramWorkoutTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchTraineeActivitiesForRange } from "./actions/fetchTraineeActivitiesForRange";
import { updateWorkoutActivity } from "./actions/updateWorkoutActivity";
import { TRAINEE_ACTIVITIES_REDUCER_NAME, type TraineeActivitiesReducer } from "./types";

const initialState: TraineeActivitiesReducer = {
  data: {
    currentWorkout: null,
    currentDay: "",
    isNewWorkout: null,
    workoutSource: null,
    editedDocId: null,
    workoutStatus: null,
    exerciseActivities: {},
  },
  status: null,
  error: null,
  workoutEditModalShown: false,
  workoutEditModalTouched: false,
  modalEditWeekScheduleShown: false,
  activities: [],
  activitiesStatus: null,
  exercisesStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_ACTIVITIES_REDUCER_NAME,
  reducers: {
    clearActivities: () => {
      return initialState;
    },
    updateWorkoutActivityRate: (state, { payload }: PayloadAction<TrainingFeedback>) => {
      if (!payload.data.activityId) {
        return;
      }
      state.activities = state.activities.map((activity) => {
        if (activity.id === payload.data.activityId) {
          return { ...activity, feedbackId: payload.id, workoutRate: payload.data };
        }
        return activity;
      });
    },
    setCurrentDayWorkout: (
      state,
      {
        payload,
      }: PayloadAction<{
        workout: WorkoutEventType["data"] | null;
        date: string;
        source?: WorkoutSource;
        id?: string;
        status: TrainingStatus | null;
      }>,
    ) => {
      const workout = payload.workout ?? createProgramWorkoutTemplate();
      state.data.currentWorkout = workout;
      state.data.currentDay = payload.date;
      state.data.isNewWorkout = !payload.workout;
      state.data.workoutSource = payload.source || WorkoutSource.FromSchedule;
      state.data.editedDocId = payload.id || null;
      state.data.workoutStatus = payload.status || state.data.workoutStatus;
    },
    setCurrentDay: (state, { payload }: PayloadAction<string>) => {
      state.data.currentDay = payload;
    },

    setWorkoutEditModalShown: (state) => {
      state.workoutEditModalShown = true;
    },
    setWorkoutEditModalHidden: (state) => {
      state.workoutEditModalShown = false;
      state.workoutEditModalTouched = false;
      state.data = initialState.data;
    },
    setModalEditWeekScheduleShown: (state) => {
      state.modalEditWeekScheduleShown = true;
    },
    setModalEditWeekScheduleHidden: (state) => {
      state.modalEditWeekScheduleShown = false;
    },
    setWorkoutEditModalTouched: (state) => {
      state.workoutEditModalTouched = true;
    },
    setWorkoutEditModalUntouched: (state) => {
      state.workoutEditModalTouched = false;
    },
    updateClientCurrentWorkout: (state, { payload }: PayloadAction<ProgramWorkout>) => {
      state.data.currentWorkout = payload;
      state.workoutEditModalTouched = true;
    },
    addActivity: (state, { payload }: PayloadAction<WorkoutActivityEventWithFeedback>) => {
      state.activities.push(payload);
    },
    updateActivity: (state, { payload }: PayloadAction<WorkoutActivityEventWithFeedback>) => {
      const index = state.activities.findIndex((activity) => activity.id === payload.id);
      if (index !== -1) {
        state.activities[index] = { ...state.activities[index], ...payload };
      }
    },
    deleteActivity: (state, { payload }: PayloadAction<string>) => {
      state.activities = state.activities.filter((activity) => activity.id !== payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTraineeActivitiesForRange.pending, (state, action) => {
      if (!action.meta.arg.optimistic) {
        state.activitiesStatus = RequestStatus.FETCHING;
      }
    });
    builder.addCase(fetchTraineeActivitiesForRange.fulfilled, (state, action) => {
      state.activitiesStatus = RequestStatus.SUCCESS;
      state.activities = action.payload;
    });
    builder.addCase(fetchTraineeActivitiesForRange.rejected, (state) => {
      state.activitiesStatus = RequestStatus.FAILED;
    });
    builder.addCase(updateWorkoutActivity.pending, (state, { meta }) => {
      state.activities = state.activities.map((activity) => {
        if (activity.id === meta.arg.id) {
          return { ...activity, ...meta.arg.activity };
        }
        return activity;
      });
    });
  },
});

export const {
  setCurrentDayWorkout,
  setCurrentDay,
  setWorkoutEditModalHidden,
  setWorkoutEditModalShown,
  updateClientCurrentWorkout,
  setWorkoutEditModalTouched,
  setWorkoutEditModalUntouched,
  setModalEditWeekScheduleShown,
  setModalEditWeekScheduleHidden,
  addActivity,
  updateActivity,
  deleteActivity,
  updateWorkoutActivityRate,
  clearActivities,
} = reducerSlice.actions;

export default reducerSlice.reducer;
