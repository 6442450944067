import { useMemo } from "react";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { ProductTask } from "@fitness-app/data-models/entities/Automation";
import { ProductChargeType, type ProductPrice } from "@fitness-app/data-models/entities/Product";

import { useAppSelector } from "~/store/initializeStore";

type RequiredField = "currency" | "unit_amount";

export const getProductPrice = (price: Pick<ProductPrice, RequiredField>): string => {
  if (!price.unit_amount) {
    return "";
  }

  return `${price.unit_amount / 100} ${price.currency.toUpperCase()}`;
};

export const useProductTaskAutomation = (productId?: string) => {
  const { t } = useTranslation("common");
  const { productsListData: products } = useAppSelector((store) => store.products);
  const productAutomationTaskOptions = useMemo(() => {
    return Object.values(ProductTask).map((value) => ({
      label: t(`common:productAutomationTask.${value}`),
      value,
    }));
  }, [t]);

  const productWithPrices = useMemo(() => {
    return products
      .filter((product) => product.id !== productId)
      .map((product) => ({
        name: product.name,
        id: product.id,
        isFree: product.chargeType === ProductChargeType.FREE,
        type: product.type,
        prices: product.prices
          ? product.prices.map(({ type, accessPeriod, id, currency, unit_amount }) => ({
              label: `${t(`products:paymentTypeOptions.${type}`)} ${
                type === "recurring" || (type === "one_time" && accessPeriod)
                  ? ` (${t(`products:${type}.${accessPeriod?.interval}`, {
                      count: accessPeriod?.interval_count,
                    })})`
                  : ""
              } - ${getProductPrice({
                currency,
                unit_amount,
              })}`,
              value: id,
            }))
          : null,
      }));
  }, [products, productId]);

  return {
    productAutomationTaskOptions,
    productWithPrices,
    productsById: keyBy(productWithPrices, "id"),
  };
};
