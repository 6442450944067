import React from "react";
import { CloseOutlined, ExportOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Card, Typography } from "antd";
import keyBy from "lodash.keyby";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import { chatActions } from "@fitness-app/app-store";
import { UserRole } from "@fitness-app/data-models";

import { UploadFilesProvider } from "~/components/Upload/UploadProvider";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import ChatWindow from "~/modules/Chat/ChatWindow";
import ChatMessageEditProvider from "~/modules/Chat/components/ChatMessageEditContext";
import { getUploadConfig } from "~/modules/Chat/uploadConfig";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ChatWindowsList = () => {
  const { activeChannels, unreadMessages } = useAppSelector((store) => store.chat, shallowEqual);
  const user = useAppSelector((store) => store.user.data);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isTrainer, isTeamMember } = useUserRole();

  if (!user) {
    return null;
  }

  const { id: userId } = user;

  return (
    <>
      <div className="pointer-events-none mb-1 mr-5 flex" id="chat">
        {activeChannels.map((channel) => {
          const clients = Object.values(channel.members).filter((user) => user.role === UserRole.CLIENT);
          let member = clients.length === 1 ? clients.find((chatMember) => chatMember.uid !== userId) : null;
          if (!member && channel.assignedUsers?.length === 2) {
            member = Object.values(channel.members).find((chatMember) => chatMember.uid !== userId);
          }
          const profilesById = channel.profiles ? keyBy(channel.profiles, "id") : null;

          return (
            <ChatMessageEditProvider key={channel.id}>
              <UploadFilesProvider {...getUploadConfig(channel.id)}>
                <Card
                  bordered
                  title={
                    <div
                      role="button"
                      className="flex cursor-pointer items-center overflow-visible"
                      onClick={() => {
                        dispatch(chatActions.toggleChannelView(channel.id));
                      }}
                    >
                      <Badge dot count={(unreadMessages?.perChannel?.[channel.id] || []).length}>
                        <Avatar
                          shape="circle"
                          icon={<UserOutlined />}
                          src={
                            member?.uid ? profilesById?.[member.uid]?.avatarUrl || member?.avatarUrl : member?.avatarUrl
                          }
                          size={22}
                        />
                      </Badge>
                      <h4 className="ml-2">
                        {member?.clientId ? (
                          <div className="flex">
                            <a
                              role="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/trainee/details/${member?.clientId}`);
                              }}
                            >
                              {member?.name}
                            </a>
                            {isTrainer || isTeamMember ? (
                              <Typography.Link
                                style={{ padding: 0, display: "inline", marginLeft: 8 }}
                                title="Otwórz w nowy oknie"
                                href={`${window.location.origin}/trainee/details/${member?.clientId}`}
                                target="_blank"
                              >
                                <ExportOutlined />
                              </Typography.Link>
                            ) : null}
                          </div>
                        ) : (
                          member?.name || "Czat grupowy"
                        )}
                      </h4>
                    </div>
                  }
                  style={{ transform: `translateY(${channel.minimized ? "94%" : "0%"})` }}
                  extra={
                    <div>
                      <CloseOutlined
                        style={{ fontSize: 15 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(chatActions.removeChannelFromList(channel.id));
                        }}
                      />
                    </div>
                  }
                  size="small"
                  className="pointer-events-auto relative m-0 mx-2 w-80 shadow-md transition-all duration-200 ease-in-out"
                  bodyStyle={{ padding: 0 }}
                >
                  <ChatWindow channel={channel} minimized={channel.minimized} />
                </Card>
              </UploadFilesProvider>
            </ChatMessageEditProvider>
          );
        })}
      </div>
    </>
  );
};

export default ChatWindowsList;
