import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";

import { WorkoutSource } from "@fitness-app/data-models/entities/ClientWorkout";
import {
  ActivityEventType,
  type WorkoutActivityEvent,
  type WorkoutActivityEventWithFeedback,
} from "@fitness-app/data-models/entities/ProgramActivity";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { type AsyncThunkCreator } from "../../../index";
import { addActivity, setWorkoutEditModalHidden, updateActivity } from "../reducer";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";

type Payload = { isMobile: boolean } | undefined;

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const saveWorkoutToSpecifiedDay = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/saveWorkoutToSpecifiedDay`,
  async (payload, { getState, dispatch, extra: { db, analytics, errorTrackingService } }) => {
    const { workoutSource, editedDocId, isNewWorkout, currentWorkout, currentDay } = getState().traineeActivities.data;
    const { selectedProgram } = getState().traineeProgram;
    const { profile } = getState().trainee;

    if (!currentWorkout) {
      throw new Error("currentWorkout is not defined");
    }

    if (workoutSource === WorkoutSource.FromActivity && editedDocId) {
      const { data, error } = await db
        .from("client_workout_activity")
        .update({
          updatedAt: new Date().toISOString(),
          data: {
            ...currentWorkout,
            updatedAt: new Date().toISOString(),
          },
        })
        .eq("id", editedDocId)
        .select("*")
        .single<WorkoutActivityEvent>();

      if (error) {
        errorTrackingService?.recordError(error, "updateSavedWorkoutToSpecifiedDay");
      }
      if (data) {
        dispatch(updateActivity(data));
      }
      if (!payload?.isMobile) {
        dispatch(setWorkoutEditModalHidden());
      }
      return editedDocId;
    }

    if (!selectedProgram || !profile) {
      throw new Error("selectedProgram or profile is not defined");
    }

    const date = dayjs(currentDay, "YYYY-MM-DD").hour(12);

    let event: WorkoutActivityEvent = {
      id: generateUniqId(),
      type: ActivityEventType.Workout,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      eventDate: date.format("YYYY-MM-DD"),
      eventMonth: date.format("YYYY-MM"),
      eventWeek: `${date.format("YYYY")}-${date.isoWeek()}`,
      programId: date.isSameOrAfter(selectedProgram.startDate, "day") ? selectedProgram.id : null,
      traineeId: profile.id,
      clientEmail: profile.email,
      userId: profile.userId,
      eventTimestamp: date.toISOString(),
      workoutId: currentWorkout.id,
      data: {
        ...currentWorkout,
        replicatedFromSchedule: !isNewWorkout,
        updatedAt: new Date().toISOString(),
      },
      feedbackId: null,
    };

    const { error, data } = await db
      .from("client_workout_activity")
      .insert(event)
      .select("*")
      .single<WorkoutActivityEvent>();

    if (data) {
      event = data;
    }

    if (error?.message.includes("duplicate key value violates unique constraint")) {
      const { error: fetchError, data: savedEvent } = await db
        .from("client_workout_activity")
        .select(
          "*, workoutRate:feedbackId(data->activityId, data->workoutId, data->rate, data->comment, data->trainingStatus, data->programId)",
        )
        .eq("traineeId", event.traineeId)
        .eq("eventDate", event.eventDate)
        .single<WorkoutActivityEventWithFeedback>();

      if (fetchError) {
        errorTrackingService?.recordError(fetchError, "errorFetchingSavedWorkout");
        throw fetchError;
      }

      if (savedEvent) {
        event = savedEvent;
      }

      const { data, error } = await db
        .from("client_workout_activity")
        .update({
          updatedAt: new Date().toISOString(),
          data: {
            ...currentWorkout,
            updatedAt: new Date().toISOString(),
          },
        })
        .eq("id", savedEvent.id)
        .select("*")
        .single<WorkoutActivityEvent>();

      if (error) {
        errorTrackingService?.recordError(error, "updateSavedWorkoutToSpecifiedDay");
      }
      if (data) {
        dispatch(updateActivity(data));
      }
      if (!payload?.isMobile) {
        dispatch(setWorkoutEditModalHidden());
      }
      return event.id;
    }

    if (error) {
      errorTrackingService?.recordError(error, "saveWorkoutToSpecifiedDay");
      throw error;
    }

    dispatch(addActivity(event));
    if (!payload?.isMobile) {
      dispatch(setWorkoutEditModalHidden());
    }

    analytics.track("tracked_workout_activity");

    return event.id;
  },
);
