import { type Database } from "@fitness-app/supabase";

import { type BusinessEventPayload } from "../businessEvents/domain/BusinessEventEnvelope";
import { type BusinessEventType } from "../businessEvents/domain/businessEventTypes";
import {
  type DiscordAutomationTask,
  type EmailClientAutomationTask,
  type FitnessAppAutomationTask,
  type ProductAutomationTask,
  type Webhook,
} from "./Automation";
import { type EmailClient } from "./ProductsSettings";

export enum ProductAutomationStatus {
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export type EmailTaskLog = Omit<EmailClientAutomationTask, "triggers" | "active" | "id"> & {
  emailClient: EmailClient;
  accountId: string;
};

export type DiscordTaskLog = Omit<DiscordAutomationTask, "triggers" | "active" | "id">;

export type ProductTaskLog = Omit<ProductAutomationTask, "triggers" | "active" | "id">;

export type FitnessTaskLog = Omit<FitnessAppAutomationTask, "triggers" | "active" | "id">;

export type AutomationLogEntity = Database["public"]["Tables"]["automation_log"]["Row"];
export interface DefaultAutomationLog
  extends Omit<AutomationLogEntity, "metadata" | "requireAction" | "errorMessage" | "errorCode"> {
  createdAt: string;
  updatedAt: string;
  id: string;
  status: ProductAutomationStatus;
  attempt: number;
  shouldRetry: boolean;
  timestamp: number;
  productId: string;
  clientEmail: string;
  ownerId: string;
  task:
    | EmailTaskLog
    | DiscordTaskLog
    | ProductTaskLog
    | FitnessTaskLog
    | (Webhook & { type: "webhook"; action?: never });
  trigger: BusinessEventType;
  taskPayload: BusinessEventPayload;
  metadata?: Record<string, string | number | boolean | null>;
}

export interface SuccessAutomationLog extends DefaultAutomationLog {
  status: ProductAutomationStatus.Success;
  requireAction?: never;
  errorMessage?: never;
  errorCode?: never;
}

export interface WarnAutomationLog extends DefaultAutomationLog {
  status: ProductAutomationStatus.Warning;
  requireAction: {
    nextStepOwner: string | null;
    status: string;
    [key: string]: string | null;
  };
  errorMessage?: never;
  errorCode?: never;
}

export interface ErrorAutomationLog extends DefaultAutomationLog {
  status: ProductAutomationStatus.Error;
  errorMessage: string;
  errorCode: string;
  requireAction?: never;
}

export type ProductAutomationLog = SuccessAutomationLog | WarnAutomationLog | ErrorAutomationLog;

export type CreateProductAutomationLogDto = Omit<ProductAutomationLog, "createdAt" | "updatedAt">;

export type AutomationLogDto = Omit<
  CreateProductAutomationLogDto,
  "ownerId" | "productId" | "shouldRetry" | "attempt" | "taskPayload" | "trigger"
>;
