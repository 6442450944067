import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import {
  ClientNutritionStatus,
  type ClientMealsPlanDetails,
  type ClientNutrition,
} from "@fitness-app/data-models/entities/ClientNutrition";

import { type AsyncThunkCreator } from "../../../index";
import { updateClientTask } from "../../clientScheduledTasks/actions";
import { MEALS_PLANS_REDUCER_NAME } from "../types";
import { fetchMealsPlanWithDetails } from "./fetchMealsPlanWithDetails";

type Payload = {
  mealsPlan: Partial<ClientNutrition> & { id: string };
  mealsPlanDetails?:
    | (Partial<ClientMealsPlanDetails> & { id: string; planId?: string })
    | (Partial<ClientMealsPlanDetails> & { planId: string; id?: string })
    | null;
};

export const updateMealsPlan = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${MEALS_PLANS_REDUCER_NAME}/updateMealsPlan`,
  async (payload, { rejectWithValue, dispatch, getState, extra: { db } }) => {
    const { error } = await db.from("client_meals_plan").update(payload.mealsPlan).eq("id", payload.mealsPlan.id);

    if (error) {
      return rejectWithValue(error.message);
    }

    if (payload.mealsPlanDetails) {
      const id = payload.mealsPlanDetails?.id || payload.mealsPlan.id;

      await db
        .from("client_meals_plan_details")
        .update(payload.mealsPlanDetails)
        .eq(payload.mealsPlanDetails?.id ? "id" : "planId", id);
    }

    if (
      payload.mealsPlan.metadata?.taskId &&
      (payload.mealsPlan.status === ClientNutritionStatus.Scheduled ||
        getState().traineeMealsPlan.selectedMealsPlan?.status === ClientNutritionStatus.Scheduled) &&
      payload.mealsPlan.startAt
    ) {
      const sendDate = dayjs(payload.mealsPlan.startAt).set("hour", 6);
      await dispatch(
        updateClientTask({
          id: payload.mealsPlan.metadata.taskId,
          task: {
            sendDate: sendDate.toISOString(),
            sendAt: sendDate.unix(),
            updatedAt: new Date().toISOString(),
          },
        }),
      ).unwrap();
    }

    void dispatch(fetchMealsPlanWithDetails({ id: payload.mealsPlan.id }));
  },
);
