import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { productsActions, productsSettingsActions } from "@fitness-app/app-store";

import Product from "~/modules/Products/Product/Product";
import ProductRedirect from "~/modules/Products/Product/ProductRedirect";
import Products from "~/modules/Products/Products";
import { useAppDispatch } from "~/store/initializeStore";

const ProductsRouter = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(productsActions.subscribeToProducts());
    dispatch(productsSettingsActions.subscribeToProductsSettings());

    return () => {
      dispatch(productsActions.unsubscribeFromProducts());
      dispatch(productsSettingsActions.unsubscribeFromProductsSettings());
    };
  }, []);

  return (
    <Routes>
      <Route index element={<Products />} />
      <Route path="/:id" element={<ProductRedirect />} />
      <Route path="/:id/:tab/*" element={<Product />} />
    </Routes>
  );
};

export default ProductsRouter;
