import { createAsyncThunk } from "@reduxjs/toolkit";

import { type UserRoleEnum } from "@fitness-app/data-models";

import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";

export type TaskDate = {
  sendAt: number;
  sendDate: string;
  sentTime: {
    hours: number;
    minutes: number;
  };
};
type Payload =
  | {
      clients: {
        id: string;
        userId: string;
        email: string;
        firstName: string;
        lastName: string | null;
        assignedTrainerId: string | null;
      }[];
      messages: string[];
      authorId: string;
      authorRole: UserRoleEnum;
      authorName: string;
      authorAvatar: string | null;
      sendImmediately: boolean;
      dates?: TaskDate[];
    }
  | {
      tags: string[];
      condition: "hasTag" | "notHasTag";
      messages: string[];
      authorId: string;
      authorRole: UserRoleEnum;
      authorName: string;
      authorAvatar: string | null;
      sendImmediately: boolean;
      dates?: TaskDate[];
    };

export const sendMessageToMultipleUsers = createAsyncThunk<
  { count: number; clients: number },
  Payload,
  AsyncThunkCreator<string>
>(`${CHAT_REDUCER_NAME}/sendMessageToMultipleUsers`, async (payload, { extra: { chatApi } }) => {
  if (!chatApi) {
    throw new Error("chatApi is not defined");
  }

  const response = await chatApi.post<{ count: number; clients: number }>("/send-message-to-multiple-users", payload);

  return response.data;
});
