import { createAsyncThunk } from "@reduxjs/toolkit";

import { type MediaLibraryItemWithLinkedExercise } from "@fitness-app/data-models/entities/MediaLibrary";

import { type AsyncThunkCreator } from "../../../index";
import { MEDIA_LIBRARY_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchMediaLibraryFiles = createAsyncThunk<
  MediaLibraryItemWithLinkedExercise[],
  Payload,
  AsyncThunkCreator<string>
>(`${MEDIA_LIBRARY_REDUCER_NAME}/fetchMediaLibraryFiles`, async (payload, { rejectWithValue, extra: { db } }) => {
  const { error, data } = await db
    .from("media_library")
    .select("*, exercises:exercise(name, id)")
    .order("title")
    .returns<MediaLibraryItemWithLinkedExercise[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return data;
});
