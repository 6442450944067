import React, { memo } from "react";
import { Dropdown, Menu } from "antd";
import dayjs from "dayjs";

import { traineeActivitiesActions } from "@fitness-app/app-store";

import { useAutomatedMessagesFormContext } from "~/shared/providers/AutomatedMessagesFormProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface CustomDateCellProps {
  children: React.ReactNode;
  setCurrentDate: (date: Date) => void;
  value: Date;
}

const CustomDateCell = ({ value, setCurrentDate, children }: CustomDateCellProps) => {
  const traineeProgram = useAppSelector((store) => store.traineeProgram.selectedProgram);
  const dispatch = useAppDispatch();
  const { addNewMessage } = useAutomatedMessagesFormContext();

  const menu = traineeProgram?.id ? (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          dispatch(
            traineeActivitiesActions.setCurrentDayWorkout({
              date: dayjs(value).format("YYYY-MM-DD"),
              workout: null,
              status: null,
            }),
          );
          dispatch(traineeActivitiesActions.setWorkoutEditModalShown());
        }}
      >
        Dodaj trening
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setCurrentDate(value);
          dispatch(traineeActivitiesActions.setModalEditWeekScheduleShown());
        }}
      >
        Zmień harmonogram tygodnia
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setCurrentDate(value);
          addNewMessage(dayjs(value));
        }}
      >
        Dodaj automatyczną wiadomość
      </Menu.Item>
    </Menu>
  ) : undefined;

  return (
    <div className="rbc-day-bg">
      <Dropdown overlay={menu} trigger={["contextMenu"]}>
        <div style={{ width: "100%", height: "100%", paddingLeft: 5 }}>{children}</div>
      </Dropdown>
    </div>
  );
};

export default memo(CustomDateCell);
