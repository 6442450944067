import { createAsyncThunk } from "@reduxjs/toolkit";

import { NotificationStatus } from "@fitness-app/data-models/entities/Notification";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { NOTIFICATIONS_REDUCER_NAME } from "../types";

type Payload = void;

export const markAllAsRead = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${NOTIFICATIONS_REDUCER_NAME}/markAllAsRead`,
  async (_payload, { rejectWithValue, extra: { db, auth, analytics } }) => {
    const currentUser = await getLoggedUser(auth);

    const { error } = await db
      .from("notification")
      .update({ status: NotificationStatus.READ, updatedAt: new Date().toISOString() })
      .eq("ownerId", currentUser.id);

    analytics.track("mark_all_notifications_as_read");

    if (error) {
      return rejectWithValue(error.message);
    }
  },
);
