import { MealType } from "@fitness-app/data-models/entities/Dish";

export const colors = {
  gold8: "#71624B",
  text: "#202020",
  gold9: "#978365",
  gray6: "#DAD9D6",
  gray: "#646464",
  gray10: "#7c7b74",
};
export const weekdaysTranslation = {
  monday: "Poniedziałek",
  tuesday: "Wtorek",
  wednesday: "Środa",
  thursday: "Czwartek",
  friday: "Piątek",
  saturday: "Sobota",
  sunday: "Niedziela",
} as const;

export const mealTranslation = {
  [MealType.BREAKFAST]: "Śniadanie",
  [MealType.DINNER]: "Obiad",
  [MealType.SUPPER]: "Kolacja",
  [MealType.SNACK]: "Przekąska",
  [MealType.LUNCH]: "Lunch",
  [MealType.SECOND_BREAKFAST]: "II śniadanie",
} as const;

export const measureTranslations: Record<string, string> = {
  none: "Brak",
  handful: "Garść",
  spoon: "Łyżka",
  piece: "Sztuka",
  liter: "Litr",
  portion: "Porcja",
  smallSlice: "Plasterek",
  package: "Opakowanie",
  slice: "Plaster",
  teaspoon: "Łyżeczka",
  leaf: "Liść",
  glass: "Szklanka",
  cube: "Kostka",
  clove: "Ząbek",
  stalk: "Łodyga",
  drop: "Kropla",
  pinch: "Szczypta",
  flake: "Płatek",
  tube: "Rurka",
  capsule: "Kapsułka",
  scoop: "Miarka",
  bunch: "Pęczek",
  tablet: "Tabletka",
  gram: "g",
  ml: "ml",
};
