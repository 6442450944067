import React, { memo, useCallback, useMemo, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { Button, Empty } from "antd";
import flatten from "lodash.flatten";
import xor from "lodash.xor";
import { useTranslation } from "react-i18next";

import {
  type ExerciseInWorkout,
  type SuperSet,
  type WorkoutExercisesType,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { prepareMergedWorkoutExercises } from "@fitness-app/utils/src/programs/prepareMergedWorkoutExercises";

import { SortableItem } from "~/components/Dnd/SortableItem";
import ExerciseInProgramItem from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/ExerciseInProgramItem/ExerciseInProgramItem";
import WorkoutDayFooter from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/components/WorkoutDayFooter";
import { useWorkoutActionProviderContext } from "~/modules/TrainingPrograms/ProgramBuilder/WorkoutDay/providers/WorkoutActionProvider";
import { useProgramBuilderContext, useProgramIsTouched } from "~/shared/providers/ProgramBuilderProvider";

interface WorkoutExercisesProps {
  exercises: ExerciseInWorkout[];
  exercisesType: WorkoutExercisesType;
  workoutId: string;
}

const WorkoutExercises = ({ exercises, exercisesType, workoutId }: WorkoutExercisesProps): React.ReactElement => {
  const { setNodeRef } = useDroppable({
    id: `PLACEHOLDER__${workoutId}__${exercisesType}`,
  });
  const { t } = useTranslation("workouts");

  const [selectedExercisesKeys, setSelectedExercisesKeys] = useState<string[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const {
    openExerciseForm,
    groupExercisesIntoSuperSet,
    splitExercisesFromSuperSet,
    deleteExerciseFromWorkout,
    handleRemoveSeriesFromExercise,
    handleAddSeriesToExercise,
    handleUpdateSuperSetSeries,
    updateExerciseInWorkout,
    toggleRecordingVideoFlag,
  } = useWorkoutActionProviderContext();
  const { showRecordingVideoSwitch } = useProgramBuilderContext();

  const touched = useProgramIsTouched();
  const mergedExercises = useMemo(() => prepareMergedWorkoutExercises(exercises), [exercises]);
  const items = useMemo(() => mergedExercises.map((exe) => exe.id), [mergedExercises]);

  const getIndex = useCallback(
    (id: string) => {
      const index = items.indexOf(id);

      return index;
    },
    [items],
  );

  const onExerciseSelect = (exercise: ExerciseInWorkout | SuperSet) => {
    setSelectedRowIds((prev) => xor(prev, [exercise.id]));
    setSelectedExercisesKeys((prev) =>
      xor(prev, flatten(["mergedExercisesIds" in exercise ? exercise.mergedExercisesIds : exercise.id])),
    );
  };
  const groupInSuperSet = () => {
    groupExercisesIntoSuperSet(selectedExercisesKeys, exercisesType, workoutId);
    setSelectedRowIds([]);
    setSelectedExercisesKeys([]);
  };

  const splitSuperSet = (recordToSplit: SuperSet) => {
    if (recordToSplit?.mergedExercisesIds.length) {
      splitExercisesFromSuperSet(recordToSplit.mergedExercisesIds, exercisesType, workoutId);
    }
  };

  const toggleRecordingFlag = useCallback(
    (exerciseId: string, checked: boolean) => {
      if (toggleRecordingVideoFlag && showRecordingVideoSwitch) {
        return toggleRecordingVideoFlag(exerciseId, checked, exercisesType);
      }
      return undefined;
    },
    [exercisesType, showRecordingVideoSwitch],
  );

  return (
    <div>
      <div ref={setNodeRef}>
        {items.length === 0 && (
          <div>
            <Empty
              imageStyle={{ height: 30 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div>
                  <p className="mb-5">{t("exerciseInProgram.emptyList")}</p>
                </div>
              }
            >
              <Button
                size="small"
                type="primary"
                disabled={touched}
                icon={<PlusOutlined />}
                onClick={() => openExerciseForm(exercisesType)}
                className="mb-5 self-center"
              >
                {t("workout.addExercise")}
              </Button>
            </Empty>
          </div>
        )}
        <SortableContext items={items}>
          {mergedExercises.map((item, index) => (
            <SortableItem
              disabled={touched}
              key={item.id}
              id={item.id}
              index={index}
              containerId={exercisesType}
              getIndex={getIndex}
              handle
            >
              <ExerciseInProgramItem
                touched={touched}
                toggleRecordingVideoFlag={showRecordingVideoSwitch ? toggleRecordingFlag : undefined}
                exerciseInWorkout={item}
                onExerciseSelect={() => onExerciseSelect(item)}
                isSelected={selectedRowIds.includes(item.id)}
                splitSuperSet={splitSuperSet}
                deleteExerciseFromWorkout={(id) => deleteExerciseFromWorkout(id, exercisesType)}
                addSeriesToExercise={() => handleAddSeriesToExercise(item.id, exercisesType)}
                removeSeriesFromExercise={(setNumber) =>
                  handleRemoveSeriesFromExercise(setNumber, item.id, exercisesType)
                }
                changeNumberOfSeries={(numberOfSeries, exercisesIds) =>
                  handleUpdateSuperSetSeries(numberOfSeries, exercisesIds, exercisesType)
                }
                updateExerciseInWorkout={(exercise) => updateExerciseInWorkout(exercise, exercisesType)}
              />
            </SortableItem>
          ))}
        </SortableContext>
      </div>
      <WorkoutDayFooter
        exercisesType={exercisesType}
        visible={!!exercises.length}
        onMergeIntoSuperSet={selectedRowIds.length > 1 ? groupInSuperSet : undefined}
      />
    </div>
  );
};

export default memo(WorkoutExercises);
