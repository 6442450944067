import React, { useEffect, useState, type FunctionComponent } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Typography,
} from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { productActions } from "@fitness-app/app-store";
import { ProductPriceAction, type ProductPrice } from "@fitness-app/data-models/entities/Product";

import { useAppDispatch } from "~/store/initializeStore";

interface OwnProps {
  price: ProductPrice;
  extended: boolean;
  productId: string;
  productPricesActions: null | { id: string; action: ProductPriceAction }[];
  canRemove: boolean;
}

type Props = OwnProps;

interface ConfigFormModel {
  showAsPromo: boolean;
  priceBeforeDiscount?: number;
  showTimeLimit: boolean;
  priceTimeLimit?: Dayjs | null;
  showQuantityLimit: boolean;
  priceQuantityLimit?: number | null;
  showQuantityAvailable: boolean;
  showAdditionalInfo: boolean;
  priceAdditionalInfo?: string | null;
}

const PriceConfig: FunctionComponent<Props> = ({ price, extended, productPricesActions, productId, canRemove }) => {
  const [showPromoModal, togglePromoModal] = useState(false);
  const [formController] = Form.useForm<ConfigFormModel>();
  const showAsPromo = Form.useWatch("showAsPromo", formController);
  const showTimeLimit = Form.useWatch("showTimeLimit", formController);
  const showQuantityLimit = Form.useWatch("showQuantityLimit", formController);
  const showQuantityAvailable = Form.useWatch("showQuantityAvailable", formController);
  const showAdditionalInfo = Form.useWatch("showAdditionalInfo", formController);
  const [saving, toggleSavingFlag] = useState(false);
  const { t } = useTranslation("products");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showPromoModal) {
      const showAsPromo = Boolean(price.config?.showAsPromo);
      const showTimeLimit = Boolean(price.config?.activeUntil);
      const showQuantityLimit = Boolean(price.config?.quantityLimit);
      const showQuantityAvailable = Boolean(price.config?.showAvailable);
      const showAdditionalInfo = Boolean(price.config?.additionalInfo);
      formController?.setFieldsValue({
        showAsPromo,
        priceBeforeDiscount: price.config?.showAsPromo ? price.config.showAsPromo.priceBeforeDiscount / 100 : 0,
        showTimeLimit,
        priceTimeLimit: price.config?.activeUntil ? dayjs(price.config?.activeUntil) : null,
        showQuantityLimit,
        priceQuantityLimit: price.config?.quantityLimit ?? 100,
        showQuantityAvailable,
        showAdditionalInfo,
        priceAdditionalInfo: price.config?.additionalInfo,
      });
    } else {
      setTimeout(() => {
        formController.resetFields();
      }, 300);
    }
  }, [showPromoModal]);

  const onConfigFormSubmit = async (data: ConfigFormModel) => {
    toggleSavingFlag(true);

    try {
      await dispatch(
        productActions.updateProductPriceConfig({
          priceId: price.id,
          productId,
          config: {
            additionalInfo: data.showAdditionalInfo ? data.priceAdditionalInfo : null,
            activeUntil: data.showTimeLimit ? data.priceTimeLimit?.valueOf() ?? null : null,
            quantityLimit: data.showQuantityLimit ? data.priceQuantityLimit : null,
            showAvailable: data.showQuantityAvailable ?? false,
            showAsPromo:
              data.showAsPromo && data.priceBeforeDiscount
                ? { priceBeforeDiscount: data.priceBeforeDiscount * 100 }
                : false,
          },
        }),
      ).unwrap();
      toggleSavingFlag(false);
      togglePromoModal(false);
    } catch {
      toggleSavingFlag(false);
      void message.error("common:messages.error.default");
    }
  };

  const currentlyProcessed = productPricesActions ? productPricesActions.find((item) => item.id === price.id) : null;

  return (
    <>
      <Space>
        {extended && (
          <Button type="link" onClick={() => togglePromoModal(true)}>
            {t("common:button.configure")}
          </Button>
        )}
        {price.clients ? (
          <Popconfirm
            overlayStyle={{ maxWidth: 400 }}
            placement="leftTop"
            disabled={!canRemove}
            title={t("mailing.archivePriceWarning")}
            onConfirm={() =>
              dispatch(
                productActions.updateProductPrices({
                  onFailure: () => {
                    void message.error(t("errorWhenUpdatingPrice"));
                  },
                  payload: {
                    prices: [
                      {
                        productId,
                        priceId: price.id,
                        action: ProductPriceAction.Archive,
                      },
                    ],
                  },
                }),
              )
            }
          >
            <Button
              type="link"
              danger
              disabled={!canRemove}
              loading={currentlyProcessed ? currentlyProcessed.action === ProductPriceAction.Archive : false}
            >
              {t("common:button.archive")}
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            overlayStyle={{ maxWidth: 400 }}
            placement="leftTop"
            disabled={!canRemove}
            title={t("mailing.deletePriceWarning")}
            onConfirm={() =>
              dispatch(
                productActions.updateProductPrices({
                  onFailure: () => {
                    void message.error(t("errorWhenUpdatingPrice"));
                  },
                  payload: {
                    prices: [
                      {
                        productId,
                        priceId: price.id,
                        action: ProductPriceAction.Remove,
                      },
                    ],
                  },
                }),
              )
            }
          >
            <Button
              type="link"
              danger
              disabled={!canRemove}
              loading={currentlyProcessed ? currentlyProcessed.action === ProductPriceAction.Remove : false}
            >
              {t("common:button.delete")}
            </Button>
          </Popconfirm>
        )}
      </Space>
      <Modal
        visible={showPromoModal}
        width={600}
        onCancel={() => {
          togglePromoModal(false);
        }}
        okText={t("common:button.save")}
        title={t("prices.modalTitle")}
        onOk={() => formController?.submit()}
        okButtonProps={{
          loading: saving,
          disabled: saving,
        }}
      >
        <Form<ConfigFormModel>
          wrapperCol={{ span: 16 }}
          onFinish={onConfigFormSubmit}
          form={formController}
          initialValues={{
            showAsPromo: false,
            priceBeforeDiscount: 0,
            showTimeLimit: false,
            priceTimeLimit: null,
            showQuantityLimit: false,
            priceQuantityLimit: 100,
            showQuantityAvaliable: false,
          }}
        >
          <Form.Item label={t("prices.showAsPromo")} name="showAsPromo" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showAsPromo && (
            <Form.Item
              label={t("prices.priceBefore")}
              name="priceBeforeDiscount"
              tooltip={t("prices.willBeShowsStrikethrough")}
              rules={[
                {
                  required: showAsPromo,
                  type: "number",
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
                {
                  validator: (_, value) =>
                    value > (price.unit_amount ?? 0) / 100
                      ? Promise.resolve()
                      : Promise.reject(new Error(t<string>("prices.cannotBeLessThanCurrent"))),
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100000}
                precision={2}
                addonAfter={price.currency.toUpperCase()}
                decimalSeparator={price.currency === "pln" ? "," : "."}
              />
            </Form.Item>
          )}

          <Form.Item label={t("prices.showTimeLimit")} name="showTimeLimit" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showTimeLimit && (
            <Form.Item
              label={t("prices.priceTime")}
              name="priceTimeLimit"
              tooltip={t("prices.willBeShowsTimer")}
              rules={[
                {
                  required: showTimeLimit,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
                {
                  validator: (_, value) =>
                    value > Date.now()
                      ? Promise.resolve()
                      : Promise.reject(new Error(t<string>("prices.cannotBeEarlierThanNow"))),
                },
              ]}
            >
              <DatePicker
                showTime
                format="YYYY-MM-DD | HH:mm"
                disabledDate={(current) => current && current.valueOf() < Date.now() - 60 * 60 * 24}
              />
            </Form.Item>
          )}

          <Form.Item label={t("prices.showQuantityLimit")} name="showQuantityLimit" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showQuantityLimit && (
            <>
              <Form.Item
                label={t("prices.priceQuantity")}
                name="priceQuantityLimit"
                tooltip={t("prices.willBeShowsQuantity")}
                rules={[
                  {
                    required: showQuantityLimit,
                    type: "number",
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                  {
                    validator: (_, value) =>
                      value >= price.clients
                        ? Promise.resolve()
                        : Promise.reject(new Error(t<string>("prices.cannotBeLessThanSold"))),
                  },
                ]}
              >
                <InputNumber min={0} max={100000} />
              </Form.Item>
              <Form.Item label={t("prices.showQuantityAvailable")} name="showQuantityAvailable" valuePropName="checked">
                <Switch />
              </Form.Item>
              {showQuantityAvailable && (
                <div className="-mt-4 mb-4">
                  <Typography.Text type="secondary">{t("prices.quantityAvailableExample")}</Typography.Text>
                </div>
              )}
            </>
          )}
          <Form.Item label={t("prices.showAdditionalInfo")} name="showAdditionalInfo" valuePropName="checked">
            <Switch />
          </Form.Item>
          {showAdditionalInfo && (
            <>
              <Form.Item
                label={t("prices.additionalInfo")}
                name="priceAdditionalInfo"
                tooltip={t("prices.willBeShowsInfo")}
                rules={[
                  {
                    required: showAdditionalInfo,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                ]}
              >
                <Input style={{ maxWidth: 280 }} />
              </Form.Item>
              <div className="-mt-4 mb-4">
                <Typography.Text type="secondary">{t("prices.additionalInfoExample")}</Typography.Text>
              </div>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default PriceConfig;
