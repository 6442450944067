import { useState } from "react";
import { type FormInstance } from "antd/lib/form";

import { DEFAULT_LEGAL_INFO, DEFAULT_VAT_RATE } from "~/modules/Products/ProductsSettings/Invoices/clients/constants";
import { type IFirmaIntegrationFormModel } from "~/modules/Products/ProductsSettings/Invoices/clients/iFirma/types";
import { type InFaktIntegrationFormModel } from "~/modules/Products/ProductsSettings/Invoices/clients/inFakt/types";
import { type WFirmaIntegrationFormModel } from "~/modules/Products/ProductsSettings/Invoices/clients/wFirma/types";
import { type IFakturowniaIntegrationForm } from "../Fakturownia/types";

export const useVatIssuer = (
  defaultValue = true,
  formController?: FormInstance<
    IFirmaIntegrationFormModel | IFakturowniaIntegrationForm | WFirmaIntegrationFormModel | InFaktIntegrationFormModel
  >,
) => {
  const [isVatIssuer, toggleVatIssuer] = useState(defaultValue);

  const onChangeVatIssuerField = (checked: boolean) => {
    toggleVatIssuer(checked);
    if (checked) {
      formController?.setFields([
        {
          name: ["settings", "defaultVatRate"],
          value: DEFAULT_VAT_RATE,
        },
      ]);
    } else {
      formController?.setFields([
        {
          name: ["settings", "legalVatExemption"],
          value: DEFAULT_LEGAL_INFO,
        },
      ]);
    }
  };

  return {
    isVatIssuer,
    onChangeVatIssuerField,
  };
};
