import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { MEDIA_LIBRARY_REDUCER_NAME } from "../types";

type Payload = {
  title: string;
  id: string;
  size: number;
  type: string;
};

interface Response {
  status: string;
  id: string;
  signature: string;
  expire: number;
  libraryId: string;
}

export const addFileToMediaLibrary = createAsyncThunk<Response, Payload, AsyncThunkCreator<string>>(
  `${MEDIA_LIBRARY_REDUCER_NAME}/addFileToMediaLibrary`,
  async (payload, { rejectWithValue, extra: { mediaLibraryApi, analytics } }) => {
    try {
      const response = await mediaLibraryApi.post<Response>("/create-video", payload);
      analytics.track("created_new_video", { size: payload.size, type: payload.type });

      return response.data;
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
