import { StyleSheet } from "@react-pdf/renderer";

import { colors } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNutrition/TraineePdfGenerator/components/constants";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 75,
    paddingHorizontal: 35,
    fontFamily: "Inter",
    color: "#202020",
  },
  title: {
    fontSize: 24,
    marginLeft: 10,
    color: "#71624B",
  },
  numberOfMeals: {
    fontSize: 12,
  },
  calories: {
    fontSize: 12,
    marginLeft: 10,
  },
  macros: {
    fontSize: 12,
    marginLeft: 5,
  },
  macrosBold: {
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 13,
    textAlign: "justify",
  },
  image: {
    width: 24,
    height: 24,
  },
  suppleImg: {
    width: 18,
  },
  basketImg: {
    width: 24,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  macrosContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  macroItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  mealContainer: {
    marginTop: 30,
  },
  mealHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mealMacrosContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    paddingBottom: 5,
    borderBottomColor: "#e8e9fe",
  },
  productsMacroItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    marginTop: 10,
  },
  dayContainer: {
    marginBottom: 10,
  },
  productContainer: {
    display: "flex",
    marginTop: 5,
    borderBottomWidth: 1,
    paddingBottom: 5,
    borderBottomColor: "#e8e9fe",
  },
  productHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productText: {
    fontSize: 12,
  },
  productMacroText: {
    fontSize: 12,
    color: "#9A97AA",
  },
  productMacrosContainer: {
    display: "flex",
    flexDirection: "row",
  },
  mealHeaderText: {
    color: "#303051",
  },
  kcalImg: {
    marginRight: 5,
    width: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 10,
  },
  centeredVertical: {
    justifyContent: "center",
  },
  tag: {
    backgroundColor: "#e8e9fe",
    paddingVertical: 3,
    paddingHorizontal: 6,
    borderRadius: 24,
    alignSelf: "flex-start",
    marginVertical: 1,
    flexDirection: "row",
    marginRight: 10,
  },
  tagText: {
    color: "#849dff",
    fontSize: 12.5,
  },
  kcalText: {
    color: "#849dff",
  },
  portion: {
    fontWeight: 500,
  },
  extraText: {
    fontSize: 10,
    color: "#9A97AA",
    fontWeight: 500,
  },
  supplementsContainer: {
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
    marginVertical: 5,
    paddingBottom: 2,
    borderBottomColor: "#e8e9fe",
    borderBottomWidth: 1,
    flexWrap: "wrap",
  },
  item: {
    fontSize: 12,
    marginBottom: 0,
    marginTop: 0,
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  name: {
    flexBasis: "40%",
  },
  comment: {
    flexBasis: "60%",
  },
  basketName: {
    flexBasis: "80%",
  },
  basketComment: {
    flexBasis: "20%",
  },
  headerTitle: {
    color: "#9A97AA",
    marginBottom: 0,
    marginTop: 5,
    fontSize: 12,
  },
  backgroundImg: {
    width: "80%",
    marginTop: -50,
    marginBottom: 20,
  },
  mainPageText: {
    marginTop: 10,
    fontWeight: 600,
    fontSize: 18,
  },
  logoImg: {
    width: 200,
  },
  appleImg: {
    width: 25,
  },
  card: {
    borderRadius: 12,
    backgroundColor: "#F6F6F6",
    paddingVertical: 20,
    paddingHorizontal: 50,
    marginVertical: 15,
    alignSelf: "center",
  },
  macrosMain: {
    fontSize: 13,
    marginLeft: 10,
  },
  macroMainItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  mainText: {
    fontSize: 14,
    marginTop: 8,
  },
  dayTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.gold8,
  },
});
