import React, { useEffect, useMemo, useState, type FunctionComponent } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Segmented,
  Select,
  Switch,
} from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import { useTranslation } from "react-i18next";

import { DEFAULT_CURRENCY_OPTIONS, DEFAULT_PRODUCT_CURRENCY } from "@fitness-app/data-models";
import {
  EndAccessStrategy,
  PaymentClient,
  PaymentTypeOption,
  ProductDurationType,
  ProductType,
  type PricePaymentType,
  type ProductTypeEnum,
} from "@fitness-app/data-models/entities/Product";
import {
  WFirmaIntegrationStatus,
  type FakturowniaIntegration,
  type IFirmaIntegration,
  type InFaktIntegration,
  type WFirmaIntegration,
} from "@fitness-app/data-models/entities/ProductsSettings";

import ImageUploadField from "~/components/ImageUploadField/ImageUploadField";
import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { type ProductFormModel } from "~/modules/Products/ProductForm/types";
import { useAppSelector } from "~/store/initializeStore";
import {
  BillingPeriod,
  createBillingPeriodOptions,
  createEndAccessStrategy,
  createIntervalOptions,
  createNumberOfCycleTypeOptions,
  createPaymentClientsOptions,
  createProductDurationTypeOptions,
  createProductPriceTypeOptions,
  createProductTypeOptions,
} from "../constants/productForm";

interface OwnProps {
  formController?: FormInstance<ProductFormModel>;
  onSubmit: (formData: ProductFormModel) => void;
  model?: ProductFormModel | null;
  fromOnboarding?: boolean;
}

type Props = OwnProps;

const pricesOptions = DEFAULT_CURRENCY_OPTIONS.map((currency) => ({
  value: currency,
  label: currency.toUpperCase(),
}));

const ProductForm: FunctionComponent<Props> = ({ formController, onSubmit, model, fromOnboarding = false }: Props) => {
  const { t } = useTranslation(["common", "products"]);
  const userData = useAppSelector((store) => store.user.data);
  const integrationData = useAppSelector((store) => store.productsSettings.data);
  const selectedProductType = Form.useWatch("productType", formController);
  const selectedProductDurationType = Form.useWatch("productDurationType", formController);
  const productPrices = Form.useWatch("prices", formController);
  const [activeInstallments, setActiveInstallments] = useState<Record<string, boolean>>({});

  const productDetails = useAppSelector((store) => store.product.details);
  const { productsListData: products } = useAppSelector((store) => store.products);
  const [paymentType, changePaymentType] = useState<PricePaymentType[]>([PaymentTypeOption.Recurring]);
  const invoices = integrationData?.invoices;
  const [selectedBillingPeriod, setBillingPeriod] = useState<string[]>([PaymentTypeOption.Recurring]);
  const [currency, setCurrency] = useState<string[]>([DEFAULT_PRODUCT_CURRENCY]);
  const productsToUpsell = products.filter((product) => product.type === ProductType.PERSONAL_CLIENT_SERVICE);
  const hasModel = Boolean(model);
  const isFree = Form.useWatch("isFree", formController);

  const productsToSellAfterProgramEnd = products.filter(
    (product) =>
      (product.type === ProductType.PERSONAL_CLIENT_SERVICE || product.type === ProductType.AUTOMATED_CLIENT_SERVICE) &&
      product.id !== productDetails?.id,
  );

  useEffect(() => {
    if (model) {
      // @ts-expect-error ignore
      formController?.setFieldsValue({
        ...model,
        // customAuthor: Boolean(model.author),
      });
    }
  }, [model]);

  const customAuthor = Form.useWatch("customAuthor", formController);

  const changeCurrency = (currency: string, index: number) => {
    setCurrency((prev) => {
      const clone = prev.slice();
      clone[index] = currency;
      return clone;
    });
  };

  const invoicesOptions = useMemo(() => {
    if (!invoices) {
      return [];
    }

    return Object.values(invoices)

      .map(
        (invoiceClient) =>
          invoiceClient?.filter(
            (item) => !("status" in item) || ("status" in item && item.status === WFirmaIntegrationStatus.Completed),
          ),
      )
      .map(
        (invoiceClient: (FakturowniaIntegration | IFirmaIntegration | WFirmaIntegration | InFaktIntegration)[]) =>
          invoiceClient?.map(
            (account: FakturowniaIntegration | IFirmaIntegration | WFirmaIntegration | InFaktIntegration) => ({
              label: `${account.accountName} - ${t(`invoices.${account.client}`)}`,
              value: `${account.accountName}${LIST_ITEM_SEPARATOR}${account.client}${LIST_ITEM_SEPARATOR}${account.url}`,
            }),
          ),
      )
      .filter((item): item is { label: string; value: string }[] => !!item)
      .flat();
  }, [invoices, t]);

  const changePaymentItemType = (type: PricePaymentType, index: number) => {
    changePaymentType((prev) => {
      const clone = prev.slice();
      clone[index] = type;
      return clone;
    });
    setTimeout(() => {
      formController?.setFields([
        {
          name: ["prices", index, "billingPeriod"],
          value: type === PaymentTypeOption.OneTime ? BillingPeriod.ToCancel : BillingPeriod.Monthly,
        },
        ...(type === PaymentTypeOption.OneTime
          ? [
              {
                name: ["prices", index, "endAccessStrategy"],
                value: EndAccessStrategy.Cancel,
              },
            ]
          : []),
      ]);
    }, 0);
  };

  const changeBillingPeriodItem = (period: string, index: number) => {
    setBillingPeriod((prev) => {
      const clone = prev.slice();
      clone[index] = period;
      return clone;
    });
  };

  const changeProductType = (type: ProductTypeEnum) => {
    [ProductType.ONLY_CONTENT, ProductType.ADDON].includes(type) &&
      productPrices?.forEach((_, index) => {
        setTimeout(() => {
          formController?.setFields([
            {
              name: ["prices", index, "paymentType"],
              value: PaymentTypeOption.OneTime,
            },
          ]);
        }, 0);
        changePaymentItemType(PaymentTypeOption.OneTime, index);
      });
  };

  const renderDurationField = () => {
    if (!selectedProductDurationType || selectedProductDurationType === ProductDurationType.Infinite) {
      return null;
    }

    if (selectedProductDurationType === ProductDurationType.UntilDate) {
      return (
        <>
          <Form.Item
            name={["productDuration", "untilDate"]}
            label={t<string>("products:form.productDurationDate")}
            rules={[
              {
                required: true,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker
              format="DD.MM.YYYY"
              disabledDate={(current) => current && current < dayjs().startOf("day")}
              showToday={false}
              disabled={hasModel}
            />
          </Form.Item>
          <Form.Item name={["productDuration", "type"]} hidden>
            <Input value={ProductDurationType.UntilDate} />
          </Form.Item>
        </>
      );
    }

    return (
      <>
        <Form.Item label={t<string>("products:form.productDurationCycle")} style={{ marginBottom: 0 }}>
          <Form.Item name={["productDuration", "type"]} hidden>
            <Input value={ProductDurationType.LastsFor} />
          </Form.Item>
          <Form.Item
            name={["productDuration", "count"]}
            style={{ display: "inline-block" }}
            rules={[
              {
                required: true,
                type: "number",
                min: 1,
                message: t<string>("validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber disabled={hasModel} autoFocus min={0} max={60} precision={0} style={{ minWidth: 150 }} />
          </Form.Item>

          <Form.Item name={["productDuration", "unit"]} style={{ display: "inline-block", marginLeft: 8 }}>
            <Select disabled={hasModel} options={createNumberOfCycleTypeOptions(t)} defaultValue="month" />
          </Form.Item>
        </Form.Item>
      </>
    );
  };

  const renderUpsellField = () => {
    if (selectedProductType !== ProductType.AUTOMATED_CLIENT_SERVICE || !productsToUpsell.length) {
      return null;
    }

    return (
      <Form.Item
        name="upsellTo"
        label={t<string>("products:form.upsellTo")}
        tooltip={t<string>("products:form.upsellToTooltip")}
      >
        <Select
          mode="multiple"
          options={productsToUpsell.map((product) => ({
            value: product.id,
            label: product.name,
          }))}
          placeholder={t<string>("products:form.selectUpsellTo")}
        />
      </Form.Item>
    );
  };

  const renderEndAccessField = () => {
    if (
      [ProductType.ADDON, ProductType.ONLY_CONTENT].includes(selectedProductType) ||
      !productsToSellAfterProgramEnd.length
    ) {
      return null;
    }

    return (
      <Form.Item
        name="endAccessOffers"
        label={t<string>("products:form.endAccessOffers")}
        tooltip={t<string>("products:form.endAccessOffersTooltip")}
      >
        <Select
          mode="multiple"
          options={productsToSellAfterProgramEnd.map((product) => ({
            value: product.id,
            label: product.name,
          }))}
          placeholder={t<string>("products:form.endAccessOffersPlaceholder")}
        />
      </Form.Item>
    );
  };

  return (
    <Form<ProductFormModel>
      name="product-form"
      labelCol={{ span: fromOnboarding ? 6 : 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      labelWrap
      form={formController}
      initialValues={{
        isFree: false,
        name: "",
        description: "",
        images: [],
        prices: [],
        author: { firstName: "", lastName: "", email: "", avatar: "" },
        productType: ProductType.AUTOMATED_CLIENT_SERVICE,
        clientTags: [],
        customAuthor: false,
        ...(cloneDeep(model) || {}),
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="productType"
        label={t<string>("products:form.productType")}
        rules={[
          {
            required: isEmpty(model),
            message: t<string>("validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          options={createProductTypeOptions(t)}
          disabled={hasModel}
          onChange={(value) => changeProductType(value as ProductTypeEnum)}
        />
      </Form.Item>

      <Form.Item
        name="name"
        label={t<string>("products:form.name")}
        rules={[
          {
            required: true,
            message: t<string>("validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input placeholder={t<string>("products:form.namePlaceholder")} style={{ maxWidth: 700 }} />
      </Form.Item>

      <Form.Item
        name="description"
        label={t<string>("products:form.description")}
        rules={[
          {
            required: true,
            message: t<string>("validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input.TextArea placeholder={t<string>("products:form.descPlaceholder")} style={{ maxWidth: 700 }} />
      </Form.Item>

      <Form.Item
        tooltip={t<string>("products:form.imageAdditionalInfo")}
        name="images"
        label={t<string>("products:form.images")}
        valuePropName="fileList"
        getValueFromEvent={(e: unknown) => e}
      >
        <ImageUploadField storageRef={`${userData?.id}/products`} multiple={false} />
      </Form.Item>

      <Form.Item
        label={t("products:form.customAuthor")}
        name="customAuthor"
        valuePropName="checked"
        tooltip={t("products:form.customAuthorInfo")}
      >
        <Switch />
      </Form.Item>

      {customAuthor && (
        <>
          <Form.Item name={["author", "firstName"]} label={t<string>("products:form.authorFirstName")}>
            <Input placeholder={t<string>("products:form.authorFirstNamePlaceholder")} style={{ maxWidth: 700 }} />
          </Form.Item>

          <Form.Item name={["author", "lastName"]} label={t<string>("products:form.authorLastName")}>
            <Input placeholder={t<string>("products:form.authorLastNamePlaceholder")} style={{ maxWidth: 700 }} />
          </Form.Item>

          <Form.Item
            name={["author", "email"]}
            label={t<string>("products:form.authorEmail")}
            rules={[
              {
                type: "email",
                message: t<string>("validationErrors.wrongEmail"),
              },
            ]}
          >
            <Input placeholder={t<string>("products:form.authorEmailPlaceholder")} style={{ maxWidth: 700 }} />
          </Form.Item>

          <Form.Item
            tooltip={t<string>("products:form.imageAdditionalInfo")}
            name={["author", "avatar"]}
            label={t<string>("products:form.authorAvatar")}
            valuePropName="fileList"
            getValueFromEvent={(e: unknown) => e}
          >
            <ImageUploadField storageRef={`${userData?.id}/products`} multiple={false} />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="productDurationType"
        label={t<string>("products:form.productDurationType")}
        hidden={
          !selectedProductType ||
          selectedProductType === ProductType.ONLY_CONTENT ||
          selectedProductType === ProductType.ADDON
        }
        rules={[
          {
            required: !(selectedProductType === ProductType.ONLY_CONTENT || selectedProductType === ProductType.ADDON),
            message: t<string>("validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          options={createProductDurationTypeOptions(t)}
          disabled={Boolean(model)}
          placeholder={t<string>("products:form.productDurationType")}
        />
      </Form.Item>

      {renderDurationField()}

      {renderUpsellField()}

      {renderEndAccessField()}

      <Form.Item
        name="isFree"
        valuePropName="checked"
        preserve
        wrapperCol={{ span: 8, offset: fromOnboarding ? 6 : 8 }}
        style={{ marginBottom: 4 }}
      >
        <Checkbox disabled={!!model}>{t("products:form.isFree")}</Checkbox>
      </Form.Item>

      <Form.Item
        name="customInvoicesClient"
        tooltip={t<string>("products:form.customInvoicesClientTooltip")}
        label={t<string>("products:form.customInvoicesClient")}
        hidden={isFree}
      >
        <Select
          allowClear
          options={invoicesOptions}
          placeholder={t<string>("products:form.customInvoicesClientPlaceholder")}
        />
      </Form.Item>

      {!isFree && isEmpty(model) ? (
        <Col offset={fromOnboarding ? 6 : undefined} xs={fromOnboarding ? 16 : 24}>
          <Alert showIcon message={t("products:form.pricePaymentInfo")} type="info" style={{ marginBottom: 10 }} />

          <Form.List
            name="prices"
            rules={[
              {
                validator: async (_, levels: string[]) => {
                  if (!levels || levels.length < 1) {
                    void message.warning(t("products:form.atLeastOnePrice"));
                    return Promise.reject(new Error("At least 1 level"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => {
              const onlyOneItem = fields.length === 1;
              return (
                <>
                  {fields.map((field, index) => {
                    return (
                      <div
                        className="relative mb-12 border border-dashed bg-gray-50 bg-opacity-30 p-12"
                        key={field.key}
                      >
                        <Form.Item
                          name={[field.name, "paymentType"]}
                          tooltip={t<string>("products:form.billingPeriodTooltip")}
                          label={t<string>("products:form.paymentType")}
                          rules={[
                            {
                              required: true,
                              message: t<string>("validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Segmented
                            options={createProductPriceTypeOptions(t, selectedProductType)}
                            onChange={(value) => changePaymentItemType(value as PricePaymentType, index)}
                          />
                        </Form.Item>

                        <Form.Item label={t<string>("products:form.price")} style={{ marginBottom: 0 }}>
                          <Form.Item
                            name={[field.name, "amount"]}
                            style={{ display: "inline-block", width: 160 }}
                            rules={[
                              {
                                required: true,
                                type: "number",
                                min: 3,
                                message: t<string>("validationErrors.minPrice", {
                                  number: 3,
                                  currency: "PLN",
                                }),
                              },
                            ]}
                          >
                            <InputNumber
                              autoFocus
                              min={0}
                              max={100000}
                              precision={2}
                              decimalSeparator={currency[index] === "pln" ? "," : "."}
                              name="amount"
                              style={{ width: 160 }}
                            />
                          </Form.Item>

                          <Form.Item name={[field.name, "currency"]} style={{ display: "inline-block", marginLeft: 8 }}>
                            <Select
                              options={pricesOptions}
                              defaultValue={DEFAULT_PRODUCT_CURRENCY}
                              onSelect={(value: string) => changeCurrency(value, index)}
                            />
                          </Form.Item>
                        </Form.Item>

                        <Form.Item
                          name={[field.name, "billingPeriod"]}
                          label={t<string>(
                            paymentType[index] === PaymentTypeOption.Recurring
                              ? "products:form.billingPeriod"
                              : "products:form.access",
                          )}
                          rules={[
                            {
                              required: paymentType[index] === PaymentTypeOption.Recurring,
                              message: t<string>("validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Select
                            style={{ maxWidth: 320 }}
                            onSelect={(value: string) => changeBillingPeriodItem(value, index)}
                          >
                            {createBillingPeriodOptions(t, paymentType[index] ?? PaymentTypeOption.OneTime).map(
                              (period) => (
                                <Select.Option value={period.value} key={period.value}>
                                  {period.label}
                                </Select.Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>

                        {selectedBillingPeriod[index] === BillingPeriod.Custom && (
                          <>
                            <Form.Item label={t<string>("products:form.customInterval")} style={{ marginBottom: 0 }}>
                              <Form.Item
                                name={[field.name, "billingIntervalCount"]}
                                style={{ display: "inline-block" }}
                                rules={[
                                  {
                                    required: true,
                                    type: "number",
                                    min: 1,
                                    message: t<string>("validationErrors.fieldIsRequired"),
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={60}
                                  precision={0}
                                  name="numberOfCycles"
                                  style={{ minWidth: 150 }}
                                />
                              </Form.Item>

                              <Form.Item
                                name={[field.name, "billingInterval"]}
                                style={{
                                  display: "inline-block",
                                  marginLeft: 8,
                                }}
                              >
                                <Select options={createIntervalOptions(t)} defaultValue="month" />
                              </Form.Item>
                            </Form.Item>
                          </>
                        )}

                        {paymentType[index] === PaymentTypeOption.Recurring && (
                          <>
                            <Form.Item
                              label={t("products:form.setInstallments")}
                              name={[field.name, "activeInstallments"]}
                              valuePropName="checked"
                            >
                              <Switch
                                onChange={(checked) => setActiveInstallments((prev) => ({ ...prev, [index]: checked }))}
                              />
                            </Form.Item>
                            {activeInstallments[index] ? (
                              <Form.Item
                                name={[field.name, "installments"]}
                                label={t<string>("products:form.installments")}
                                tooltip={t("products:form.installmentsInfo")}
                                rules={[
                                  {
                                    required: true,
                                    message: t<string>("common:validationErrors.fieldIsRequired"),
                                  },
                                ]}
                              >
                                <InputNumber min={0} max={100000} />
                              </Form.Item>
                            ) : null}
                          </>
                        )}

                        {paymentType[index] === PaymentTypeOption.OneTime ? (
                          <Form.Item
                            name={[field.name, "endAccessStrategy"]}
                            label={t<string>("products:form.endAccessStrategy")}
                            rules={[
                              {
                                required: true,
                                message: t<string>("validationErrors.fieldIsRequired"),
                              },
                            ]}
                          >
                            <Select style={{ maxWidth: 320 }}>
                              {createEndAccessStrategy(t).map((strategy) => (
                                <Select.Option value={strategy.value} key={strategy.value}>
                                  {strategy.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null}

                        <Form.Item
                          name={[field.name, "paymentClient"]}
                          label={t<string>("products:form.paymentClient")}
                          rules={[
                            {
                              required: true,
                              message: t<string>("validationErrors.fieldIsRequired"),
                            },
                          ]}
                        >
                          <Select style={{ maxWidth: 250 }} disabled>
                            {createPaymentClientsOptions(t).map((client) => (
                              <Select.Option value={client.value} key={client.value}>
                                {client.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        {!onlyOneItem && (
                          <div className="absolute bottom-8 right-8">
                            <DeleteOutlined
                              style={{ color: "#f64e60", fontSize: 20 }}
                              onClick={() => {
                                changePaymentType((prev) => prev.filter((_, i) => i !== index));
                                setCurrency((prev) => prev.filter((_, i) => i !== index));
                                remove(field.name);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    style={{
                      flexBasis: "100%",
                      marginTop: 16,
                      opacity: paymentType ? 1 : 0,
                    }}
                  >
                    <Row style={{ width: "100%" }}>
                      <Col xs={12} offset={fromOnboarding ? 0 : 8}>
                        <Button
                          type="primary"
                          onClick={() => {
                            changePaymentType((value) => [...value, PaymentTypeOption.Recurring]);
                            setCurrency((value) => [...value, DEFAULT_PRODUCT_CURRENCY]);
                            add({
                              currency: DEFAULT_PRODUCT_CURRENCY,
                              billingPeriod: BillingPeriod.Monthly,
                              paymentType: PaymentTypeOption.Recurring,
                              paymentClient: PaymentClient.STRIPE,
                            });
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          {fields.length
                            ? t<string>("products:form.addAnotherPrice")
                            : t<string>("products:form.addPrice")}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              );
            }}
          </Form.List>
        </Col>
      ) : null}
    </Form>
  );
};

export default ProductForm;
