import { useEffect } from "react";
import { type FormInstance } from "antd/lib/form";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { type AddSurveyFormModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/AddSurveyForm/types";
import { useAppSelector } from "~/store/initializeStore";

export type SurveysVariablesForm = {
  variables: Record<string, string>;
};

export const useDefaultVariables = (variables: null | string[], formController: FormInstance<AddSurveyFormModel>) => {
  const client = useAppSelector((store) => store.trainee.profile);
  const trainer = useAppSelector((store) => store.user.profile);
  const { isOwner } = useUserClaims();
  const selectedMember = useAppSelector((store) => store.team.selectedMember);

  const defaults: Record<string, string | undefined | null> = {
    firstName: client?.firstName,
    clientFirstName: client?.firstName,
    clientName: client?.firstName,
    lastName: client?.lastName,
    clientLastName: client?.lastName,
    email: client?.email,
    clientEmail: client?.email,
    trainerName: client?.assignedTrainer?.firstName
      ? `${client.assignedTrainer.firstName} ${client.assignedTrainer.lastName}`
      : `${trainer?.firstName} ${trainer?.lastName}`,
    trainerEmail: client?.assignedTrainer?.email || trainer?.email,
  };

  const globalVariables = isOwner ? {} : selectedMember?.variables || {};

  useEffect(() => {
    if (variables?.length) {
      const defaultValues = variables.reduce((acc, variable) => {
        return {
          ...acc,
          [variable]: globalVariables[variable] || defaults[variable] || "",
        };
      }, {});
      setTimeout(() => {
        formController.setFieldsValue({ variables: defaultValues });
      }, 200);
    }
  }, [variables]);
};

export const useDefaultTemplateVariables = (
  variables: null | string[],
  model: Partial<Record<string, string | number>> | null,
  formController: FormInstance<Record<string, string | number>>,
) => {
  const client = useAppSelector((store) => store.trainee.profile);
  const trainer = useAppSelector((store) => store.user.profile);
  const { isOwner } = useUserClaims();
  const selectedMember = useAppSelector((store) => store.team.selectedMember);

  const defaults: Record<string, string | undefined | null> = {
    firstName: client?.firstName,
    clientFirstName: client?.firstName,
    clientName: client?.firstName,
    lastName: client?.lastName,
    clientLastName: client?.lastName,
    email: client?.email,
    clientEmail: client?.email,
    trainerName: client?.assignedTrainer?.firstName
      ? `${client.assignedTrainer.firstName} ${client.assignedTrainer.lastName}`
      : `${trainer?.firstName} ${trainer?.lastName}`,
    trainerEmail: client?.assignedTrainer?.email || trainer?.email,
  };

  const globalVariables = isOwner ? {} : selectedMember?.variables || {};

  useEffect(() => {
    if (variables?.length) {
      const defaultValues = variables.reduce((acc, variable) => {
        return {
          ...acc,
          [variable]: globalVariables[variable] || defaults[variable] || model?.[variable] || "",
        };
      }, {});
      setTimeout(() => {
        formController.setFieldsValue({ ...defaultValues });
      }, 200);
    }
  }, [variables]);
};

export const useSurveyTemplateVariables = (
  variables: null | string[],
  formController: FormInstance<SurveysVariablesForm>,
) => {
  const selectedMember = useAppSelector((store) => store.team.selectedMember);

  useEffect(() => {
    if (variables?.length) {
      const defaultValues = variables.reduce((acc, variable) => {
        return {
          ...acc,
          [variable]: selectedMember?.variables?.[variable] || "",
        };
      }, {});
      setTimeout(() => {
        formController.setFieldsValue({ variables: defaultValues });
      }, 200);
    }
  }, [variables, selectedMember]);
};
