import React, { useMemo } from "react";
import { Card, Col, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import ProductsList from "~/modules/Products/ProductsList/ProductsList";
import ProductsSettings from "./ProductsSettings/ProductsSettings";

const Products = (): React.ReactElement => {
  const { t } = useTranslation("products");

  const tabs = useMemo(() => {
    return [
      {
        key: "products",
        label: t("productsList"),
        children: <ProductsList />,
      },
      {
        key: "settings",
        label: t("settings"),
        children: <ProductsSettings />,
      },
    ];
  }, [t]);

  return (
    <Row>
      <Col xs={24}>
        <Card title={t("products")}>
          <Tabs type="card" hideAdd items={tabs} />
        </Card>
      </Col>
    </Row>
  );
};

export default Products;
