import React, { useEffect, useMemo } from "react";
import { BarChart, Card, Col, Grid, Title } from "@tremor/react";
import { Skeleton, Statistic } from "antd";
import dayjs from "dayjs";
import sortBy from "lodash.sortby";
import { useTranslation } from "react-i18next";

import { statisticsActions } from "@fitness-app/app-store";
import { RequestStatus } from "@fitness-app/data-models";
import { type DayMonth } from "@fitness-app/data-models/entities/Reports";

import { ChatAlerts } from "~/modules/Dashboard/AppStats/ChatAlerts";
import { paymentTypeColorMapper } from "~/modules/Products/constants/paymentTypeColorMapper";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const AppStats = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("dashboard");

  const { productsMonthStats, currentMonthStats, productsMonthStatsStatus } = useAppSelector(
    (state) => state.statistics,
  );

  useEffect(() => {
    void dispatch(statisticsActions.fetchCreatorStats());
    void dispatch(statisticsActions.fetchCurrentMonthStats());
    void dispatch(statisticsActions.fetchProductsMonthStats({}));
  }, []);

  const monthChartData = useMemo(() => {
    const dailyStats: Record<string, { name: string; value: number }[]> = {};

    if (!productsMonthStats.length) {
      return [];
    }

    productsMonthStats.forEach((productMonthStats) => {
      Object.keys(productMonthStats.byDay).forEach((dayKey) => {
        const [day] = dayKey.split("-");
        const date = `${productMonthStats.monthYear}-${day}`;
        if (dailyStats[date]) {
          dailyStats[date]?.push({
            name: productMonthStats.productData.name,
            // eslint-disable-next-line
            value:
              productMonthStats.byDay[dayKey as DayMonth]?.clients ||
              // @ts-expect-error ignore
              productMonthStats.byDay[dayKey as DayMonth]?.subscribers ||
              0,
          });
        } else {
          dailyStats[date] = [
            {
              name: productMonthStats.productData.name,
              // eslint-disable-next-line
              value:
                productMonthStats.byDay[dayKey as DayMonth]?.clients ||
                // @ts-expect-error ignore
                productMonthStats.byDay[dayKey as DayMonth]?.subscribers ||
                0,
            },
          ];
        }
      });
    });

    return sortBy(
      Object.keys(dailyStats).map((dayKey) => {
        const date = dayjs(dayKey, "YYYY-M-D").format("DD.MM.YYYY");

        return {
          date,
          ...dailyStats[dayKey]?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.name]: curr.value,
            }),
            {},
          ),
        };
      }),
      "date",
    );
  }, [productsMonthStats]);

  const productsName = productsMonthStats.map((product) => product.productData.name);

  return (
    <main className="flex flex-col gap-6">
      <Skeleton active loading={productsMonthStatsStatus === RequestStatus.FETCHING} paragraph={{ rows: 10 }}>
        <Grid numItemsLg={6} className="mt-6 gap-6">
          <Col numColSpanLg={4}>
            <Card className="h-full">
              <Title>Ilość dodanych klientów do produktów w danym miesiącu</Title>
              <BarChart
                className="mt-4 h-96"
                data={monthChartData}
                index="date"
                categories={productsName}
                allowDecimals={false}
                colors={["indigo", "cyan", "red", "green", "yellow", "blue", "purple", "pink", "orange", "teal"]}
              />
            </Card>
          </Col>

          <Col numColSpanLg={2}>
            <div className="space-y-12">
              <Card>
                <div className="flex flex-col justify-between sm:flex-row">
                  <Statistic
                    title={t("statistics.newTotalNumberOfPaidSubscribers")}
                    value={currentMonthStats?.numberOfPaidClients ?? 0}
                    precision={0}
                    valueStyle={{ color: paymentTypeColorMapper.recurring }}
                  />
                </div>
              </Card>

              <Card>
                <div className="flex flex-col justify-between sm:flex-row">
                  <Statistic
                    title={t("statistics.newTotalNumberOfFreeSubscribers")}
                    value={currentMonthStats?.numberOfFreeClients ?? 0}
                    precision={0}
                    valueStyle={{ color: paymentTypeColorMapper.free }}
                  />
                </div>
              </Card>

              <Card>
                <div className="flex flex-col justify-between sm:flex-row">
                  <Statistic
                    title={t("statistics.totalNumberOfImportedPaidClients")}
                    value={currentMonthStats?.numberOfImportedPaidClients ?? 0}
                    precision={0}
                    valueStyle={{ color: paymentTypeColorMapper.free }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Grid>
      </Skeleton>

      <ChatAlerts />
    </main>
  );
};
