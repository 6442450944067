import React, { useEffect, useMemo, useState } from "react";
import { LineChart } from "@tremor/react";
import { Alert, Card, Space, Typography } from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash.isempty";
import mean from "lodash.mean";
import round from "lodash.round";

import { RequestStatus, traineeMeasurementsActions } from "@fitness-app/app-store";
import { MeasurementType, type WeightMeasurement } from "@fitness-app/data-models/entities/Measurement";
import { getErrorMessage } from "@fitness-app/utils";

import { type CommonChartProps } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeResults/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

type WeightMeasurementChartProps = CommonChartProps;

const valueFormatterAbsolute = (number: number) => `${Intl.NumberFormat("pl").format(number).toString()} kg`;

const WeightMeasurementChart = ({ range }: WeightMeasurementChartProps) => {
  const traineeId = useAppSelector((state) => state.trainee.profile?.id || "");
  const dispatch = useAppDispatch();
  const [measurements, setMeasurements] = useState<WeightMeasurement[]>([]);
  const [requestState, setRequestState] = useState<{ status: RequestStatus; error: null | string }>({
    error: null,
    status: RequestStatus.IDLE,
  });

  const chartData: { date: string; Waga: number }[] = useMemo(() => {
    return measurements.map((measurement) => ({
      date: dayjs(measurement.eventTimestamp).format("DD.MM.YYYY"),
      Waga: measurement.data.weight,
    }));
  }, [measurements]);

  const averageWeight = round(mean(chartData.map((item) => item.Waga)), 1);

  const fetchMeasurements = async (dateStart: Date, dateEnd: Date) => {
    try {
      setRequestState({ error: null, status: RequestStatus.FETCHING });
      const response = await dispatch(
        traineeMeasurementsActions.fetchMeasurementsForRangeAndType({
          dateStart,
          dateEnd,
          type: MeasurementType.WeightMeasurement,
          traineeId,
        }),
      ).unwrap();
      setMeasurements(response as WeightMeasurement[]);
      setRequestState({ error: null, status: RequestStatus.SUCCESS });
    } catch (e) {
      setRequestState({ error: getErrorMessage(e), status: RequestStatus.FAILED });
    }
  };

  useEffect(() => {
    if (range?.[0] && range?.[1]) {
      void fetchMeasurements(range[0].startOf("day").toDate(), range[1].endOf("day").toDate());
    }
  }, [range]);

  return (
    <Card
      title="Waga"
      type="inner"
      className="relative"
      loading={requestState.status === RequestStatus.FETCHING}
      extra={
        <Space direction="horizontal">
          <Typography.Text strong>
            Średnia z wybranego okresu: {isEmpty(chartData) ? "-" : `${averageWeight}kg`}
          </Typography.Text>
        </Space>
      }
    >
      <LineChart
        className="mt-8 h-80"
        data={chartData}
        index="date"
        categories={["Waga"]}
        colors={["blue"]}
        showLegend={false}
        valueFormatter={valueFormatterAbsolute}
        yAxisWidth={60}
        curveType="monotone"
        autoMinValue
        intervalType={"preserveStartEnd"}
        allowDecimals={false}
      />

      {requestState.status === RequestStatus.FAILED && (
        <Alert
          message={requestState.error || "Wystąpił błąd podczas pobierania danych"}
          type="error"
          showIcon
          className="absolute left-10 right-10 top-[55%]"
        />
      )}
      {isEmpty(chartData) && requestState.status !== RequestStatus.FAILED && (
        <Alert
          message="Brak informacji na temat wagi klienta"
          type="warning"
          showIcon
          className="absolute left-10 right-10 top-[55%]"
        />
      )}
    </Card>
  );
};

export default WeightMeasurementChart;
