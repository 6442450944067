import React, { useCallback, useMemo, useRef, useState } from "react";
import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Input, Popconfirm, Space, Table, Tag, type InputRef } from "antd";
import { type ColumnType } from "antd/es/table";
import { type FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";

import { RequestStatus, workoutTemplatesActions } from "@fitness-app/app-store";
import {
  type WorkoutTemplate,
  type WorkoutTemplateWithCreator,
} from "@fitness-app/data-models/entities/WorkoutTemplate";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useTagOptions } from "~/hooks/useTagOptions";
import AddWorkoutTemplateForm from "~/modules/TrainingPrograms/ProgramDayTemplates/AddWorkoutTemplateForm";
import { type AddWorkoutTemplateFormModel } from "~/modules/TrainingPrograms/ProgramDayTemplates/types";
import WorkoutTemplateBuilder from "~/modules/TrainingPrograms/ProgramDayTemplates/WorkoutTemplateBuilder";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface ProgramDayTemplatesProps {
  selectMode?: boolean;
  selectionType?: "radio" | "checkbox";
  onRowSelect?: (row: WorkoutTemplateWithCreator[]) => void;
}

const ProgramDayTemplates = ({
  selectMode = false,
  selectionType = "radio",
  onRowSelect,
}: ProgramDayTemplatesProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const { listStatus, list, creators } = useAppSelector((store) => store.workoutTemplates);
  const [selectedTemplate, setTemplate] = useState<WorkoutTemplate | null>(null);
  const [showTemplateForm, toggleTemplateForm] = useState(false);
  const [savingTemplate, onSuccessSave, onFailureSave, startSaving] = useEntityChange();
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { filters: tagsFilters } = useTagOptions("workoutTags");

  const closeTemplateForm = () => {
    toggleTemplateForm(false);
    setTemplate(null);
  };

  const openTemplateForm = (template: WorkoutTemplate) => {
    setTemplate(template);
    toggleTemplateForm(true);
  };

  const openAddTemplateForm = () => {
    toggleTemplateForm(true);
  };

  const onSaveWorkoutTemplate = async (formData: AddWorkoutTemplateFormModel) => {
    startSaving();
    try {
      if (selectedTemplate) {
        await dispatch(
          workoutTemplatesActions.updateTemplate({
            templateId: selectedTemplate.id,
            template: formData,
          }),
        ).unwrap();
      } else {
        await dispatch(workoutTemplatesActions.addWorkoutTemplate({ template: formData })).unwrap();
      }
      onSuccessSave();
      closeTemplateForm();
      setTemplate(null);
    } catch {
      onFailureSave();
    }
  };

  const rowSelection = useMemo(
    () => ({
      onChange: (_selectedRowKeys: (string | number)[], selectedRows: WorkoutTemplateWithCreator[]) => {
        onRowSelect?.(selectedRows);
      },
      hideSelectAll: true,
    }),
    [onRowSelect],
  );

  const handleReset = (clearFilters?: () => void) => {
    clearFilters?.();
    setSearchText("");
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: "name" | "tags",
  ) => {
    confirm();
    setSearchText(selectedKeys[0] ?? "");
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = useCallback(
    (columnName: "name" | "tags"): ColumnType<WorkoutTemplateWithCreator> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close, clearFilters }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInputRef}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, columnName)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, columnName)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.search")}
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters);
                confirm({ closeDropdown: false });
              }}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {t("common:button.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
      onFilter: (value, record) =>
        record[columnName]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) || false,
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current?.select(), 100);
        }
      },
    }),
    [handleSearch, handleReset, t],
  );

  return (
    <Card
      bordered={false}
      extra={
        selectMode ? undefined : (
          <Button type="primary" icon={<PlusOutlined />} onClick={openAddTemplateForm}>
            {t("workoutTemplates.add")}
          </Button>
        )
      }
    >
      <Table<WorkoutTemplateWithCreator>
        rowSelection={
          selectMode
            ? {
                type: selectionType,
                ...rowSelection,
              }
            : undefined
        }
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => <WorkoutTemplateBuilder template={record} />,
        }}
        scroll={{ x: true }}
        pagination={false}
        loading={listStatus === RequestStatus.FETCHING}
        dataSource={list}
        locale={{
          emptyText: "Lista zapisanych szablonów jest pusta",
        }}
      >
        <Table.Column
          title="Nazwa szablonu"
          dataIndex="name"
          key="name"
          {...getColumnSearchProps("name")}
          render={(name: string) =>
            searchedColumn === "name" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={name ? name.toString() : ""}
              />
            ) : (
              name
            )
          }
        />
        <Table.Column
          title="Opis"
          dataIndex="description"
          key="description"
          ellipsis
          render={(text: string) => text || "-"}
        />
        <Table.Column<WorkoutTemplateWithCreator>
          title="Tagi"
          dataIndex="tags"
          key="tags"
          filterMultiple
          filterSearch
          filters={tagsFilters}
          onFilter={(value, record) => (record.tags || []).includes(value as string)}
          render={(tags: string[]) =>
            tags?.length ? (
              <Space direction="vertical">
                {tags.map((tag, i) => (
                  <Tag key={`${tag}-${i}`}>{tag}</Tag>
                ))}
              </Space>
            ) : (
              "-"
            )
          }
        />
        <Table.Column<WorkoutTemplateWithCreator>
          title={t("program.createdBy")}
          dataIndex="createdBy"
          key="createdBy"
          filters={creators.map((creator) => ({
            text: creator.label,
            value: creator.value,
          }))}
          onFilter={(value, record) => record.createdBy === value}
          render={(_createdBy: string, row) => {
            if (row.creator) {
              return `${row.creator.firstName} ${row.creator.lastName}`;
            }
            return "-";
          }}
        />
        {!selectMode ? (
          <Table.Column<WorkoutTemplateWithCreator>
            title="Opcje"
            dataIndex="action"
            key="action"
            render={(text, record) => (
              <Space>
                <Button
                  size="small"
                  title="Edytuj"
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => openTemplateForm(record)}
                />
                <Button
                  size="small"
                  type="text"
                  title="Duplikuj"
                  icon={<CopyOutlined />}
                  onClick={() => dispatch(workoutTemplatesActions.duplicateWorkoutTemplate(record))}
                />
                <Popconfirm
                  title="Czy na pewno chcesz usunąc wybrany szablon? Usunięte dane nie będą możliwe do odzyskania."
                  okText="Tak"
                  cancelText="Nie"
                  placement="left"
                  onConfirm={() => dispatch(workoutTemplatesActions.removeTemplate(record.id))}
                >
                  <Button size="small" type="text" title="Usuń" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              </Space>
            )}
          />
        ) : null}
      </Table>
      <ModalForm
        open={showTemplateForm}
        onCancel={closeTemplateForm}
        okText={selectedTemplate ? t("common:button.update") : t("common:button.add")}
        title={selectedTemplate ? t("workoutTemplates.edit") : t("workoutTemplates.add")}
        loading={!!savingTemplate}
      >
        <AddWorkoutTemplateForm
          onSubmit={onSaveWorkoutTemplate}
          model={
            selectedTemplate
              ? {
                  name: selectedTemplate.name,
                  tags: selectedTemplate.tags ?? [],
                  description: selectedTemplate.description ?? "",
                }
              : undefined
          }
        />
      </ModalForm>
    </Card>
  );
};

export default ProgramDayTemplates;
