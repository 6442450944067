import React, { useState } from "react";
import { Badge, Button, Card, Input, Space, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { RequestStatus, traineesActions } from "@fitness-app/app-store";

import { useExerciseRecordings } from "~/modules/Trainee/hooks/useExerciseRecordings";
import { TraineeVideoRecordingsTable } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeMeasurements/TraineeVideoRecordings/TraineeVideoRecordingsTable";
import TraineesActivityTable from "~/modules/Trainee/TraineesActivityTable";
import TraineesTable from "~/modules/Trainee/TrainnesTable";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const TraineeList = () => {
  const { t } = useTranslation("trainees");
  const { searchStatus, searchTerm, filters, list } = useAppSelector((store) => store.trainees);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();
  const { data, count, isLoading } = useExerciseRecordings();

  const onClear = () => {
    setSearchValue("");
    dispatch(traineesActions.setSearchTerm(""));
    void dispatch(traineesActions.fetchTrainees());
  };

  const isSearchMode = Boolean(searchTerm);

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (isSearchMode) {
        onClear();
      }
      return;
    }
    dispatch(traineesActions.setSearchTerm(trimmed));

    void dispatch(traineesActions.fetchTrainees());
  };

  // const menu = (
  //   <Menu>
  //     {(details.product_stats?.activeClients && details.product_stats.activeClients > 0) ||
  //     (details.product_stats?.totalClients && details.product_stats.totalClients > 0) ? (
  //       <Menu.Item key="1" onClick={() => exportToCsvClients(productId, details?.name ?? "", "active")}>
  //         {t("clients.active")}
  //       </Menu.Item>
  //     ) : null}
  //
  //     <>
  //       {details.product_stats?.archivedClients && details.product_stats?.archivedClients > 0 ? (
  //         <Menu.Item key="2" onClick={() => exportToCsvClients(productId, details?.name ?? "", "archived")}>
  //           {t("clients.archived")}
  //         </Menu.Item>
  //       ) : null}
  //
  //       <Menu.Item key="3" onClick={() => exportToCsvClients(productId, details?.name ?? "", "all")}>
  //         {t("clients.all")}
  //       </Menu.Item>
  //     </>
  //   </Menu>
  // );

  return (
    <Card title={t("title")}>
      <Space size={4}>
        <Input.Search
          placeholder={t<string>("search.placeholder")}
          onSearch={onSearch}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ width: 300, margin: "20px 0" }}
          loading={searchStatus === RequestStatus.FETCHING}
        />
        {Boolean(searchTerm) && (
          <Button onClick={onClear} type="link">
            {t("products:search.reset")}
          </Button>
        )}
      </Space>
      <Tabs
        destroyInactiveTabPane
        // tabBarExtraContent={
        //   <Dropdown overlay={menu}>
        //     <Button type="dashed">
        //       {t("orders.exportToCsv")} <DownOutlined />
        //     </Button>
        //   </Dropdown>
        // }
      >
        <Tabs.TabPane
          tab={`${
            filters.currentStatusFilter?.length === 4 || filters.currentStatusFilter?.length === 0
              ? t("products:clients.all")
              : t(`products:clients.${filters.currentStatusFilter?.[0]?.toLowerCase() || "all"}`)
          }${searchTerm ? ` (${list.length})` : ""}`}
          key="active"
        >
          <TraineesTable isSearchMode={isSearchMode} />
        </Tabs.TabPane>
        <Tabs.TabPane key="activities" tab="Statystyki aktywności">
          <TraineesActivityTable isSearchMode={isSearchMode} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="recordings"
          tab={
            <div className="space-x-2">
              Nagrania do oceny <Badge count={count} />
            </div>
          }
        >
          <TraineeVideoRecordingsTable withTraineeInfo data={data || []} isLoading={isLoading} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default TraineeList;
