import React, { type FunctionComponent } from "react";
import { Card, Grid, Tabs } from "antd";
import { type TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { RequestStatus } from "@fitness-app/app-store";

import { useQuery } from "~/hooks/useQuery";
import CheckoutSettings from "~/modules/Products/ProductsSettings/CheckoutSettings/CheckoutSettings";
import EmailClients from "~/modules/Products/ProductsSettings/EmailClients/EmailClients";
import InvoicesClientsPanel from "~/modules/Products/ProductsSettings/Invoices/InvoicesClientsPanel";
import PaymentGateways from "~/modules/Products/ProductsSettings/PaymentGateways/PaymentGateways";
import { useAppSelector } from "~/store/initializeStore";

const creatorTabs = (t: TFunction<"settings">) => [
  <Tabs.TabPane tab={t("emailClients.title")} key="email-clients">
    <EmailClients />
  </Tabs.TabPane>,
  <Tabs.TabPane tab={t("payments.title")} key="payment-gateways" disabled>
    <PaymentGateways />
  </Tabs.TabPane>,
  <Tabs.TabPane tab={t("invoices.title")} key="invoices-settings">
    <InvoicesClientsPanel />
  </Tabs.TabPane>,
  <Tabs.TabPane tab={t("checkout.title")} key="checkout-settings">
    <CheckoutSettings />
  </Tabs.TabPane>,
  // <Tabs.TabPane tab={t("salesPage.title")} key="sales-page-settings" disabled>
  //   {/*<SalesPage />*/}
  // </Tabs.TabPane>,
];

const ProductsSettings: FunctionComponent = () => {
  const { t } = useTranslation("settings");
  const { status } = useAppSelector((state) => state.productsSettings);
  const query = useQuery();
  const { lg } = Grid.useBreakpoint();

  const getDefaultKey = () => {
    if (query.get("discord") || query.get("integration") === "discord") {
      return "additional-integrations";
    }
    if (query.get("integration") === "stripe") {
      return "payment-gateways";
    }
    return undefined;
  };

  return (
    <Card bordered={false} loading={status === RequestStatus.SUBSCRIBING}>
      <Tabs tabPosition={lg ? "left" : "top"} defaultActiveKey={getDefaultKey()}>
        {creatorTabs(t)}
      </Tabs>
    </Card>
  );
};

export default ProductsSettings;
