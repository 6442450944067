import { createSlice } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { RequestStatus } from "../../../enums/requestStatus";
import { addProgram } from "./actions/addProgram";
import { fetchPrograms } from "./actions/fetchPrograms";
import { updateProgram } from "./actions/updateProgram";
import { PROGRAMS_REDUCER_NAME, type ProgramsReducer } from "./types";

const initialState: ProgramsReducer = {
  list: [],
  listStatus: null,
  programStatus: null,
  creators: [],
};

const reducerSlice = createSlice({
  initialState,
  name: PROGRAMS_REDUCER_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchPrograms.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.list = payload;
      state.creators = uniqBy(
        payload.reduce<{ label: string; value: string }[]>((prev, current) => {
          if (current.creator) {
            prev.push({
              label: `${current.creator.firstName} ${current.creator.lastName}`,
              value: current.creator.id,
            });
          }
          return prev;
        }, []),
        "value",
      );
    });
    builder.addCase(fetchPrograms.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(addProgram.pending, (state) => {
      state.programStatus = RequestStatus.ADDING;
    });
    builder.addCase(addProgram.rejected, (state) => {
      state.programStatus = null;
    });
    builder.addCase(addProgram.fulfilled, (state) => {
      state.programStatus = null;
    });
    builder.addCase(updateProgram.pending, (state) => {
      state.programStatus = RequestStatus.UPDATING;
    });
    builder.addCase(updateProgram.fulfilled, (state, { payload }) => {
      state.programStatus = null;

      state.list = payload?.archived
        ? state.list.filter((item) => item.id !== payload.id)
        : state.list.map((item) => (item.id === payload?.id ? { ...item, ...payload } : item));
    });
    builder.addCase(updateProgram.rejected, (state) => {
      state.programStatus = null;
    });
  },
});

export default reducerSlice.reducer;
