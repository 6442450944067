import { type WorkoutSource } from "@fitness-app/data-models/entities/ClientWorkout";
import { type WorkoutActivityEventWithFeedback } from "@fitness-app/data-models/entities/ProgramActivity";
import {
  type ExerciseInWorkout,
  type ProgramWorkout,
  type TrainingStatus,
} from "@fitness-app/data-models/entities/TrainingProgram";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TRAINEE_ACTIVITIES_REDUCER_NAME = "traineeActivities";

export interface SelectedActivity {
  currentWorkout: null | ProgramWorkout;
  currentDay: string | null;
  isNewWorkout: null | boolean;
  workoutSource: null | WorkoutSource;
  editedDocId: null | string;
  workoutStatus: null | TrainingStatus;
  exerciseActivities: Record<string, ExerciseInWorkout>;
}

export interface TraineeActivitiesReducer {
  status: null | RequestStatus;
  activitiesStatus: null | RequestStatus;
  exercisesStatus: null | RequestStatus;
  error: null | string;
  workoutEditModalShown: boolean;
  workoutEditModalTouched: boolean;
  modalEditWeekScheduleShown: boolean;
  activities: WorkoutActivityEventWithFeedback[];
  data: SelectedActivity;
}
