import { type MediaLibraryItem } from "@fitness-app/data-models/entities/MediaLibrary";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AppThunk } from "../../../index";
import {
  addFileToList,
  deleteFileFromList,
  subscribeToMediaLibraryFailed,
  subscribeToMediaLibraryPending,
  subscribeToMediaLibrarySuccess,
  updateFileOnList,
} from "../reducer";
import { fetchMediaLibraryFiles } from "./fetchMediaLibraryFiles";
import { unsubscribeFromMediaLibrary } from "./unsubscribeFromMediaLibrary";

export const subscribeToMediaLibrary =
  (): AppThunk =>
  async (dispatch, getState, { auth, subscriptions, db }) => {
    try {
      dispatch(subscribeToMediaLibraryPending());

      await dispatch(fetchMediaLibraryFiles());

      const { id } = await getLoggedUser(auth);

      if (subscriptions.mediaLibraryListChannelName && subscriptions.realtimeMediaLibraryListChannel) {
        await db.removeChannel(subscriptions.realtimeMediaLibraryListChannel);
      }

      subscriptions.mediaLibraryListChannelName = `media-library-${id}`;
      subscriptions.realtimeMediaLibraryListChannel = db.channel(subscriptions.mediaLibraryListChannelName);

      subscriptions.realtimeMediaLibraryListChannel
        .on<MediaLibraryItem>(
          "postgres_changes",
          { event: "*", schema: "public", table: "media_library" },
          (payload) => {
            if (payload.eventType === "UPDATE") {
              dispatch(updateFileOnList(payload.new));
            }
            if (payload.eventType === "INSERT") {
              dispatch(addFileToList(payload.new));
            }
            if (payload.eventType === "DELETE" && payload.old.id) {
              dispatch(deleteFileFromList(payload.old.id));
            }
          },
        )
        .subscribe((status) => {
          if (status === "SUBSCRIBED") {
            dispatch(subscribeToMediaLibrarySuccess());
          }
          if (status === "TIMED_OUT") {
            dispatch(unsubscribeFromMediaLibrary());
          }
        });
    } catch {
      dispatch(subscribeToMediaLibraryFailed());
    }
  };
