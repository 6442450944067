export type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never;

export function hasKey<O extends object>(obj: O, key: string | number | symbol): key is keyof O {
  return key in obj;
}

type ExtraPropertiesError = "Object has extra properties";

export type Exact<T, Shape> = T extends Shape
  ? Exclude<keyof T, keyof Shape> extends never
    ? T
    : ExtraPropertiesError
  : never;

export function isExact<T, Shape>(obj: Exact<T, Shape>): Exact<T, Shape> {
  return obj;
}
