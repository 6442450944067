import { useQuery } from "@tanstack/react-query";

import {
  ExerciseRecordingStatus,
  type ExerciseRecordingWithExerciseInfo,
} from "@fitness-app/data-models/entities/ExerciseRecording";

import { supabase } from "~/store/initializeStore";

export const useExerciseRecordings = () => {
  const { data, isLoading } = useQuery(["exercise_recordings"], {
    queryFn: async () => {
      const query = supabase
        .from("exercise_recording_ownership")
        .select(
          "*, recording:exerciseRecordingId!inner(*,trainee:traineeId(firstName,lastName,email),exercise:parentExerciseId(name),plan:programId(name))",
        )
        .in("recording.status", [ExerciseRecordingStatus.RECORDED])
        .limit(100);

      const { error, data } = await query.returns<{ recording: ExerciseRecordingWithExerciseInfo }[]>();
      if (error) {
        return [];
      }
      return data;
    },
  });

  return {
    data: data?.map((item) => item.recording) || [],
    isLoading,
    count: data?.length || 0,
  };
};
