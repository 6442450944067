import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type TeamMember } from "@fitness-app/data-models";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchAppVersionConfig } from "./actions/fetchAppVersionConfig";
import { fetchTeamMember } from "./actions/fetchTeamMember";
import { fetchTeamMembers } from "./actions/fetchTeamMembers";
import { fetchTrainerConfig } from "./actions/fetchTrainerConfig";
import { TEAM_REDUCER_NAME, type TeamReducer } from "./types";

const initialState: TeamReducer = {
  listStatus: null,
  trainers: [],
  selectedMember: null,
  memberStatus: null,
  trainerConfig: null,
  appVersionConfig: null,
  redirectToUpdate: false,
};

const teamSlice = createSlice({
  initialState,
  name: TEAM_REDUCER_NAME,
  reducers: {
    subscribeToTeamPending(state) {
      state.listStatus = RequestStatus.SUBSCRIBING;
    },
    subscribeToTeamSuccess(state) {
      state.listStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToTeamFailed(state) {
      state.listStatus = RequestStatus.FAILED;
    },
    subscribeToTeamMemberPending(state) {
      state.memberStatus = RequestStatus.SUBSCRIBING;
    },
    subscribeToTeamMemberSuccess(state) {
      state.memberStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToTeamMemberFailed(state) {
      state.memberStatus = RequestStatus.FAILED;
    },
    unsubscribeFromTeamMember(state) {
      state.selectedMember = null;
      state.memberStatus = null;
    },
    unsubscribeFromTeam(state) {
      state.listStatus = null;
      state.trainers = [];
    },
    updateTrainerInTeam(state, { payload }: PayloadAction<Partial<TeamMember>>) {
      if (state.selectedMember && state.selectedMember?.id === payload.id) {
        state.selectedMember = {
          ...state.selectedMember,
          ...payload,
        };
      }

      state.trainers = state.trainers.map((trainer) => {
        if (trainer.id === payload.id) {
          return {
            ...trainer,
            ...payload,
          };
        }

        return trainer;
      });
    },
    removeTrainerFromTeam(state, { payload }: PayloadAction<string>) {
      state.trainers = state.trainers.filter((trainer) => trainer.id !== payload);
    },
    addTrainerToTeam(state, { payload }: PayloadAction<TeamMember>) {
      state.trainers = [{ ...payload, user: null, assignedTrainees: [] }, ...state.trainers];
    },
    updateTeamMember(state, { payload }: PayloadAction<TeamMember>) {
      if (state.selectedMember?.id === payload.id) {
        state.selectedMember = {
          ...state.selectedMember,
          ...payload,
        };
      }
      state.trainers.map((trainer) => {
        if (trainer.id === payload.id) {
          return {
            ...trainer,
            ...payload,
          };
        }
        return trainer;
      });
    },
    toggleRedirectToUpdate(state, { payload }: PayloadAction<boolean>) {
      state.redirectToUpdate = payload ?? !state.redirectToUpdate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeamMembers.fulfilled, (state, { payload }) => {
      state.trainers = payload;
    });
    builder.addCase(fetchTeamMember.fulfilled, (state, { payload }) => {
      state.selectedMember = payload;
    });
    builder.addCase(fetchTrainerConfig.fulfilled, (state, { payload }) => {
      state.trainerConfig = payload;
    });
    builder.addCase(fetchAppVersionConfig.fulfilled, (state, { payload }) => {
      state.appVersionConfig = payload;
    });
  },
});

export const {
  subscribeToTeamFailed,
  subscribeToTeamPending,
  subscribeToTeamSuccess,
  subscribeToTeamMemberFailed,
  subscribeToTeamMemberPending,
  subscribeToTeamMemberSuccess,
  unsubscribeFromTeam,
  unsubscribeFromTeamMember,
  updateTrainerInTeam,
  removeTrainerFromTeam,
  addTrainerToTeam,
  updateTeamMember,
  toggleRedirectToUpdate,
} = teamSlice.actions;
export default teamSlice.reducer;
