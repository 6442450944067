import React, { useEffect, type FunctionComponent } from "react";
import { Checkbox, Form, Input, InputNumber, Typography } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { createWFirmaRedirectLink } from "@fitness-app/utils";

import { DEFAULT_VAT_RATE } from "~/modules/Products/ProductsSettings/Invoices/clients/constants";
import { useVatIssuer } from "~/modules/Products/ProductsSettings/Invoices/clients/hooks/useVatIssuer";
import { useAppSelector } from "~/store/initializeStore";
import { type WFirmaIntegrationFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<WFirmaIntegrationFormModel>;
  onSubmit: (formData: WFirmaIntegrationFormModel) => void;
  model?: WFirmaIntegrationFormModel | null;
  step?: number;
}

type Props = OwnProps;

const disabledInputStyle = {
  backgroundColor: "#fff",
  color: "rgba(0, 0, 0, 0.85)",
};

const WFirmaIntegrationForm: FunctionComponent<Props> = ({ formController, onSubmit, model, step }) => {
  const { t } = useTranslation(["settings", "common"]);
  const user = useAppSelector((store) => store.user.data);
  const { isVatIssuer, onChangeVatIssuerField } = useVatIssuer(model?.settings?.isVatIssuer, formController);

  useEffect(() => {
    if (!model) {
      formController?.resetFields();
    } else {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const initialValues = {
    redirectUri: createWFirmaRedirectLink({
      userId: user?.id || "",
      functionDomain: import.meta.env.VITE_INTEGRATION_API_URL,
    }),
    scope: "invoices-read, invoices-write",
    ipAddress: import.meta.env.VITE_FUNCTION_STATIC_IP,
    appName: "Fitness app",
  };

  if (!step) {
    return (
      <div className="min-w-[450px]">
        <Typography.Title level={5}>{t("invoices.wfirma.stepFirstTitle")}</Typography.Title>
        <Typography.Paragraph>{t("invoices.wfirma.stepsToAddApp")}</Typography.Paragraph>
        <Typography.Paragraph>{t("invoices.wfirma.requiredData")}</Typography.Paragraph>

        <Form
          name="invoice-integration-form"
          layout="vertical"
          form={formController}
          onFinish={onSubmit}
          initialValues={initialValues}
        >
          <Form.Item
            name="appName"
            label={t("invoices.wfirma.appName")}
            help={
              <Typography.Paragraph
                type="secondary"
                style={{ fontSize: 13, marginTop: 4 }}
                copyable={{
                  text: initialValues.appName,
                  tooltips: [t("common:copy"), t("common:copied")],
                }}
              >
                {t("common:copy")}
              </Typography.Paragraph>
            }
          >
            <Input disabled style={disabledInputStyle} />
          </Form.Item>

          <Form.Item name="scope" label={t("invoices.wfirma.scope")}>
            <Input disabled style={disabledInputStyle} />
          </Form.Item>

          <Form.Item
            name="redirectUri"
            label={t("invoices.wfirma.redirectUri")}
            help={
              <Typography.Paragraph
                type="secondary"
                style={{ fontSize: 13, marginTop: 4 }}
                copyable={{
                  // text: initialValues.redirectUri,
                  tooltips: [t("common:copy"), t("common:copied")],
                }}
              >
                {t("common:copy")}
              </Typography.Paragraph>
            }
          >
            <Input disabled style={disabledInputStyle} />
          </Form.Item>

          <Form.Item
            name="ipAddress"
            label={t("invoices.wfirma.ipAddress")}
            help={
              <Typography.Paragraph
                type="secondary"
                style={{ fontSize: 13, marginTop: 4 }}
                copyable={{
                  text: initialValues.ipAddress,
                  tooltips: [t("common:copy"), t("common:copied")],
                }}
              >
                {t("common:copy")}
              </Typography.Paragraph>
            }
          >
            <Input disabled style={disabledInputStyle} />
          </Form.Item>
        </Form>

        <Typography.Title level={5}>{t("invoices.wfirma.afterVerification")}</Typography.Title>
      </div>
    );
  }

  return (
    <div className="min-w-[450px]">
      <Form<WFirmaIntegrationFormModel>
        name="invoice-integration-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        initialValues={{
          url: model?.url ?? "",
          settings: {
            isVatIssuer,
            defaultVatRate: DEFAULT_VAT_RATE,
          },
        }}
        validateTrigger={["onSubmit"]}
      >
        <Form.Item
          name="accountName"
          label={t("invoices.accountName")}
          tooltip={t("invoices.accountNameTooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input
            autoFocus={!model}
            disabled={!!model?.url}
            placeholder={t<string>("invoices.placeholder.accountName")}
          />
        </Form.Item>

        <Form.Item
          name="companyId"
          label={t("invoices.wfirma.companyId")}
          tooltip={t("invoices.wfirma.companyIdTooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="url"
          label={t("invoices.wfirma.clientId")}
          tooltip={t("invoices.wfirma.clientIdTooltip")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="clientSecret"
          tooltip={t("invoices.wfirma.apiKeyTooltip")}
          label={t("invoices.wfirma.apiKey")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input autoFocus={!!model} />
        </Form.Item>

        <Form.Item name={["settings", "isVatIssuer"]} valuePropName="checked">
          <Checkbox onChange={(e) => onChangeVatIssuerField(e.target.checked)}>{t("invoices.isVatIssuer")}</Checkbox>
        </Form.Item>

        {isVatIssuer ? (
          <Form.Item
            label={t("invoices.defaultVatRate")}
            name={["settings", "defaultVatRate"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber<string | number>
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value?.replace("%", "") ?? 0}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={t("invoices.legalVATExemption")}
            name={["settings", "legalVatExemption"]}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
              {
                max: 30,
                message: t<string>("common:validationErrors.maxLength", {
                  number: 30,
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default WFirmaIntegrationForm;
