import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { type DefaultOptionType } from "rc-select/lib/Select";
import { useTranslation } from "react-i18next";

import { programBuilderActions } from "@fitness-app/app-store";
import { type ProgramAutomation, type WorkoutRoutine } from "@fitness-app/data-models/entities/ProgramAutomation";

import {
  type SelectProgramFormModel,
  type WorkoutRoutineWithDates,
} from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface SelectProgramProps {
  selectedAutomation: ProgramAutomation;
  onSubmit: (model: SelectProgramFormModel) => void;
  formController: FormInstance<SelectProgramFormModel>;
  workoutRoutineModel?: WorkoutRoutine | WorkoutRoutineWithDates | null;
  withoutWeeksLimit?: boolean;
}

const SelectProgram = ({
  selectedAutomation,
  onSubmit,
  formController,
  workoutRoutineModel,
  withoutWeeksLimit,
}: SelectProgramProps) => {
  const { list } = useAppSelector((store) => store.programs);
  const { t } = useTranslation(["automation", "common"]);
  const [selectedProgramId, setProgram] = useState<null | string>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (workoutRoutineModel) {
      formController.setFieldsValue({
        program: workoutRoutineModel.name,
        startWeek: workoutRoutineModel.startWeek,
        endWeek: workoutRoutineModel.endWeek,
      });
    }
  }, [workoutRoutineModel]);

  const onProgramSelect = (programId: string, option: DefaultOptionType) => {
    if ("key" in option) {
      setProgram(option.key as string);
      void dispatch(
        programBuilderActions.validateProgramWithDetails({
          id: option.key as string,
        }),
      );
    }
  };

  const currentNumberOfScheduledWeeks = Object.keys(selectedAutomation.schedule).length;

  const handleSubmit = (model: SelectProgramFormModel) => {
    const foundProgram = list.find((program) => program.id === selectedProgramId);
    onSubmit({
      ...model,
      selectedProgram: foundProgram || null,
    });
  };

  return (
    <div>
      <Form<SelectProgramFormModel>
        name="schedule-program"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        form={formController}
        onFinish={handleSubmit}
        initialValues={{
          program: "",
          startWeek: 1,
          endWeek: currentNumberOfScheduledWeeks,
          additionalComment: "",
        }}
      >
        <Form.Item
          name="program"
          label="Wybierz program"
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            disabled={!!workoutRoutineModel}
            onClear={() => setProgram(null)}
            onSelect={onProgramSelect}
            filterOption={(inputValue, option) =>
              // @ts-expect-error ignore
              (option?.children as string)?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
            }
          >
            {list.map((program) => (
              <Select.Option key={program.id} value={program.name}>
                {program.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <>
          <Form.Item
            name="startWeek"
            label="Od tygodnia"
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber min={1} max={withoutWeeksLimit ? undefined : currentNumberOfScheduledWeeks} />
          </Form.Item>

          <Form.Item
            name="endWeek"
            label="Do tygodnia"
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber min={1} max={withoutWeeksLimit ? undefined : currentNumberOfScheduledWeeks} />
          </Form.Item>
        </>

        <Form.Item name="additionalComment" label="Uwagi do planu">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectProgram;
