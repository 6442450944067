import React, { useMemo } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import isEmpty from "lodash.isempty";
import orderBy from "lodash.orderby";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import {
  SurveyType,
  type ClientSurvey,
  type SurveyABAnswer,
  type SurveyQuestionAB,
  type SurveySelectAnswer,
  type SurveyTextAnswer,
} from "@fitness-app/data-models/entities/ClientSurvey";
import {
  SurveyQuestionType,
  type SurveyQuestionSelect,
  type SurveyQuestionText,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import ABAnswer from "~/components/SurveyDetails/SurveyAnswers/ABAnswer";
import SelectAnswer from "~/components/SurveyDetails/SurveyAnswers/SelectAnswer";
import TextAnswer from "~/components/SurveyDetails/SurveyAnswers/TextAnswer";

interface SurveyDetailsProps {
  survey: ClientSurvey | null;
  onCancel: () => void;
}

const { Column } = Table;

export const questionTypeLabels = {
  [SurveyQuestionType.Text]: "Własna odpowiedź",
  [SurveyQuestionType.Select]: "Wybór opcji",
  [SurveyQuestionType.AB]: "Tak/nie",
};

const getSurveyDetailsTableData = (surveyDetails: ClientSurvey) => {
  if (!surveyDetails?.questions) {
    return [];
  }
  const questions = orderBy(
    Object.entries(surveyDetails.questions).map(([id, question]) => ({ ...question, id })),
    "orderKey",
  );
  if (!surveyDetails.answers || isEmpty(surveyDetails.answers)) {
    return questions;
  }
  return questions.map((question) => ({
    ...question,
    clientAnswers: surveyDetails.answers?.[question.id] || null,
  }));
};

const SurveyDetails = ({ survey, onCancel }: SurveyDetailsProps) => {
  const { t } = useTranslation("surveys");
  const expandedTable = useMemo(() => Boolean(survey?.answers && !isEmpty(survey.answers)), [survey]);
  const renderAnswer = (
    question:
      | (SurveyQuestionAB & { clientAnswers: SurveyABAnswer })
      | (SurveyQuestionText & { clientAnswers: SurveyTextAnswer })
      | (SurveyQuestionSelect & { clientAnswers: SurveySelectAnswer }),
  ) => {
    if (!question.clientAnswers) {
      return <p>{t("noAnswerForQuestion")}</p>;
    }
    if (question.type === SurveyQuestionType.Text) {
      return <TextAnswer disabled value={String(question.clientAnswers.answer)} />;
    }
    if (question.type === SurveyQuestionType.AB) {
      return (
        <ABAnswer
          disabled
          value={question.clientAnswers.answer}
          extendedAnswer={question.clientAnswers.extendedAnswer}
        />
      );
    }
    if (question.type === SurveyQuestionType.Select) {
      return (
        <SelectAnswer
          options={question.answers}
          multiSelect={question.multiSelect}
          value={question.clientAnswers.answer}
          isInitial={survey?.type === SurveyType.Initial || survey?.type === SurveyType.OneTime}
        />
      );
    }
  };

  return (
    <Modal
      visible={!!survey}
      title={t("surveyDetails")}
      width={750}
      onCancel={onCancel}
      footer={
        <Button key="back" onClick={onCancel} data-ctx="close-modal" type="primary">
          {t("common:button.close")}
        </Button>
      }
    >
      {survey && (
        <Table
          // @ts-expect-error ignore
          dataSource={getSurveyDetailsTableData(survey)}
          locale={{ emptyText: t("emptyState") }}
          pagination={false}
          size="middle"
          rowKey="id"
          expandedRowRender={renderAnswer}
          defaultExpandAllRows={expandedTable}
        >
          <Column title="Nr" align="center" key="index" width="5%" render={(_, __, index) => index + 1} />
          <Column
            title="Pytanie"
            dataIndex="title"
            key="title"
            width="35%"
            render={(rowText) => truncate(rowText, { length: 80 })}
          />
          <Column
            title="Typ pytania"
            dataIndex="type"
            key="type"
            width="30%"
            render={(type: SurveyQuestionType) => questionTypeLabels[type]}
          />
          <Column
            title="Obowiązkowe"
            dataIndex="isRequired"
            key="isRequired"
            render={(isRequired) =>
              isRequired ? (
                <CheckOutlined style={{ color: "#25b10f" }} />
              ) : (
                <CloseOutlined style={{ color: "#d6d6d6" }} />
              )
            }
          />
          <Column
            title="Odpowiedź"
            dataIndex="hasAnswer"
            key="hasAnswer"
            render={(_, row: any) =>
              row.clientAnswers ? (
                <CheckOutlined style={{ color: "#25b10f" }} />
              ) : (
                <CloseOutlined style={{ color: "#d6d6d6" }} />
              )
            }
          />
        </Table>
      )}
    </Modal>
  );
};

export default SurveyDetails;
