import React, { useEffect, useState } from "react";
import { ProfileOutlined, ScheduleOutlined } from "@ant-design/icons";
import { Divider, Form, Modal, Steps } from "antd";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { programsActions, RequestStatus } from "@fitness-app/app-store";
import { type ProgramAutomation, type WorkoutRoutine } from "@fitness-app/data-models/entities/ProgramAutomation";
import { TrainingProgramLevel, TrainingProgramType } from "@fitness-app/data-models/entities/TrainingProgram";
import { createProgramDetailsTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import ProgramWeekSchedule from "~/modules/Automation/ScheduleProgramStepper/steps/ProgramWeekSchedule";
import SelectProgram from "~/modules/Automation/ScheduleProgramStepper/steps/SelectProgram";
import {
  type ProgramScheduleFormModel,
  type SelectProgramFormModel,
} from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface ScheduleProgramStepperProps<T extends WorkoutRoutine> {
  visible: boolean;
  handleClose: () => void;
  selectedAutomation: ProgramAutomation;
  routineModel?: WorkoutRoutine;
  onSubmit: (workoutRoutine: T) => void | Promise<void>;
  withoutWeeksLimit?: boolean;
}

export const useScheduleProgramStepper = () => {
  const [visible, toggleVisibility] = useState(false);
  const [editedRoutine, setRoutineModel] = useState<null | WorkoutRoutine>(null);

  const closeModal = () => {
    toggleVisibility(false);
    setRoutineModel(null);
  };

  const openModal = (routine?: WorkoutRoutine) => {
    if (routine) {
      setRoutineModel(routine);
    }
    toggleVisibility(true);
  };

  return {
    visible,
    closeModal,
    openModal,
    routineModel: editedRoutine,
  };
};

enum ProgramStep {
  SelectProgram,
  // UpdateWorkout,
  SetSchedule,
}

function ScheduleProgramStepper<T extends WorkoutRoutine>({
  visible,
  handleClose,
  selectedAutomation,
  routineModel,
  onSubmit,
  withoutWeeksLimit,
}: ScheduleProgramStepperProps<T>) {
  const [step, setStep] = useState(ProgramStep.SelectProgram);
  const { t } = useTranslation("automation");
  const dispatch = useAppDispatch();
  const { listStatus } = useAppSelector((store) => store.programs);
  const [selectProgramControllerForm] = Form.useForm<SelectProgramFormModel>();
  const [scheduleProgramControllerForm] = Form.useForm<ProgramScheduleFormModel>();
  const programDetails = useAppSelector((store) => store.programBuilder.validatedProgram);
  const [workoutRoutine, setWorkoutRoutine] = useState<null | WorkoutRoutine>(routineModel || null);
  const [saving, toggleSaving] = useState(false);
  const { userId } = useUserRole();

  useEffect(() => {
    if (listStatus !== RequestStatus.SUCCESS) {
      void dispatch(programsActions.fetchPrograms());
    }
  }, [dispatch]);

  const handleProgramSelect = (model: SelectProgramFormModel) => {
    const { selectedProgram, ...rest } = model;

    if (routineModel) {
      const scheduledProgram: WorkoutRoutine = {
        ...routineModel,
        startWeek: model.startWeek,
        endWeek: model.endWeek,
        duration:
          model.startAt && model.endAt
            ? Math.ceil(model.endAt.diff(model.startAt, "week"))
            : model.endWeek - model.startWeek + 1,
      };
      setWorkoutRoutine(scheduledProgram);
      setStep((prev) => prev + 1);
    } else {
      const id = selectedProgram?.id || uuid();

      const scheduledProgram: WorkoutRoutine = {
        level: TrainingProgramLevel.BEGINNER,
        shared: true,
        type: TrainingProgramType.FULL_BODY_WORKOUT,
        ...(selectedProgram || {}),
        id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: userId,
        archived: false,
        tags: [],
        comment: selectedProgram?.comment || "",
        ...rest,
        name: model.program,
        duration: model.endWeek - model.startWeek + 1,
        schedule: null,
        routineId: uuid(),
        programWorkouts: programDetails?.programId === id ? programDetails : createProgramDetailsTemplate(id),
      };

      setWorkoutRoutine(scheduledProgram);
      setStep((prev) => prev + 1);
    }
  };

  const handleScheduleSave = async (model: ProgramScheduleFormModel) => {
    if (workoutRoutine) {
      toggleSaving(true);

      await onSubmit({
        ...workoutRoutine,
        schedule: { exceptionWeeks: {}, weekSchedule: model.weeks },
      } as T);

      handleClose();
      toggleSaving(false);
      setWorkoutRoutine(null);
      setStep(ProgramStep.SelectProgram);
    }
  };

  const renderStep = () => {
    switch (step) {
      case ProgramStep.SelectProgram:
        return (
          <SelectProgram
            workoutRoutineModel={routineModel}
            selectedAutomation={selectedAutomation}
            onSubmit={handleProgramSelect}
            formController={selectProgramControllerForm}
            withoutWeeksLimit={withoutWeeksLimit}
          />
        );
      case ProgramStep.SetSchedule:
        return workoutRoutine ? (
          <ProgramWeekSchedule
            programWorkouts={workoutRoutine.programWorkouts}
            schedule={routineModel?.schedule?.weekSchedule}
            formController={scheduleProgramControllerForm}
            onSubmit={handleScheduleSave}
          />
        ) : null;
      default:
        return null;
    }
  };

  const onNext = () => {
    if (step === ProgramStep.SetSchedule) {
      scheduleProgramControllerForm.submit();
    }

    if (step === ProgramStep.SelectProgram) {
      selectProgramControllerForm.submit();
    }
  };

  const handleCancelButton = () => {
    if (step === ProgramStep.SelectProgram) {
      handleClose();
      return;
    }

    setStep((prev) => prev - 1);
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancelButton}
      onOk={onNext}
      destroyOnClose
      width={900}
      title="Dodaj plan treningowy"
      okButtonProps={{
        loading: saving,
      }}
      cancelText={step === ProgramStep.SelectProgram ? t("common:button.cancel") : t("common:button.prev")}
      okText={step === ProgramStep.SetSchedule ? t("common:button.add") : t("common:button.next")}
    >
      <Steps current={step}>
        <Steps.Step key={0} title="Wybierz plan" icon={<ProfileOutlined />} />
        <Steps.Step key={2} title="Ustaw dni treningowe" icon={<ScheduleOutlined />} />
      </Steps>
      <Divider />
      <div className="p-6">{renderStep()}</div>
    </Modal>
  );
}

export default ScheduleProgramStepper;
