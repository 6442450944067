import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ProgramAutomationTask } from "@fitness-app/data-models/entities/ProgramAutomation";

import { RequestStatus } from "../../../enums/requestStatus";
import { fetchClientTasks } from "./actions/fetchClientTasks";
import { removeClientTask } from "./actions/removeClientTask";
import { updateClientTask } from "./actions/updateClientTask";
import { CLIENT_SCHEDULED_TASKS_REDUCER_NAME, type ClientScheduledTasksReducer, type SelectedMonth } from "./types";

const initialState: ClientScheduledTasksReducer = {
  list: [],
  listStatus: null,
  selectedMonth: null,
  selectedTask: null,
};

const reducerSlice = createSlice({
  initialState,
  name: CLIENT_SCHEDULED_TASKS_REDUCER_NAME,
  reducers: {
    subscribeToClientScheduledTasksStarted(state, { payload }: PayloadAction<SelectedMonth>) {
      state.listStatus = RequestStatus.SUBSCRIBING;
      state.selectedMonth = payload;
    },
    subscribeToClientScheduledTasksSuccess(state, { payload }: PayloadAction<ProgramAutomationTask[]>) {
      state.listStatus = RequestStatus.SUBSCRIBED;
      state.list = payload;
    },
    subscribeToClientScheduledTasksFailed(state) {
      state.listStatus = RequestStatus.FAILED;
      state.selectedMonth = null;
    },
    unsubscribeFromClientScheduledTasks(state) {
      state.listStatus = null;
      state.selectedMonth = null;
      state.list = [];
    },
    setSelectedTask(state, { payload }: PayloadAction<ProgramAutomationTask | null>) {
      state.selectedTask = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientTasks.pending, (state) => {
      state.listStatus = RequestStatus.FETCHING;
      state.selectedMonth = null;
    });
    builder.addCase(fetchClientTasks.fulfilled, (state, action) => {
      state.selectedMonth = action.payload.selectedMonth;
      state.list = action.payload.data;
      state.listStatus = RequestStatus.SUCCESS;
    });
    builder.addCase(fetchClientTasks.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
      state.selectedMonth = null;
    });
    builder.addCase(updateClientTask.pending, (state, action) => {
      if (action.meta.arg.isOptimisticUpdate) {
        state.list = state.list.map((task) =>
          task.id !== action.meta.arg.id ? task : { ...task, ...action.meta.arg.task },
        );
      }
    });
    builder.addCase(updateClientTask.fulfilled, (state, action) => {
      if (action.meta.arg.isOptimisticUpdate) {
        state.list = state.list.map((task) =>
          task.id !== action.meta.arg.id ? task : { ...task, ...action.meta.arg.task },
        );
      }
    });
    builder.addCase(removeClientTask.fulfilled, (state, action) => {
      state.list = state.list.filter((task) => task.id !== action.meta.arg);
    });
  },
});

export const {
  subscribeToClientScheduledTasksStarted,
  unsubscribeFromClientScheduledTasks,
  subscribeToClientScheduledTasksFailed,
  subscribeToClientScheduledTasksSuccess,
  setSelectedTask,
} = reducerSlice.actions;

export default reducerSlice.reducer;
