import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import { type TrainingProgramWithCreator } from "@fitness-app/data-models/entities/TrainingProgram";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { getUserRole } from "../../user/selectors";
import { PROGRAMS_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchPrograms = createAsyncThunk<TrainingProgramWithCreator[], Payload, AsyncThunkCreator<string>>(
  `${PROGRAMS_REDUCER_NAME}/fetchPrograms`,
  async (_payload, { rejectWithValue, getState, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);
    const role = getUserRole(getState());

    let query = db.from("training_program").select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

    if (role !== UserRole.TRAINER) {
      query = query.or(`createdBy.eq.${currentUser.id}, shared.is.${true}`);
    }

    const { data, error } = await query
      .eq("archived", false)
      .order("createdAt", { ascending: false })
      .returns<TrainingProgramWithCreator[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
