import UnknownError from "./UnknownError";

export const isStandardError = (unknownError: unknown): unknownError is Error => unknownError instanceof Error;

export const getErrorMessage = (unknownError: unknown): string =>
  isStandardError(unknownError) ? unknownError.message : UnknownError.message;

export const inferAsError = (unknownError: unknown): Error =>
  unknownError instanceof Error
    ? unknownError
    : new Error(
        unknownError && typeof unknownError === "object" && "message" in unknownError
          ? (unknownError?.message as string) || "Unknown error"
          : String(unknownError),
      );

export const getError = (
  error: unknown,
): Error & {
  status: number;
  code?: string | number;
  errorCode?: string | number;
  response?: { message?: string; data?: object; status?: string | number };
} => (isStandardError(error) ? (error as Error & { status: number }) : new UnknownError(error));
