import React from "react";

import { type RequestStatus } from "@fitness-app/app-store";
import {
  type ClientTrainingProgram,
  type ClientTrainingProgramDetails,
  type ProgramWorkout,
  type TrainingProgram,
  type TrainingProgramDetails,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { type WorkoutTemplate } from "@fitness-app/data-models/entities/WorkoutTemplate";

export interface IProgramBuilderContext {
  programStatus: RequestStatus | null;
  programDetails: TrainingProgramDetails | ClientTrainingProgramDetails | null;
  programId: string | null;
  selectedProgram: null | TrainingProgram | ClientTrainingProgram;
  duplicateWorkoutInProgram: (params: { workout: ProgramWorkout }) => Promise<void>;
  fetchProgramWithDetails: () => Promise<void>;
  addNewWorkoutToProgram: (
    params:
      | { workoutId: string; workoutTemplates?: never }
      | { workoutId?: never; workoutTemplates: WorkoutTemplate[] },
  ) => Promise<void>;
  removeWorkoutFromProgram: (params: { workoutId: string }) => Promise<true | false | void>;
  replaceWorkoutInProgram: (params: {
    workoutIdToReplace: string;
    workoutTemplate: WorkoutTemplate | ProgramWorkout;
  }) => Promise<void> | void;
  updateWorkoutInProgram: (params: { workout: ProgramWorkout }) => Promise<void> | void;
  touched?: boolean;
  showRecordingVideoSwitch?: boolean;
}

export const ProgramBuilderContext = React.createContext<IProgramBuilderContext | undefined>(undefined);

export const useProgramBuilderContext = () => {
  const context = React.useContext(ProgramBuilderContext);
  if (!context) {
    throw new Error("useProgramBuilderContext must be used within a ProgramBuilderProvider");
  }
  return context;
};

export const useProgramIsTouched = () => {
  const context = React.useContext(ProgramBuilderContext);

  return context?.touched ?? false;
};

const ProgramBuilderProvider = ({ children, ...rest }: { children: React.ReactElement } & IProgramBuilderContext) => {
  return <ProgramBuilderContext.Provider value={rest}>{children}</ProgramBuilderContext.Provider>;
};

export default ProgramBuilderProvider;
