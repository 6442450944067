import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  PlusOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Input, Modal, Space, Switch, Table, Tag, type InputRef, type MenuProps } from "antd";
import { type ColumnType, type FilterConfirmProps } from "antd/es/table/interface";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { programsActions, RequestStatus } from "@fitness-app/app-store";
import { type MealsPlanWithCreator } from "@fitness-app/data-models/entities/MealsPlan";
import {
  type TrainingProgramLevelEnum,
  type TrainingProgramTypeEnum,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useTagOptions } from "~/hooks/useTagOptions";
import ProgramForm from "~/modules/TrainingPrograms/components/ProgramForm/ProgramForm";
import {
  programLevelOptions,
  programTypeOptions,
  type ProgramFormModel,
} from "~/modules/TrainingPrograms/components/ProgramForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const ProgramsList = () => {
  const { t } = useTranslation(["workouts", "common"]);
  const [showProgramForm, setShowProgramForm] = useState(false);
  const { listStatus, list, creators } = useAppSelector((store) => store.programs);
  const dispatch = useAppDispatch();
  const { filters: tagsFilters } = useTagOptions("trainingProgramTags");
  const [modal, contextHolder] = Modal.useModal();

  const [_searchText, setSearchText] = useState("");
  const searchInput = useRef<InputRef>(null);
  const loggedUser = useAppSelector((store) => store.user.data);
  const [editedModel, setEditedModel] = useState<(ProgramFormModel & { id: string }) | null>(null);
  const navigate = useNavigate();
  const { isTrainer, userId } = useUserRole();

  const [savingProgram, onSuccess, onFailure, onStart] = useEntityChange(() => {
    setShowProgramForm(false);
    setEditedModel(null);
  });

  const [duplicatingProgram, onSuccessDuplicated, onFailureDuplicated, onStartDuplicating] = useEntityChange();

  const handleReset = (clearFilters?: () => void) => {
    clearFilters?.();
    setSearchText("");
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0] ?? "");
  };

  const getColumnSearchProps = useCallback(
    (): ColumnType<TrainingProgramWithCreator> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close, clearFilters }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.search")}
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters);
                confirm({ closeDropdown: false });
              }}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {t("common:button.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
      onFilter: (value, record) =>
        record.name
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    }),
    [handleSearch, handleReset, t],
  );

  useEffect(() => {
    void dispatch(programsActions.fetchPrograms());
  }, [dispatch]);

  const toggleSharedState = async (programId: string, shared: boolean) => {
    await dispatch(
      programsActions.updateProgram({
        programId,
        program: {
          shared,
          updatedAt: new Date().toISOString(),
        },
      }),
    ).unwrap();
  };

  const onSubmitProgramForm = async (model: ProgramFormModel) => {
    onStart();
    try {
      if (editedModel) {
        await dispatch(
          programsActions.updateProgram({
            programId: editedModel.id,
            program: {
              ...model,
              updatedAt: new Date().toISOString(),
            },
          }),
        ).unwrap();
      } else {
        const programId = await dispatch(
          programsActions.addProgram({
            program: {
              ...model,
              shared: isTrainer,
              tags: model.tags || [],
              id: uuid(),
              createdBy: loggedUser?.id || null,
              updatedAt: new Date().toISOString(),
              createdAt: new Date().toISOString(),
              archived: false,
            },
          }),
        ).unwrap();

        navigate(programId);
      }

      onSuccess();
    } catch {
      onFailure();
    }
  };

  const duplicateProgram = async (program: TrainingProgramWithCreator) => {
    onStartDuplicating(program.id, t<string>("program.duplicating"));
    try {
      await dispatch(programsActions.duplicateProgram(program)).unwrap();
      onSuccessDuplicated();
    } catch {
      onFailureDuplicated();
    }
  };

  const getMenuItems = useCallback(
    (row: TrainingProgramWithCreator): MenuProps["items"] => [
      {
        label: t("common:button.duplicate"),
        key: "duplicate",
        icon: <CopyOutlined />,
        onClick: () => {
          void duplicateProgram(row);
        },
      },
      {
        label: t("common:button.edit"),
        key: "edit",
        icon: <EditOutlined />,
        onClick: () => {
          setEditedModel({
            name: row.name,
            level: row.level || null,
            type: row.type || null,
            comment: row.comment || "",
            id: row.id,
            tags: row.tags || [],
          });
          setShowProgramForm(true);
        },
      },
      {
        label: t("common:button.archive"),
        key: "delete",
        icon: <DeleteOutlined />,
        danger: true,

        onClick: () => {
          void modal.confirm({
            title: t("exercisesTable.deleteWarning"),
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { danger: true },
            okText: t("common:button.archive"),
            onOk() {
              void dispatch(
                programsActions.updateProgram({
                  programId: row.id,
                  program: { archived: true },
                }),
              );
            },
          });
        },
        disabled: !isTrainer && row.createdBy !== userId,
      },
    ],
    [t, isTrainer, userId],
  );

  return (
    <Card
      extra={
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowProgramForm(true)}>
          {t("program.addNewProgram")}
        </Button>
      }
    >
      <Table
        dataSource={list}
        loading={listStatus === RequestStatus.FETCHING}
        pagination={false}
        rowKey="id"
        scroll={{ x: true }}
      >
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.name")}
          dataIndex="name"
          key="name"
          sorter={(a, b) => a.name.localeCompare(b.name)}
          {...getColumnSearchProps()}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.type")}
          dataIndex="type"
          key="type"
          render={(type) => t(`programType.${type}`)}
          filters={programTypeOptions.map((part) => ({
            text: part.label,
            value: part.value,
          }))}
          onFilter={(value, record) => (record.type ? record.type.includes(value as TrainingProgramTypeEnum) : false)}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.level")}
          dataIndex="level"
          key="level"
          render={(level) => t(`programLevel.${level}`)}
          filters={programLevelOptions.map((part) => ({
            text: part.label,
            value: part.value,
          }))}
          onFilter={(value, record) =>
            record.level ? record.level.includes(value as TrainingProgramLevelEnum) : false
          }
        />
        <Table.Column<MealsPlanWithCreator>
          title={t("program.tags")}
          dataIndex="tags"
          key="tags"
          filterMultiple
          filterSearch
          filters={tagsFilters}
          onFilter={(value, record) => (record.tags || []).includes(value as string)}
          render={(tags: string[]) =>
            tags?.length ? (
              <Space direction="vertical">
                {tags.map((tag, i) => (
                  <Tag key={`${tag}-${i}`}>{tag}</Tag>
                ))}
              </Space>
            ) : (
              "-"
            )
          }
        />

        <Table.Column<TrainingProgramWithCreator>
          title={t("program.createdBy")}
          dataIndex="createdBy"
          key="createdBy"
          filters={creators.map((creator) => ({
            text: creator.label,
            value: creator.value,
          }))}
          onFilter={(value, record) => record.createdBy === value}
          render={(_createdBy: string, row) => {
            if (row.creator) {
              return `${row.creator.firstName} ${row.creator.lastName}`;
            }
            return "-";
          }}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.shared")}
          dataIndex="shared"
          key="shared"
          render={(shared: boolean, row) => {
            return (
              <Switch
                unCheckedChildren={<EyeInvisibleOutlined />}
                checkedChildren={<ShareAltOutlined />}
                disabled={!isTrainer && row.createdBy !== userId}
                defaultChecked={shared}
                onChange={(change) => {
                  void toggleSharedState(row.id, change);
                }}
              />
            );
          }}
        />
        <Table.Column<TrainingProgramWithCreator>
          title={t("program.options")}
          key="action"
          align="left"
          render={(_, row) => (
            <>
              <Dropdown.Button
                menu={{ items: getMenuItems(row) }}
                loading={duplicatingProgram === row.id}
                onClick={() => {
                  navigate(row.id);
                }}
              >
                {t("common:button.details")}
              </Dropdown.Button>
              {contextHolder}
            </>
          )}
        />
      </Table>
      <ModalForm
        title={editedModel ? t("program.editProgram") : t("program.addNewProgram")}
        loading={!!savingProgram}
        open={showProgramForm}
        onCancel={() => {
          setShowProgramForm(false);
          setEditedModel(null);
        }}
      >
        <ProgramForm onSubmit={onSubmitProgramForm} model={editedModel} />
      </ModalForm>
    </Card>
  );
};

export default ProgramsList;
