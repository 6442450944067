import React, { useState } from "react";
import { EditOutlined, ScheduleOutlined, UserAddOutlined } from "@ant-design/icons";
import { Divider, Form, message, Modal, Spin, Steps } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { traineeProgramActions } from "@fitness-app/app-store";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";
import {
  ClientProgramStatus,
  TrainingProgramLevel,
  TrainingProgramType,
  type ClientTrainingProgram,
  type TrainingProgramDetails,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { getErrorMessage } from "@fitness-app/utils";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";
import { createClientProgramDetailsTemplate } from "@fitness-app/utils/src/programs/workoutsTemplate";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import ProgramWeekSchedule from "~/modules/Automation/ScheduleProgramStepper/steps/ProgramWeekSchedule";
import { type ProgramScheduleFormModel } from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import TraineeSelectProgram from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/TraineeSelectProgram";
import { type TraineeSelectProgramModel } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeTrainingProgram/types";
import ProgramBuilder from "~/modules/TrainingPrograms/ProgramBuilder/ProgramBuilder";
import TraineeProgramProvider from "~/shared/providers/TraineeProgramProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeProgramStepperProps {
  open: boolean;
  onClose: () => void;
  traineeId: string;
}

enum Step {
  SelectProgram,
  EditWorkouts,
  SetSchedule,
}

const TraineeProgramStepper = ({ open, onClose, traineeId }: TraineeProgramStepperProps) => {
  const { t } = useTranslation(["trainees"]);

  const [step, setStep] = useState(Step.SelectProgram);
  const [selectedClientProgram, setClientProgram] = useState<null | ClientTrainingProgram>(null);
  const [scheduleProgramControllerForm] = Form.useForm<ProgramScheduleFormModel>();
  const [selectProgramControllerForm] = Form.useForm<TraineeSelectProgramModel>();
  const traineeProfile = useAppSelector((store) => store.trainee.profile);
  const [isLoading, toggleLoading] = useState(false);
  const { programDetails } = useAppSelector((state) => state.traineeProgram);

  const traineeStatus = traineeProfile?.status || TraineeStatus.INACTIVE;
  const hasAddedPlan = Boolean(traineeProfile?.activeTrainingProgramId);
  const { userId } = useUserRole();
  const dispatch = useAppDispatch();

  const onNext = () => {
    if (step === Step.SelectProgram) {
      selectProgramControllerForm.submit();
    }

    if (step === Step.EditWorkouts) {
      setStep(Step.SetSchedule);
    }

    if (step === Step.SetSchedule) {
      scheduleProgramControllerForm.submit();
    }
  };

  const handleScheduleSave = async (model: ProgramScheduleFormModel) => {
    toggleLoading(true);

    await dispatch(
      traineeProgramActions.setProgramSchedule({
        programSchedule: model.weeks,
      }),
    );

    onClose();
    toggleLoading(false);

    setClientProgram(null);
    setStep(Step.SelectProgram);
  };

  const handleProgramSelect = async (
    program: TraineeSelectProgramModel,
    fetchedProgram?: { plan: TrainingProgramWithCreator; details: TrainingProgramDetails } | null,
  ) => {
    toggleLoading(true);
    let duration = program.duration || 1;
    const endDate = program.endDate || dayjs().add(1, "month");

    let endDateStr = "";

    if (program.lengthUnit === "weeks") {
      endDateStr = program.startDate.add(duration, "weeks").endOf("day").format("YYYY-MM-DD");
    }

    if (program.lengthUnit === "months") {
      endDateStr = program.startDate.add(duration, "months").endOf("day").format("YYYY-MM-DD");
    }

    if (program.lengthUnit === "selectedDate") {
      endDateStr = endDate.endOf("day").format("YYYY-MM-DD");
      duration = Math.ceil(endDate.diff(program.startDate, "weeks", false));
    }

    const { id } = fetchedProgram?.plan || {};

    const clientProgram: ClientTrainingProgram = {
      id: generateUniqId(),
      programTemplateId: id || null,
      tags: fetchedProgram?.plan.tags || [],
      level: fetchedProgram?.plan.level || TrainingProgramLevel.BEGINNER,
      type: fetchedProgram?.plan.type || TrainingProgramType.FULL_BODY_WORKOUT,
      comment: program.comment || fetchedProgram?.plan.comment || "",
      addedAt: dayjs().toISOString(),
      createdAt: dayjs().toISOString(),
      updatedAt: dayjs().toISOString(),
      addedBy: userId,
      name: program.programId,
      duration,
      startDate: program.startDate.format("YYYY-MM-DD"),
      endDate: endDateStr,
      workoutsSchedule: null,
      syncWithTemplate: false,
      archived: false,
      archivedAt: null,
      archivedBy: null,
      status: ClientProgramStatus.InProgress,
      traineeId,
      lastNotificationSentAt: null,
      metadata: null,
    };

    try {
      await dispatch(
        traineeProgramActions.addProgramToTrainee({
          traineeId,
          program: clientProgram,
          hasActiveProgram: program.hasActiveProgram ?? false,
          keepOldTrainingWithoutStatus: program.keepOldTrainingWithoutStatus ?? false,
          programDetails: fetchedProgram?.details
            ? {
                ...fetchedProgram.details,
                traineeId,
                programTemplateId: fetchedProgram.details.id,
                id: generateUniqId(),
                programId: clientProgram.id,
              }
            : createClientProgramDetailsTemplate({ programId: clientProgram.id, programTemplateId: null, traineeId }),
        }),
      ).unwrap();
      setClientProgram(clientProgram);
      setStep(Step.EditWorkouts);
    } catch (e) {
      void message.error(`Nie można dodać planu treningowego do klienta: ${getErrorMessage(e)}`);
    } finally {
      toggleLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case Step.SelectProgram:
        return (
          <TraineeSelectProgram
            isUpdatingPlan={hasAddedPlan}
            traineeStatus={traineeStatus}
            onSubmit={handleProgramSelect}
            formController={selectProgramControllerForm}
          />
        );
      case Step.EditWorkouts:
        return selectedClientProgram ? (
          <TraineeProgramProvider assignedProgramId={selectedClientProgram.id}>
            <ProgramBuilder />
          </TraineeProgramProvider>
        ) : (
          <Spin />
        );
      case Step.SetSchedule:
        return selectedClientProgram && programDetails ? (
          <ProgramWeekSchedule
            programWorkouts={programDetails}
            schedule={selectedClientProgram?.workoutsSchedule?.schedule}
            formController={scheduleProgramControllerForm}
            onSubmit={handleScheduleSave}
          />
        ) : (
          <Spin />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      width={950}
      open={open}
      destroyOnClose
      maskClosable={false}
      title="Aktywuj plan treningowy"
      cancelText={t("common:button.cancel")}
      okText={step === Step.SelectProgram ? t("common:button.add") : t("common:button.save")}
      onCancel={onClose}
      onOk={onNext}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Steps current={step} className="mt-4">
        <Steps.Step key={Step.SelectProgram} title="Wybierz plan" icon={<UserAddOutlined />} />
        <Steps.Step key={Step.EditWorkouts} title="Edycja treningów" icon={<EditOutlined />} />
        <Steps.Step key={Step.SetSchedule} title="Ustaw dni treningowe" icon={<ScheduleOutlined />} />
      </Steps>
      <Divider />
      <div className="p-6">{renderStep()}</div>
    </Modal>
  );
};

export default TraineeProgramStepper;
