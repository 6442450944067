import React, { type FunctionComponent } from "react";
import { Badge, Radio, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/store/initializeStore";
import PaymentIntegration from "./PaymentIntegration";
import StripeIcon from "./stripe.svg";

const PaymentGateways: FunctionComponent = () => {
  const { data } = useAppSelector((state) => state.productsSettings);
  const { t } = useTranslation("settings");
  if (data?.stripeConnectedAccountId) {
    return (
      <div className="mx-auto my-12 flex max-w-[800px] flex-col text-center">
        <Radio.Group size="large" value="stripe">
          <Radio.Button value="stripe">
            <img
              src={StripeIcon}
              alt={`stripe-icon`}
              className="h-full w-full max-w-[200px] object-contain	align-text-bottom	"
            />
          </Radio.Button>
        </Radio.Group>
        <Space direction="vertical" size={24}>
          <Row gutter={24} justify="center">
            <div style={{ maxWidth: 800, textAlign: "center" }} className="max-w-[800px] text-center">
              <Space size={16} direction="vertical" style={{ marginTop: 40 }}>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {t("clientIntegration.status")}
                </Typography.Title>
                <Typography.Paragraph style={{ marginBottom: 0 }}>
                  {t("paymentGateway.stripe.accountId")}: <strong>{data?.stripeConnectedAccountId}</strong>
                </Typography.Paragraph>
                <Badge status="success" text={t("clientIntegration.completed")} />
              </Space>
            </div>
          </Row>
        </Space>
      </div>
    );
  }
  return (
    <div>
      <PaymentIntegration />
    </div>
  );
};

export default PaymentGateways;
