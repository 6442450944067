import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProductChargeType } from "@fitness-app/data-models/entities/Product";

import { getProductPrice } from "~/modules/Products/hooks/useProductTaskAutomation";
import { useAppSelector } from "~/store/initializeStore";

export const useProductPricesOptions = () => {
  const { t } = useTranslation("products");
  const productDetails = useAppSelector((store) => store.product.details);

  return useMemo(() => {
    if (productDetails && productDetails.chargeType !== ProductChargeType.FREE) {
      return productDetails.prices.map(({ type, accessPeriod, id, currency, unit_amount }) => ({
        label: `${t(`products:paymentTypeOptions.${type}`)} ${
          type === "recurring" || (type === "one_time" && accessPeriod)
            ? ` (${t(`products:${type}.${accessPeriod?.interval}`, {
                count: accessPeriod?.interval_count,
              })})`
            : ""
        } - ${getProductPrice({
          currency,
          unit_amount,
        })}`,
        value: id,
      }));
    }
    return [];
  }, [productDetails]);
};
