import React, { useCallback, useEffect } from "react";

import {
  exercisesActions,
  traineeActivitiesActions,
  traineeProgramActions,
  workoutTemplatesActions,
} from "@fitness-app/app-store";
import { replaceExercisesInWorkoutFromTemplate } from "@fitness-app/utils/src/programs/replaceExercisesInWorkoutFromTemplate";

import ProgramBuilderProvider, { type IProgramBuilderContext } from "~/shared/providers/ProgramBuilderProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface SharedProgramProviderProps {
  children: React.ReactElement;
  assignedProgramId: string;
}

const TraineeProgramProvider = ({ children, assignedProgramId: programId }: SharedProgramProviderProps) => {
  const { programStatus, programDetails, selectedProgram } = useAppSelector((state) => state.traineeProgram);
  const { currentWorkout } = useAppSelector((state) => state.traineeActivities.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(exercisesActions.fetchExercises());
    void dispatch(workoutTemplatesActions.fetchWorkoutTemplates());
  }, [dispatch]);

  const duplicateWorkoutInProgram: IProgramBuilderContext["duplicateWorkoutInProgram"] = useCallback(
    async ({ workout }) => {
      await dispatch(
        traineeProgramActions.duplicateWorkoutInProgram({
          workout,
          programId,
        }),
      );
    },
    [dispatch, programId],
  );

  const fetchProgramWithDetails: IProgramBuilderContext["fetchProgramWithDetails"] = useCallback(async () => {
    await dispatch(traineeProgramActions.fetchProgramWithDetails({ id: programId }));
  }, [dispatch, programId]);

  const addNewWorkoutToProgram: IProgramBuilderContext["addNewWorkoutToProgram"] = useCallback(
    async ({ workoutId, workoutTemplates }) => {
      await dispatch(
        traineeProgramActions.addNewWorkoutToProgram({
          programId,
          workoutId,
          workoutTemplates,
        }),
      );
    },
    [dispatch, programId],
  );

  const removeWorkoutFromProgram: IProgramBuilderContext["removeWorkoutFromProgram"] = useCallback(
    async ({ workoutId }) => {
      await dispatch(
        traineeActivitiesActions.deleteWorkoutFromDay({
          workoutId,
        }),
      );
    },
    [dispatch],
  );

  const replaceWorkoutInProgram: IProgramBuilderContext["replaceWorkoutInProgram"] = useCallback(
    ({ workoutTemplate }) => {
      if (!currentWorkout) {
        throw new Error("Current workout is required");
      }

      const updatedWorkout = replaceExercisesInWorkoutFromTemplate(currentWorkout, workoutTemplate);

      dispatch(
        traineeActivitiesActions.updateClientCurrentWorkout({
          ...updatedWorkout,
          name: workoutTemplate.name || currentWorkout.name,
        }),
      );
    },
    [dispatch],
  );

  const updateWorkoutInProgram: IProgramBuilderContext["updateWorkoutInProgram"] = useCallback(
    ({ workout }) => {
      dispatch(traineeActivitiesActions.updateClientCurrentWorkout(workout));
    },
    [dispatch],
  );

  const touched = currentWorkout?.exercises.some((exercise) => exercise.status);

  return (
    <ProgramBuilderProvider
      programId={programId}
      programStatus={programStatus}
      selectedProgram={selectedProgram}
      programDetails={programDetails}
      duplicateWorkoutInProgram={duplicateWorkoutInProgram}
      fetchProgramWithDetails={fetchProgramWithDetails}
      addNewWorkoutToProgram={addNewWorkoutToProgram}
      removeWorkoutFromProgram={removeWorkoutFromProgram}
      replaceWorkoutInProgram={replaceWorkoutInProgram}
      updateWorkoutInProgram={updateWorkoutInProgram}
      touched={touched}
    >
      {children}
    </ProgramBuilderProvider>
  );
};

export default TraineeProgramProvider;
