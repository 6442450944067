import React, { useEffect, useMemo, type FunctionComponent } from "react";
import { ArrowLeftOutlined, DownOutlined, EditOutlined, ExportOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Avatar, Button, Card, Descriptions, Dropdown, List, Menu, Space, Spin } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { productClientActions, RequestStatus } from "@fitness-app/app-store";
import {
  PaymentTypeOption,
  ProductDurationType,
  type OrderPaymentType,
} from "@fitness-app/data-models/entities/Product";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";
import { getUserInitials } from "@fitness-app/utils";

import ModalForm from "~/components/ModalForm/ModalForm";
import ClientAutomationLogs from "~/modules/Products/ProductClientDetails/ClientAutomationLogs/ClientAutomationLogs";
import ProductClientActionsHistory from "~/modules/Products/ProductClientDetails/ProductClientActionsHistory/ProductClientActionsHistory";
import {
  ProductClientArchive,
  useProductClientArchive,
} from "~/modules/Products/ProductClientDetails/ProductClientArchive/ProductClientArchive";
import {
  ProductClientEmailChange,
  useProductClientEmailChange,
} from "~/modules/Products/ProductClientDetails/ProductClientEmailChange/ProductClientEmailChange";
import ProductClientInvoiceData from "~/modules/Products/ProductClientDetails/ProductClientInvoiceData/ProductClientInvoiceData";
import ProductClientSettings from "~/modules/Products/ProductClientDetails/ProductClientSettings/ProductClientSettings";
import ClientStatusBadge from "~/modules/Trainee/components/TraineeStatusBadge/TraineeStatusBadge";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
  ownerId?: string;
  readonly: boolean;
}

type Props = OwnProps;

const allowedPaymentTypes: OrderPaymentType[] = [PaymentTypeOption.OneTime, PaymentTypeOption.Recurring];

const ProductClientDetails: FunctionComponent<Props> = ({ productId, ownerId, readonly }) => {
  const { t } = useTranslation(["client", "products", "common"]);
  const { clientEmail } = useParams<{ clientEmail: string }>();
  const { showArchiveModal, selectedClient, setClient, handleSubmit, processing } = useProductClientArchive();
  const {
    handleEmailChangeSubmit,
    showChangeEmailModal,
    setClientToEmailChange,
    selectedClientToChangeEmail,
    processingChangingEmail,
  } = useProductClientEmailChange();
  const { data, status } = useAppSelector((store) => store.productClient);
  const { data: productAutomation } = useAppSelector((store) => store.productAutomation);
  const navigate = useNavigate();

  const lastOrder = useAppSelector((store) => store.productOrders.orders)[0];

  const dispatch = useAppDispatch();

  const stripePromise = useMemo(() => {
    return loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
      locale: "pl",
    });
  }, []);

  useEffect(() => {
    if (productId && clientEmail) {
      const isEmail = clientEmail.includes("@");
      dispatch(
        productClientActions.subscribeToProductClient(
          productId,
          isEmail ? clientEmail : null,
          isEmail ? undefined : clientEmail,
        ),
      );
    }

    return () => {
      dispatch(productClientActions.unsubscribeFromProductClient());
    };
  }, [productId, dispatch, clientEmail]);

  const clientName = `${data?.firstName} ${data?.lastName}`.trim();

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={() => setClientToEmailChange(data)}>
        {t("ownerOptions.changeEmail")}
      </Menu.Item>
      <Menu.Item
        key="2"
        danger
        icon={<UserDeleteOutlined />}
        onClick={() => setClient(data)}
        disabled={Boolean(data?.archivedInfo)}
      >
        {t("ownerOptions.refund")}
      </Menu.Item>
    </Menu>
  );

  const archived = data?.status === "archived";
  const traineeId = data?.traineeId;

  return (
    <div>
      <Card
        loading={status === RequestStatus.SUBSCRIBING}
        title={
          <Button type="dashed" onClick={() => navigate(`/products/${productId}/clients`)}>
            <ArrowLeftOutlined />
          </Button>
        }
        extra={
          <Dropdown overlay={menu} placement="bottomRight" disabled={readonly || archived}>
            <Button type="primary">
              {t("common:options")} <DownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          {data && (
            <Descriptions bordered labelStyle={{ width: 250 }}>
              <Descriptions.Item label={t("list.columns.client")} span={3}>
                <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={<Avatar src={null}>{getUserInitials(clientName)}</Avatar>}
                  title={clientName}
                  description={<a href={`mailto:${data.email}`}>{data.email}</a>}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("list.columns.phoneNumber")} span={3}>
                {data.phoneNumber || "-"}
              </Descriptions.Item>

              <Descriptions.Item label={t("list.columns.date")} span={3}>
                {dayjs(data.createdAt).format("DD.MM.YYYY")}
              </Descriptions.Item>

              {data.productDuration && (
                <Descriptions.Item label={t("products:productDetails.productDurationType")} span={3}>
                  {data.productDuration.type === ProductDurationType.LastsFor
                    ? `${t(`products:recurring.${data.productDuration.unit}`, {
                        count: data.productDuration.count,
                      })} - ${dayjs.unix(data.productDuration.startAt).format("DD.MM.YYYY")} - ${dayjs
                        .unix(data.productDuration.endAt)
                        .format("DD.MM.YYYY")}`
                    : data.productDuration.type === ProductDurationType.UntilDate
                    ? `${dayjs.unix(data.productDuration.startAt).format("DD.MM.YYYY")} - ${dayjs
                        .unix(data.productDuration.endAt)
                        .format("DD.MM.YYYY")}`
                    : t(`products:productDurationType.${data.productDuration.type}`)}
                </Descriptions.Item>
              )}

              {data.trainee && traineeId && (
                <Descriptions.Item label={t("products:list.columns.traineeAccount")}>
                  <ClientStatusBadge status={data.trainee.status || TraineeStatus.INACTIVE} />
                  <Space>
                    <Button type="link" onClick={() => navigate(`/trainee/details/${traineeId}`)}>
                      Panel klienta
                    </Button>
                    <Button
                      style={{ marginLeft: -12 }}
                      icon={<ExportOutlined />}
                      title="Otwórz w nowy oknie"
                      href={`${window.location.origin}/trainee/details/${traineeId}`}
                      target="_blank"
                      type="link"
                    />
                  </Space>
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
        </Space>
        <div>
          <div className="px-0 py-4">
            {status === RequestStatus.SUBSCRIBING ? (
              <Spin size="small" />
            ) : (
              <Elements stripe={stripePromise}>
                <ProductClientSettings productClient={data} ownerView readonly={readonly || archived} />
              </Elements>
            )}
          </div>
          {data && allowedPaymentTypes.includes(data.paymentType) && data.invoiceRequested !== false ? (
            <div className="px-0 py-4">
              <ProductClientInvoiceData productClient={data} ownerView />
            </div>
          ) : null}
          {data && (
            <div className="px-0 py-4">
              <ProductClientActionsHistory ownerView productClient={data} />
            </div>
          )}
          {data && (
            <div className="px-0 py-4">
              <ClientAutomationLogs productClient={data} ownerId={ownerId} />
            </div>
          )}
        </div>
      </Card>
      <ModalForm
        open={showArchiveModal}
        onCancel={() => setClient(null)}
        title={t("products:clientDetails.archiveModalTitle")}
        okButtonProps={{ danger: true }}
        okText={t("products:clientDetails.archiveConfirm")}
        width={950}
        loading={processing}
      >
        {selectedClient ? (
          <ProductClientArchive
            client={selectedClient}
            onSubmit={handleSubmit}
            lastOrder={lastOrder || null}
            productAutomation={productAutomation}
          />
        ) : (
          <div />
        )}
      </ModalForm>
      <ModalForm
        open={showChangeEmailModal}
        onCancel={() => setClientToEmailChange(null)}
        title={t("products:clientDetails.changeEmailModalTitle")}
        okText={t("products:clientDetails.confirm")}
        width={950}
        loading={processingChangingEmail}
      >
        {selectedClientToChangeEmail ? (
          <ProductClientEmailChange
            client={selectedClientToChangeEmail}
            onSubmit={handleEmailChangeSubmit}
            productAutomation={productAutomation}
          />
        ) : (
          <div />
        )}
      </ModalForm>
    </div>
  );
};

export default ProductClientDetails;
