import React, { useEffect, type FunctionComponent } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, Divider, Form, Input, Radio, Select, Switch, Typography } from "antd";
import round from "lodash.round";
import { Trans, useTranslation } from "react-i18next";

import { productAutomationActions } from "@fitness-app/app-store";
import { type ProductInvoiceConfig, type ProductTaxSettings } from "@fitness-app/data-models/entities/Automation";
import { ProductChargeType, ProductType } from "@fitness-app/data-models/entities/Product";
import { DEFAULT_INCOME_FLAT_TAX_RATES, DEFAULT_VAT_RATES } from "@fitness-app/utils/src/taxes/taxRates";

import { useEntityChange } from "~/hooks/useEntityChange";
import i18next from "~/i18nextConfig";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

interface AutomationSettingsForm {
  successUrl: string | null;
  showPromotionCodesField: CouponCodeField;
  invoiceAsOption: boolean;
  disableEmailNotificationAboutPurchase: boolean;
  disableEmailNotificationAboutPurchaseToOwner: boolean;
  invoiceConfig: ProductInvoiceConfig;
  facebookPixelId: string | null;
  googleAnalyticsId: string | null;
  hideCountryField: boolean;
}

enum CouponCodeField {
  Hidden = "hidden",
  ShowForAll = "showForAll",
  ShowOnlyForSubscription = "showOnlyForSubscription",
}

export const flatTaxRates = DEFAULT_INCOME_FLAT_TAX_RATES.map((rate) => ({
  value: rate,
  label: `${round(rate * 100, 1)}%`,
}));

export const VAT_RATES = [
  {
    value: DEFAULT_VAT_RATES[0],
    label: i18next.t("common:vatRate.zw"),
  },
  {
    value: DEFAULT_VAT_RATES[1],
    label: i18next.t("common:vatRate.np"),
  },
  ...DEFAULT_VAT_RATES.slice(2).map((rate) => ({
    value: rate,
    label: `${rate}%`,
  })),
];

const ProductSettings: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation(["products", "common"]);
  const [formController] = Form.useForm<AutomationSettingsForm>();
  const dispatch = useAppDispatch();
  const productDetails = useAppSelector((store) => store.product.details);
  const productAutomation = useAppSelector((store) => store.productAutomation.data);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const invoiceAsOption = Form.useWatch(["invoiceAsOption"], formController);
  const vatRate = Form.useWatch(
    ["invoiceConfig", "taxSettings", "vatRate"],
    formController,
  ) as ProductTaxSettings["vatRate"];

  const onSubmit = (data: AutomationSettingsForm) => {
    onStart();
    if (data.invoiceConfig) {
      data.invoiceConfig = {
        flatTaxRate: data.invoiceConfig.flatTaxRate || null,
        itemName: data.invoiceConfig.itemName || null,
      };
    }

    const { showPromotionCodesField, ...rest } = data;

    void dispatch(
      productAutomationActions.updateProductAutomationSettings({
        payload: {
          productId,
          settings: {
            ...rest,
            showPromotionCodesField: showPromotionCodesField === CouponCodeField.ShowForAll,
            showPromotionCodesFieldOnlyForSubscription:
              showPromotionCodesField === CouponCodeField.ShowOnlyForSubscription,
          },
        },
        onSuccess,
        onFailure,
      }),
    );
  };

  useEffect(() => {
    if (productAutomation?.settings) {
      const showPromotionCodesField = productAutomation.settings?.showPromotionCodesFieldOnlyForSubscription
        ? CouponCodeField.ShowOnlyForSubscription
        : productAutomation.settings?.showPromotionCodesField
        ? CouponCodeField.ShowForAll
        : CouponCodeField.Hidden;
      formController?.setFieldsValue({
        ...productAutomation.settings,
        showPromotionCodesField,
      });
    }
  }, [productAutomation]);

  if (!productDetails) {
    return null;
  }

  const hiddenField = [ProductType.ONLY_CONTENT, ProductType.ADDON].includes(productDetails.type);

  return (
    <Card className="min-h-[500px]">
      <div className="mx-auto my-10 max-w-[550px]">
        <Form<AutomationSettingsForm>
          name="active-campaign-form"
          layout="vertical"
          form={formController}
          onFinish={onSubmit}
          validateTrigger={["onSubmit"]}
          initialValues={{
            successUrl: "",
            showPromotionCodesField: CouponCodeField.Hidden,
            allowDowngradeSubscriptionPlan: false,
            invoiceAsOption: false,
            disableEmailNotificationAboutPurchase: false,
            allowUpgradeSubscriptionPlan: false,
            issueReceiptWhenInvoiceIsOptIn: false,
            allowDowngradeSubscriptionPlanDesc: false,
            disableEmailNotificationAboutPurchaseToOwner: false,
            googleAnalyticsId: null,
            facebookPixelId: null,
            invoiceConfig: {
              flatTaxRate: null,
              itemName: null,
            },
            cart: {
              features: [],
              preSaleUrl: "",
              customButtonLabel: "",
              hidePriceWhenPreSaleLinkExists: false,
            },
          }}
        >
          <Form.Item label={<Typography.Title level={5}>{t("productAutomation.afterPurchase")}</Typography.Title>}>
            <Form.Item label={t("productAutomation.cartFeatures")} hidden={hiddenField}>
              <Form.List name={["cart", "features"]} initialValue={[""]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div
                        key={field.key}
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "baseline",
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name]}
                          key={field.key}
                          rules={[
                            {
                              required: true,
                              message: t<string>("common:validationErrors.fieldIsRequired"),
                            },
                          ]}
                          style={{ width: "90%" }}
                        >
                          <Input placeholder={t<string>("productAutomation.cartFeaturesPlaceholder")} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginLeft: 12 }} />
                      </div>
                    ))}
                    {fields.length < 5 && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          style={{ marginTop: 10 }}
                        >
                          {t("common:button.add")}
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Form.Item>

            <Form.Item
              hidden={hiddenField}
              name={["cart", "preSaleUrl"]}
              label={t("productAutomation.preSaleUrl")}
              rules={[
                {
                  type: "url",
                  message: t<string>("common:validationErrors.wrongUrl"),
                },
              ]}
            >
              <Input placeholder={t<string>("productAutomation.preSaleUrlPlaceholder")} />
            </Form.Item>

            <Form.Item
              hidden={hiddenField}
              name={["cart", "hidePriceWhenPreSaleLinkExists"]}
              label={t("productAutomation.hidePriceWhenPreSaleLinkExists")}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              hidden={hiddenField}
              name={["cart", "customButtonLabel"]}
              label={t("productAutomation.customButtonLabel")}
            >
              <Input placeholder={t<string>("productAutomation.customButtonLabelPlaceholder")} />
            </Form.Item>

            <Form.Item
              name="successUrl"
              label={t(
                productDetails?.chargeType === ProductChargeType.FREE
                  ? "productAutomation.successUrlFree"
                  : "productAutomation.successUrl",
              )}
              tooltip={t("productAutomation.successUrlTooltip")}
              rules={[
                {
                  type: "url",
                  message: t<string>("common:validationErrors.wrongUrl"),
                },
              ]}
            >
              <Input autoFocus placeholder={t<string>("productAutomation.successUrlPlaceholder")} />
            </Form.Item>

            <Form.Item
              name="disableEmailNotificationAboutPurchase"
              valuePropName="checked"
              label={t("productAutomation.emailNotifications")}
            >
              <Checkbox>
                <Trans t={t} i18nKey="productAutomation.disableEmailNotificationAboutPurchase" />
              </Checkbox>
            </Form.Item>

            {productAutomation?.settings?.disableEmailNotificationAboutPurchase && (
              <Alert
                type="warning"
                message={t("productAutomation.disableEmailNotificationAboutPurchaseInfo")}
                style={{ margin: "-10px 0 20px" }}
              />
            )}

            <Form.Item name="disableEmailNotificationAboutPurchaseToOwner" valuePropName="checked">
              <Checkbox>
                <Trans t={t} i18nKey="productAutomation.disableEmailNotificationAboutPurchaseToOwner" />
              </Checkbox>
            </Form.Item>
          </Form.Item>
          {productDetails?.chargeType !== ProductChargeType.FREE && (
            <>
              <Divider />
              <Form.Item
                name="showPromotionCodesField"
                label={
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {t("productAutomation.promotionCodes")}
                  </Typography.Title>
                }
                tooltip={t("productAutomation.showPromotionCodesTooltip")}
              >
                <Radio.Group
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    marginTop: "0.5rem",
                  }}
                >
                  <Radio value={CouponCodeField.Hidden}>{t("productAutomation.hidePromotionCodesField")}</Radio>
                  <Radio value={CouponCodeField.ShowForAll}>{t("productAutomation.showPromotionCodesField")}</Radio>
                  <Radio value={CouponCodeField.ShowOnlyForSubscription}>
                    {t("productAutomation.showPromotionCodesOnlyForSubscriptionField")}
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Divider />

              <Form.Item
                name="hideCountryField"
                valuePropName="checked"
                label={<Typography.Title level={5}>{t("productAutomation.countries")}</Typography.Title>}
              >
                <Checkbox>{t("productAutomation.hideCountryField")}</Checkbox>
              </Form.Item>

              <Alert message={t("productAutomation.hideCountryInfo")} style={{ margin: "-10px 0 20px" }} />

              <Divider />

              <Form.Item
                name="invoiceAsOption"
                valuePropName="checked"
                label={<Typography.Title level={5}>{t("productAutomation.invoices")}</Typography.Title>}
              >
                <Checkbox>{t("productAutomation.invoiceAsOptionField")}</Checkbox>
              </Form.Item>

              <Alert message={t("productAutomation.invoiceAsOptionInfo")} style={{ margin: "-10px 0 20px" }} />

              {invoiceAsOption && (
                <Form.Item name="issueReceiptWhenInvoiceIsOptIn" valuePropName="checked">
                  <Checkbox>{t("productAutomation.issueReceiptWhenInvoiceIsOptIn")}</Checkbox>
                </Form.Item>
              )}

              <Form.Item name={["invoiceConfig", "itemName"]} label={t("productAutomation.invoiceConfigItemName")}>
                <Input placeholder={t<string>("productAutomation.invoiceConfigItemNamePlaceholder")} />
              </Form.Item>

              <Form.Item name={["invoiceConfig", "flatTaxRate"]} label={t("productAutomation.flatTaxRate")}>
                <Select options={flatTaxRates} allowClear placeholder={t("productAutomation.flatTaxRatePlaceholder")} />
              </Form.Item>

              <Form.Item
                name={["invoiceConfig", "taxSettings", "vatRate"]}
                label={t("productAutomation.productVatRate")}
              >
                <Select options={VAT_RATES} allowClear placeholder={t("productAutomation.productVatRatePlaceholder")} />
              </Form.Item>

              {vatRate && typeof vatRate === "string" ? (
                <Form.Item
                  name={["invoiceConfig", "taxSettings", "legalVatExemption"]}
                  label={t("productAutomation.legalVatExemption")}
                  rules={[
                    {
                      required: vatRate === "zw",
                      message: t<string>("common:validationErrors.fieldIsRequired"),
                    },
                    {
                      max: 50,
                      message: t<string>("common:validationErrors.maxLength", {
                        number: 50,
                      }),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : null}

              {productDetails?.chargeType !== ProductChargeType.FREE && (
                <>
                  <Divider />
                  <Form.Item
                    label={<Typography.Title level={5}>{t("productAutomation.analyticsInCheckout")}</Typography.Title>}
                  >
                    <Form.Item name="googleAnalyticsId" label={t("productAutomation.googleAnalyticsId")}>
                      <Input placeholder={t<string>("productAutomation.googleAnalyticsIdPlaceholder")} />
                    </Form.Item>

                    <Form.Item name="facebookPixelId" label={t("productAutomation.facebookPixelId")}>
                      <Input placeholder={t<string>("productAutomation.facebookPixelIdPlaceholder")} />
                    </Form.Item>
                  </Form.Item>
                </>
              )}

              <Divider />

              <Form.Item label={<Typography.Title level={5}>{t("productAutomation.subscription")}</Typography.Title>}>
                <Form.Item name="allowUpgradeSubscriptionPlan" valuePropName="checked" noStyle>
                  <Checkbox>{t("productAutomation.allowUpgradeSubscriptionPlan")}</Checkbox>
                </Form.Item>
              </Form.Item>

              <Alert
                message={t("productAutomation.allowUpgradeSubscriptionPlanDesc")}
                style={{ margin: "-10px 0 20px" }}
              />

              <Form.Item name="allowDowngradeSubscriptionPlan" valuePropName="checked" noStyle>
                <Checkbox>{t("productAutomation.allowDowngradeSubscriptionPlan")}</Checkbox>
              </Form.Item>
            </>
          )}

          <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
            <Button loading={!!loading} type="primary" htmlType="submit" block className="mt-4">
              {t("common:button.save")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default ProductSettings;
