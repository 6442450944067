import React, { useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Form, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useTranslation } from "react-i18next";

import { RequestStatus, traineeProgramActions } from "@fitness-app/app-store";

import ProgramWeekSchedule from "~/modules/Automation/ScheduleProgramStepper/steps/ProgramWeekSchedule";
import { type ProgramScheduleFormModel } from "~/modules/Automation/ScheduleProgramStepper/steps/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const TraineeProgramSchedule = () => {
  const { selectedProgram, programDetails, programStatus } = useAppSelector((store) => store.traineeProgram);
  const [scheduleProgramControllerForm] = Form.useForm<ProgramScheduleFormModel>();
  const { t } = useTranslation("trainees");
  const [updateScheduleFromWeek, setUpdateScheduleWeek] = useState(
    selectedProgram?.workoutsSchedule?.allowChangeScheduleFromWeek ?? null,
  );
  const dispatch = useAppDispatch();

  if (!selectedProgram || !programDetails) {
    return null;
  }

  const onSubmit = (model: ProgramScheduleFormModel) => {
    void dispatch(
      traineeProgramActions.setProgramSchedule({
        updateScheduleFromWeek,
        programSchedule: model.weeks,
      }),
    );
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    const currentAllowedWeek = selectedProgram.workoutsSchedule?.allowChangeScheduleFromWeek?.split("-");
    const today = dayjs();
    const yearOfAllowedWeek = currentAllowedWeek ? Number(currentAllowedWeek[0]) : today.get("year");
    const firstAllowedWeek = currentAllowedWeek ? Number(currentAllowedWeek[1]) : today.isoWeek();

    const firstAllowedDay = dayjs().isoWeekday(1).week(firstAllowedWeek).year(yearOfAllowedWeek).subtract(1, "week");
    return current?.isBefore(firstAllowedDay);
  };

  if (programStatus === RequestStatus.FETCHING) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card
      bordered={false}
      className="!shadow-none"
      extra={
        <Space>
          <Typography.Text>{t("changesFrom")}</Typography.Text>
          <DatePicker
            disabledDate={disabledDate}
            picker="week"
            onChange={(_, week) => setUpdateScheduleWeek(week)}
            format="YYYY-W"
          />

          <Button
            type="primary"
            size="small"
            icon={<SaveOutlined />}
            onClick={() => scheduleProgramControllerForm.submit()}
          >
            Aktualizuj
          </Button>
        </Space>
      }
    >
      <ProgramWeekSchedule
        formController={scheduleProgramControllerForm}
        schedule={selectedProgram.workoutsSchedule?.schedule || null}
        programWorkouts={programDetails}
        onSubmit={onSubmit}
      />
    </Card>
  );
};

export default TraineeProgramSchedule;
