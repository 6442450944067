import { type AppAccessType } from "../../../entities/Automation";
import { type AssignedTrainer, type TraineeStatus } from "../../../entities/Trainee";
import { type GenericBusinessEventDto } from "../../BusinessEventDto";
import { type GenericBusinessEvent } from "../BusinessEventEnvelope";

export enum TraineeEventType {
  TraineeActivatedAccount = "fitness-app.trainee.trainee-activated-account",
  TraineeAccountDeactivated = "fitness-app.trainee.trainee-account-deactivated",
  TraineeAddedToApp = "fitness-app.trainee.trainee-added-to-app",
  TraineeGotAccessToWorkoutProgram = "fitness-app.trainee.trainee-got-access-to-workout-program",
  TraineeStartedWorkoutProgram = "fitness-app.trainee.trainee-started-workout-program",
  TraineeEndedWorkoutProgram = "fitness-app.trainee.trainee-ended-workout-program",
  TraineeStartedNutritionPlan = "fitness-app.trainee.trainee-started-nutrition-plan",
  TraineeGotAccessToNutritionPlan = "fitness-app.trainee.trainee-got-access-to-nutrition-plan",
  TraineeEndedNutritionPlan = "fitness-app.trainee.trainee-ended-nutrition-plan",
  TraineeAssignedToTrainer = "fitness-app.trainee.trainee-assigned-to-trainer",
  TraineeRemovedFromTrainer = "fitness-app.trainee.trainee-removed-from-trainer",
  TraineeEndingProgramIn1Day = "fitness-app.trainee.trainee-ending-program-in-1-day",
  TraineeEndingProgramIn1Week = "fitness-app.trainee.trainee-ending-program-in-1-week",
  TraineeWorkoutPlanned = "fitness-app.trainee.trainee-workout-planned",
}

export interface Trainee<UUID = string> {
  email: string;
  userId: UUID | null;
  traineeId: string;
  firstName: string;
  lastName: string | null;
}

export type Trainer = {
  email: string;
  uid: string;
  id: string;
} | null;

export type AssignedTrainerResource = {
  uid: string;
  assignedTeamTrainer: null | string;
  assignedTeamTrainerUid?: null | string;
};

export interface ProgramInEvent {
  duration: number | null;
  id: string;
  startDate: string;
  endDate: string | null;
  name: string;
}

export interface NutritionInEvent {
  id: string;
  startDate: string;
  endDate: string | null;
  name: string;
}

export interface InfoAboutWorkoutProgram {
  programId: string;
  programName: string;
  endDate: string;
  nextPlan: { name: string; id: string; startDate: string } | null;
}

export type TraineeEndingProgramIn1DayEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeEndingProgramIn1Day,
  {
    trainee: Trainee;
    trainer: AssignedTrainerResource;
    resourceId: string | null;
    data: InfoAboutWorkoutProgram;
    date: number;
  }
>;

export type TraineeEndingProgramIn1WeekEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeEndingProgramIn1Week,
  {
    trainee: Trainee;
    trainer: AssignedTrainerResource;
    resourceId: string | null;
    data: InfoAboutWorkoutProgram;
    date: number;
  }
>;

export type TraineeEndingProgramIn1DayEvent = GenericBusinessEvent<
  TraineeEndingProgramIn1DayEventDto["eventType"],
  TraineeEndingProgramIn1DayEventDto["payload"]
>;

export type TraineeEndingProgramIn1WeekEvent = GenericBusinessEvent<
  TraineeEndingProgramIn1WeekEventDto["eventType"],
  TraineeEndingProgramIn1WeekEventDto["payload"]
>;

export type TraineeAddedToAppEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeAddedToApp,
  {
    trainee: Trainee;
    productId: string;
    productName: string;
    hasAccountBefore: boolean;
    previousProductId?: string | null;
    accessType: AppAccessType;
  }
>;

export type TraineeAddedToAppEvent = GenericBusinessEvent<
  TraineeAddedToAppEventDto["eventType"],
  TraineeAddedToAppEventDto["payload"]
>;

export type TraineeActivatedEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeActivatedAccount,
  {
    trainee: Trainee;
  }
>;

export type TraineeActivatedEvent = GenericBusinessEvent<
  TraineeActivatedEventDto["eventType"],
  TraineeActivatedEventDto["payload"]
>;

export type TraineeAccountDeactivatedEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeAccountDeactivated,
  {
    trainee: Trainee;
    deactivatedViaProductId: string | null;
  }
>;

export type TraineeAccountDeactivatedEvent = GenericBusinessEvent<
  TraineeAccountDeactivatedEventDto["eventType"],
  TraineeAccountDeactivatedEventDto["payload"]
>;

export type TraineeAssignedToTrainerEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeAssignedToTrainer,
  {
    trainee: Trainee;
    traineeStatus: TraineeStatus;
    assignedTrainer: AssignedTrainer;
    previousTrainer: null | AssignedTrainer;
  }
>;

export type TraineeAssignedToTrainerEvent = GenericBusinessEvent<
  TraineeAssignedToTrainerEventDto["eventType"],
  TraineeAssignedToTrainerEventDto["payload"]
>;

export type TraineeRemovedFromTrainerEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeRemovedFromTrainer,
  {
    trainee: Trainee;
    traineeStatus: TraineeStatus;
    previousTrainer: null | AssignedTrainer;
  }
>;

export type TraineeRemovedFromTrainerEvent = GenericBusinessEvent<
  TraineeRemovedFromTrainerEventDto["eventType"],
  TraineeRemovedFromTrainerEventDto["payload"]
>;

export type TraineeGotAccessToWorkoutProgramEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeGotAccessToWorkoutProgram,
  {
    trainee: Trainee;
    trainer: AssignedTrainerResource;
    traineeStatus: TraineeStatus;
    program: ProgramInEvent;
  }
>;

export type TraineeGotAccessToWorkoutProgramEvent = GenericBusinessEvent<
  TraineeGotAccessToWorkoutProgramEventDto["eventType"],
  TraineeGotAccessToWorkoutProgramEventDto["payload"]
>;

export type TraineeGotAccessToNutritionPlanEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeGotAccessToNutritionPlan,
  {
    trainee: Trainee;
    trainer: AssignedTrainerResource;
    traineeStatus: TraineeStatus;
    program: NutritionInEvent;
  }
>;

export type TraineeGotAccessToNutritionPlanEvent = GenericBusinessEvent<
  TraineeGotAccessToNutritionPlanEventDto["eventType"],
  TraineeGotAccessToNutritionPlanEventDto["payload"]
>;

export type TraineeStartedWorkoutProgramEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeStartedWorkoutProgram,
  {
    trainee: Trainee;
    traineeStatus: TraineeStatus;
    trainer: AssignedTrainerResource;
    program: ProgramInEvent;
    previousProgram: null | ProgramInEvent;
  }
>;

export type TraineeStartedNutritionPlanEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeStartedNutritionPlan,
  {
    trainee: Trainee;
    traineeStatus: TraineeStatus;
    trainer: AssignedTrainerResource;
    program: NutritionInEvent;
    previousProgram: null | NutritionInEvent;
  }
>;

export type TraineeStartedNutritionPlanEvent = GenericBusinessEvent<
  TraineeStartedNutritionPlanEventDto["eventType"],
  TraineeStartedNutritionPlanEventDto["payload"]
>;

export type TraineeEndedNutritionPlanEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeEndedNutritionPlan,
  {
    trainee: Trainee;
    traineeStatus: TraineeStatus;
    trainer: AssignedTrainerResource;
    program: NutritionInEvent;
  }
>;

export type TraineeEndedNutritionPlanEvent = GenericBusinessEvent<
  TraineeEndedNutritionPlanEventDto["eventType"],
  TraineeEndedNutritionPlanEventDto["payload"]
>;

export type TraineeStartedWorkoutProgramEvent = GenericBusinessEvent<
  TraineeStartedWorkoutProgramEventDto["eventType"],
  TraineeStartedWorkoutProgramEventDto["payload"]
>;

export type TraineeEndedWorkoutProgramEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeEndedWorkoutProgram,
  {
    trainee: Trainee;
    traineeStatus: TraineeStatus;
    trainer: AssignedTrainerResource;
    program: ProgramInEvent;
  }
>;

export type TraineeEndedWorkoutProgramEvent = GenericBusinessEvent<
  TraineeEndedWorkoutProgramEventDto["eventType"],
  TraineeEndedWorkoutProgramEventDto["payload"]
>;

export type TraineeWorkoutPlannedEventDto = GenericBusinessEventDto<
  TraineeEventType.TraineeWorkoutPlanned,
  {
    traineeId: string;
    programId: string | null;
    eventTimestamp: string;
    eventDate: string;
    workoutsIds: string[];
    numberOfExercises: number;
    workoutReportId: string;
    userId: string | null;
    trainee: Partial<Trainee>;
  }
>;

export type TraineeWorkoutPlannedEvent = GenericBusinessEvent<
  TraineeWorkoutPlannedEventDto["eventType"],
  TraineeWorkoutPlannedEventDto["payload"]
>;

export type TraineeEvents =
  | TraineeActivatedEvent
  | TraineeAssignedToTrainerEvent
  | TraineeGotAccessToWorkoutProgramEvent
  | TraineeStartedWorkoutProgramEvent
  | TraineeEndedWorkoutProgramEvent
  | TraineeAddedToAppEvent
  | TraineeRemovedFromTrainerEvent
  | TraineeAccountDeactivatedEvent
  | TraineeEndingProgramIn1DayEvent
  | TraineeEndingProgramIn1WeekEvent
  | TraineeEndedNutritionPlanEvent
  | TraineeStartedNutritionPlanEvent
  | TraineeGotAccessToNutritionPlanEvent
  | TraineeWorkoutPlannedEvent;

export type TraineeEventsDto =
  | TraineeActivatedEventDto
  | TraineeAssignedToTrainerEventDto
  | TraineeGotAccessToWorkoutProgramEventDto
  | TraineeStartedWorkoutProgramEventDto
  | TraineeEndedWorkoutProgramEventDto
  | TraineeAddedToAppEventDto
  | TraineeRemovedFromTrainerEventDto
  | TraineeAccountDeactivatedEventDto
  | TraineeEndingProgramIn1DayEventDto
  | TraineeEndingProgramIn1WeekEventDto
  | TraineeStartedNutritionPlanEventDto
  | TraineeEndedNutritionPlanEventDto
  | TraineeGotAccessToNutritionPlanEventDto
  | TraineeWorkoutPlannedEventDto;

export type TraineeEventsPayload = TraineeEvents["payload"];
