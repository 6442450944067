import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import uniqBy from "lodash.uniqby";

import { type NutritionPlanDay } from "@fitness-app/data-models/entities/MealsPlan";

import { RequestStatus } from "../../../enums/requestStatus";
import {
  addMealsPlan,
  deleteMealsPlan,
  deleteProductFromMealsPlan,
  fetchMealsPlan,
  fetchMealsPlanWithDetails,
  updatedMealsPlanDays,
  updateProductInMealsPlan,
} from "./actions";
import { MEALS_PLANS_REDUCER_NAME, type MealsPlansReducer } from "./types";

const initialState: MealsPlansReducer = {
  list: [],
  listStatus: null,
  listPage: 1,
  listPerPage: 100,
  listTotalPages: 0,
  selectedMealsPlan: null,
  selectedMealsPlanStatus: null,
  selectedMealsPlanDetails: null,
  creators: [],
  searchTerm: null,
  filters: {
    currentAssignedTrainerFilter: null,
    currentFilteredTags: [],
  },
};

const reducerSlice = createSlice({
  initialState,
  name: MEALS_PLANS_REDUCER_NAME,
  reducers: {
    updateDaysInMealsPlan: (state, { payload }: PayloadAction<NutritionPlanDay[]>) => {
      if (state.selectedMealsPlanDetails?.[0]?.weeks[0]?.days) {
        state.selectedMealsPlanDetails[0].weeks[0].days = payload;
        state.selectedMealsPlanDetails[0].updatedAt = new Date().toISOString();
      }
    },
    clearSelectedMealsPlan: (state) => {
      state.selectedMealsPlan = null;
      state.selectedMealsPlanDetails = null;
      state.selectedMealsPlanStatus = null;
    },
    setSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMealsPlan.pending, (state, { meta }) => {
      state.listStatus = RequestStatus.FETCHING;

      if (Array.isArray(meta.arg?.tags) || meta.arg?.tags === null) {
        state.filters.currentFilteredTags = meta.arg?.tags || [];
      }

      if (meta.arg?.assignedTrainerId || meta.arg?.assignedTrainerId === null) {
        state.filters.currentAssignedTrainerFilter = meta.arg.assignedTrainerId;
      }
    });
    builder.addCase(fetchMealsPlan.fulfilled, (state, { payload }) => {
      state.listStatus = RequestStatus.SUCCESS;
      state.listPage = payload.page;
      state.list = payload.data;
      state.listTotalPages = payload.totalPages;
      state.creators = uniqBy(
        payload.data.reduce<{ label: string; value: string }[]>((prev, current) => {
          if (current.creator) {
            prev.push({
              label: `${current.creator.firstName} ${current.creator.lastName}`,
              value: current.creator.id,
            });
          }
          return prev;
        }, []),
        "value",
      );
    });
    builder.addCase(fetchMealsPlan.rejected, (state) => {
      state.listStatus = RequestStatus.FAILED;
    });
    builder.addCase(addMealsPlan.fulfilled, (state, { payload }) => {
      if (payload?.plan) {
        state.list = [payload?.plan, ...state.list];
      }
    });
    builder.addCase(fetchMealsPlanWithDetails.pending, (state) => {
      state.selectedMealsPlanStatus = RequestStatus.FETCHING;
    });
    builder.addCase(fetchMealsPlanWithDetails.fulfilled, (state, { payload }) => {
      state.selectedMealsPlanStatus = RequestStatus.SUCCESS;
      state.selectedMealsPlan = payload.plan;
      state.selectedMealsPlanDetails = payload.details;
    });
    builder.addCase(fetchMealsPlanWithDetails.rejected, (state) => {
      state.selectedMealsPlanStatus = RequestStatus.FAILED;
    });
    builder.addCase(updatedMealsPlanDays.pending, (state, { meta }) => {
      if (state.selectedMealsPlanDetails) {
        state.selectedMealsPlanDetails = state.selectedMealsPlanDetails.map((details) =>
          details.id === meta.arg.id
            ? {
                ...details,
                updatedAt: new Date().toISOString(),
                weeks: [
                  {
                    weekNumber: 1,
                    days: meta.arg.days,
                  },
                ],
              }
            : details,
        );
      }
    });
    builder.addCase(deleteProductFromMealsPlan.fulfilled, (state, { payload }) => {
      if (state.selectedMealsPlanDetails) {
        state.selectedMealsPlanDetails = state.selectedMealsPlanDetails.map((details) =>
          details.id === payload.id ? payload : details,
        );
      }
    });
    builder.addCase(updateProductInMealsPlan.fulfilled, (state, { payload }) => {
      if (state.selectedMealsPlanDetails) {
        state.selectedMealsPlanDetails = state.selectedMealsPlanDetails.map((details) =>
          details.id === payload.id ? payload : details,
        );
      }
    });
    builder.addCase(deleteMealsPlan.fulfilled, (state, { meta }) => {
      state.list = state.list.filter((plan) => plan.id !== meta.arg.planId);
    });
  },
});

export const { updateDaysInMealsPlan, clearSelectedMealsPlan, setSearchTerm } = reducerSlice.actions;
export default reducerSlice.reducer;
