import React, { type FunctionComponent } from "react";
import { Form, Input, InputNumber } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { type ProgramAutomation } from "@fitness-app/data-models/entities/ProgramAutomation";

import { createAutomationWeekTemplate } from "../../helpers";
import { type AutomationTemplateFormModel } from "./types";

interface OwnProps {
  formController?: FormInstance<AutomationTemplateFormModel>;
  onSubmit: (formData: ProgramAutomation) => void;
  model?: Partial<AutomationTemplateFormModel>;
}

type Props = OwnProps;

const AutomationTemplateForm: FunctionComponent<Props> = ({ formController, onSubmit }) => {
  const { t } = useTranslation(["automation", "common"]);

  const handleSubmit = ({ durationInWeeks, ...formData }: AutomationTemplateFormModel) => {
    onSubmit({
      ...formData,
      id: uuid(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      initialSurveyId: null,
      additionalSurveyId: null,
      scheduledWorkoutsRoutine: [],
      welcomeMessages: [],
      hasActiveWelcomeMessages: false,
      schedule: Object.fromEntries(
        Array.from({ length: durationInWeeks }).map((_, index) => {
          return [index + 1, createAutomationWeekTemplate()];
        }),
      ),
    });
  };

  return (
    <Form
      name="automation-template-form"
      autoComplete="off"
      form={formController}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      validateTrigger={["onSubmit"]}
      onFinish={handleSubmit}
      initialValues={{
        name: "",
        comment: "",
        durationInWeeks: 1,
      }}
    >
      <Form.Item
        name="name"
        label={t("programAutomation.form.name")}
        rules={[
          {
            whitespace: true,
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="durationInWeeks" label={t("programAutomation.form.durationInWeeks")}>
        <InputNumber name="durationInWeeks" min={1} max={52} addonAfter={t("programAutomation.form.weeks")} />
      </Form.Item>

      <Form.Item name="comment" label={t("programAutomation.form.comment")}>
        <Input.TextArea rows={3} />
      </Form.Item>
    </Form>
  );
};

export default AutomationTemplateForm;
