import React, { useEffect, useState } from "react";
import { AutoComplete, DatePicker, Form, Input, InputNumber, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";

import { programBuilderActions } from "@fitness-app/app-store";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";
import {
  type TrainingProgramDetails,
  type TrainingProgramWithCreator,
} from "@fitness-app/data-models/entities/TrainingProgram";
import { getName } from "@fitness-app/data-models/utils/getAuthorName";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { type TraineeSelectProgramModel } from "./types";

interface TraineeSelectProgramProps {
  formController?: FormInstance<TraineeSelectProgramModel>;
  onSubmit: (
    formData: TraineeSelectProgramModel,
    fetchedPlan: null | {
      plan: TrainingProgramWithCreator;
      details: TrainingProgramDetails;
    },
  ) => void;
  model?: Partial<TraineeSelectProgramModel> | null;
  isUpdatingPlan?: boolean;
  traineeStatus?: TraineeStatus;
}

const createProgramOptions = (collection: TrainingProgramWithCreator[], userId?: string) => {
  if (!userId) {
    return collection.map((program) => ({
      label: program.name,
      value: program.name,
      key: program.id,
    }));
  }

  return orderBy(
    collection.map((program) => ({
      label: `${program.name} ${getName(program.creator, undefined, true)}`,
      value: program.name,
      key: program.id,
      createdBy: program.createdBy,
      creator: getName(program.creator, undefined, true),
    })),
    (item) => item.createdBy === userId,
    "desc",
  );
};

const PROGRAM_LENGTH_TYPE = {
  WEEKS: "weeks",
  MONTHS: "months",
  SELECTED_DATE: "selectedDate",
} as const;

const programLengthOptions = [
  {
    value: PROGRAM_LENGTH_TYPE.WEEKS,
    label: "Tygodnie",
  },
  {
    value: PROGRAM_LENGTH_TYPE.MONTHS,
    label: "Miesiące",
  },
  {
    value: PROGRAM_LENGTH_TYPE.SELECTED_DATE,
    label: "Wybrana data",
  },
];

const TraineeSelectProgram = ({
  model,
  onSubmit,
  formController,
  isUpdatingPlan,
  traineeStatus,
}: TraineeSelectProgramProps) => {
  const { t } = useTranslation(["common"]);
  const { list } = useAppSelector((store) => store.programs);
  const [_selectedPlan, selectPlan] = useState<string | null>(null);
  const lengthUnit = Form.useWatch("lengthUnit", formController);
  const dispatch = useAppDispatch();
  const [fetchedPlan, setFetchedPlan] = useState<{
    plan: TrainingProgramWithCreator;
    details: TrainingProgramDetails;
  } | null>(null);
  const { userId } = useUserRole();

  const onSelect = async (value: string, option: { value: string; label: string; key?: string }) => {
    if (option?.key) {
      selectPlan(option.value);

      const plan = await dispatch(programBuilderActions.validateProgramWithDetails({ id: option.key })).unwrap();
      setFetchedPlan(plan);
    }
  };

  useEffect(() => {
    if (isUpdatingPlan) {
      formController?.setFieldsValue({ keepOldTrainingWithoutStatus: true });
    }
  }, [isUpdatingPlan]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  return (
    <Form<TraineeSelectProgramModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        lengthUnit: "weeks",
        startDate: dayjs().startOf("day"),
        hasActiveProgram: true,
        duration: 8,
      }}
      onFinish={(model) => onSubmit(model, fetchedPlan)}
    >
      {isUpdatingPlan && (
        <Form.Item
          name="keepOldTrainingWithoutStatus"
          label="Zachowaj niewykonane treningi"
          valuePropName="checked"
          tooltip="Treningi ze poprzedniego planu, które nie posiadają status zostana zachowane w kalendarzu jako niewykonane."
        >
          <Switch />
        </Form.Item>
      )}
      <Form.Item
        name="programId"
        label="Wybierz plan / Dodaj nowy"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <AutoComplete
          onSelect={onSelect}
          onClear={() => {
            selectPlan(null);
            setFetchedPlan(null);
          }}
          autoFocus
          allowClear
          filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          options={createProgramOptions(list, userId)}
        />
      </Form.Item>

      <Form.Item
        name="startDate"
        label="Data rozpoczęcia"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <DatePicker
          placeholder="Wybierze datę"
          format="DD.MM.YYYY"
          showToday={false}
          disabledDate={(current) => current && current < dayjs().startOf("day")}
        />
      </Form.Item>

      <Form.Item
        name="lengthUnit"
        label="Jednostka długości"
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Select className="max-w-[150px]" options={programLengthOptions} />
      </Form.Item>

      {lengthUnit === PROGRAM_LENGTH_TYPE.SELECTED_DATE ? (
        <Form.Item
          name="endDate"
          label="Data zakończenia"
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <DatePicker
            placeholder="Wybierze datę"
            format="DD.MM.YYYY"
            disabledDate={(current) => current && current < dayjs().add(1, "day").startOf("day")}
            showToday={false}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="duration"
          label="Długość trwania"
          rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
        >
          <InputNumber
            className="max-w-[150px]"
            min={1}
            max={52}
            addonAfter={lengthUnit === PROGRAM_LENGTH_TYPE.WEEKS ? "tyg." : "mie."}
          />
        </Form.Item>
      )}

      <Form.Item name="comment" label="Uwagi do planu">
        <Input.TextArea rows={3} />
      </Form.Item>

      {traineeStatus === TraineeStatus.ACTIVE && (
        <Form.Item
          name="hasActiveProgram"
          label="Udostępnij plan klientowi"
          tooltip="Możesz udostępnić plan natychmiast pod dodaniu lub w późniejszym czasie."
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )}
    </Form>
  );
};

export default TraineeSelectProgram;
