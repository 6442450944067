import { last, mapValues, omit } from "lodash";

import { type ExerciseAggregateStats } from "@fitness-app/data-models/entities/ExerciseStats";
import {
  WeightHint,
  type ExerciseInWorkout,
  type ExerciseSet,
} from "@fitness-app/data-models/entities/TrainingProgram";

export const getPropertiesValue = (property: number | [number, number] | null | undefined, index?: number) => {
  if (typeof property === "undefined" || property === null) {
    return null;
  }
  if (Array.isArray(property)) {
    return property[typeof index === "number" ? index : 1];
  }
  return property;
};

interface HintValuesArgs {
  sets: Record<string, ExerciseSet>;
  hintType?: WeightHint;
  records?: ExerciseAggregateStats | null;
  exercise: ExerciseInWorkout;
}

export interface EnhancedWorkoutSet extends ExerciseSet {
  withHint?: WeightHint;
}

const setHintValuesForSets = ({
  sets,
  hintType = WeightHint.DEFAULT,
  records = null,
  exercise,
}: HintValuesArgs): Record<string, EnhancedWorkoutSet | ExerciseSet> => {
  if (hintType === WeightHint.DEFAULT || !records) {
    return sets;
  }
  return mapValues(sets, (set, key) => {
    const setStatusIsDefault = !set.status;
    let updatedSet: EnhancedWorkoutSet = {
      ...set,
    };
    if (setStatusIsDefault) {
      if (hintType === WeightHint.LATEST && records.latestWorkout.set) {
        const setKey = records.latestWorkout.set[key] ? key : last(Object.keys(records.latestWorkout.set));
        return {
          ...omit(records.latestWorkout.set[setKey ?? 1], ["status"]),
          repeats: updatedSet.repeats,
          withHint: WeightHint.LATEST,
        };
      }
      if (
        records.records.maxWeight &&
        (Array.isArray(updatedSet.weight) ||
          typeof updatedSet.weight === "number" ||
          typeof exercise.weight === "number")
      ) {
        const hintValue = hintType === WeightHint.MAX_VALUE ? records.records.maxWeight : records.latestWorkout.weight;

        const value = getPropertiesValue(hintValue);

        if (set.weight !== value && hintType !== WeightHint.LATEST) {
          updatedSet = {
            ...updatedSet,
            weight: value ?? 0,
            withHint: WeightHint.MAX_VALUE,
          };
        }
      }
      if (records.records.maxDuration && updatedSet.duration) {
        const hintValue =
          hintType === WeightHint.MAX_VALUE
            ? records.records.maxDuration.value
            : records.latestWorkout.duration?.value ?? 0;
        const value = getPropertiesValue(hintValue);
        if (set.duration?.value && set.duration.value !== value) {
          updatedSet = {
            ...updatedSet,
            withHint: WeightHint.MAX_VALUE,
            duration: {
              value: value ?? 0,
              format: updatedSet.duration.format,
            },
          };
        }
      }
    }
    return updatedSet;
  });
};

export default setHintValuesForSets;
