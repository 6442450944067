import React, { useEffect } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { mediaLibraryActions } from "@fitness-app/app-store";
import { ExerciseBodyPart, ExerciseType, ExerciseVideoType } from "@fitness-app/data-models/entities/Exercise";

import { useTagOptions } from "~/hooks/useTagOptions";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import {
  bodyPartOptions,
  createMediaLibraryVideoOptions,
  exerciseTypeOptions,
  videoTypeOptions,
  type ExerciseFormModel,
} from "./types";

interface ExerciseFormProps {
  formController?: FormInstance<ExerciseFormModel>;
  onSubmit: (formData: ExerciseFormModel) => void;
  model?: Partial<ExerciseFormModel> | null;
}

const ExerciseForm = ({ model, onSubmit, formController }: ExerciseFormProps) => {
  const { t } = useTranslation(["workouts", "common"]);
  const selectedVideoOption = Form.useWatch("videoType", formController);
  const { list, listStatus } = useAppSelector((store) => store.mediaLibrary);
  const dispatch = useAppDispatch();
  const { options: tagsOptions } = useTagOptions("exercisesTags");

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  useEffect(() => {
    if (!listStatus) {
      dispatch(mediaLibraryActions.subscribeToMediaLibrary());
    }
  }, [listStatus, dispatch]);

  const handleSubmit = (model: ExerciseFormModel) => {
    if (model.videoType === ExerciseVideoType.NONE) {
      model.videoId = null;
      model.videoLink = null;
    }

    if (model.videoType === ExerciseVideoType.LINK || model.videoType === ExerciseVideoType.YOUTUBE) {
      model.videoId = null;
    }

    onSubmit(model);
  };

  const renderVideoField = () => {
    if (
      selectedVideoOption === ExerciseVideoType.LINK ||
      selectedVideoOption === ExerciseVideoType.YOUTUBE ||
      selectedVideoOption === ExerciseVideoType.VIMEO
    ) {
      return (
        <Form.Item
          name="videoLink"
          label={t<string>("exerciseForm.videoLink")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    }
    if (selectedVideoOption === ExerciseVideoType.MEDIA_LIBRARY) {
      return (
        <Form.Item
          name="videoId"
          label={t<string>("exerciseForm.videoUrls")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Select
            showSearch
            options={createMediaLibraryVideoOptions(list)}
            filterOption={(inputValue, option) => option?.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
            placeholder={t("exerciseForm.selectFromList")}
          />
        </Form.Item>
      );
    }
    return null;
  };

  return (
    <Form<ExerciseFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        name: "",
        type: ExerciseType.POWER,
        bodyParts: [ExerciseBodyPart.CHEST],
        videoType: ExerciseVideoType.NONE,
        description: "",
        links: [],
        instruction: [],
        videoLink: null,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="name"
        label={t<string>("exercisesTable.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="type"
        label={t<string>("exercisesTable.type")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select options={exerciseTypeOptions} />
      </Form.Item>

      <Form.Item
        name="bodyParts"
        label={t<string>("exercisesTable.bodyPart")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select options={bodyPartOptions} mode="multiple" />
      </Form.Item>

      <Form.Item
        name="videoType"
        label={t<string>("exerciseForm.videoType")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select options={videoTypeOptions} placeholder={t("exerciseForm.selectFromList")} />
      </Form.Item>

      {renderVideoField()}

      <Form.Item name="description" label={t<string>("exerciseForm.description")}>
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item label={t<string>("exerciseForm.instruction")}>
        <Form.List name="instruction">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Form.Item
                    {...field}
                    key={field.key}
                    rules={[
                      {
                        required: true,
                        message: t<string>("common:validationErrors.fieldIsRequired"),
                      },
                    ]}
                  >
                    <Input
                      addonAfter={
                        <Button
                          size="small"
                          type="text"
                          icon={<DeleteOutlined className="text-red-500" />}
                          onClick={() => remove(field.key)}
                        />
                      }
                    />
                  </Form.Item>
                ))}
                <Button shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add("")}></Button>
              </div>
            );
          }}
        </Form.List>
      </Form.Item>

      {/*<Form.List name="links" >*/}
      {/*  {(fields, { add, remove }) => {*/}
      {/*    return (*/}
      {/*      <div>*/}
      {/*        <Form.Item label={t<string>("exerciseForm.additionalAttachments")}>*/}
      {/*          {fields.length < 4 && (*/}
      {/*            <Button*/}
      {/*              shape="circle"*/}
      {/*              size="small"*/}
      {/*              icon={<PlusOutlined />}*/}
      {/*              onClick={() => add({ mediaType: ExerciseVideoType.MEDIA_LIBRARY, id: null, title: "" })}*/}
      {/*            ></Button>*/}
      {/*          )}*/}
      {/*        </Form.Item>*/}
      {/*        {fields.map((field) => (*/}
      {/*          <div key={field.key} className="flex flex-col">*/}
      {/*            <Form.Item*/}
      {/*              name={[field.name, "mediaType"]}*/}
      {/*              label={t("exerciseForm.attachmentType")}*/}
      {/*              rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}*/}
      {/*            >*/}
      {/*              <Select options={videoTypeOptions} disabled placeholder={t("exerciseForm.selectFromList")} />*/}
      {/*            </Form.Item>*/}
      {/*            <Form.Item*/}
      {/*              name={[field.name, "id"]}*/}
      {/*              label={t("exerciseForm.video")}*/}
      {/*              rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}*/}
      {/*            >*/}
      {/*              <Select options={[]} placeholder={t("exerciseForm.selectFromList")} />*/}
      {/*            </Form.Item>*/}
      {/*            <Form.Item*/}
      {/*              name={[field.name, "title"]}*/}
      {/*              label={t("exerciseForm.attachmentTitle")}*/}
      {/*              rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}*/}
      {/*            >*/}
      {/*              <Input />*/}
      {/*            </Form.Item>*/}
      {/*            <Button*/}
      {/*              shape="circle"*/}
      {/*              size="small"*/}
      {/*              className="mx-14 -mt-3 mb-4 self-end"*/}
      {/*              danger*/}
      {/*              icon={<DeleteOutlined />}*/}
      {/*              onClick={() => remove(field.key)}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        ))}*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*</Form.List>*/}

      <Form.Item name="tags" label={t<string>("program.tags")}>
        <Select notFoundContent={false} options={tagsOptions} mode="tags" />
      </Form.Item>
    </Form>
  );
};

export default ExerciseForm;
