import { type TrainingProgramWithCreator } from "@fitness-app/data-models/entities/TrainingProgram";

import { type RequestStatus } from "../../../enums/requestStatus";

export const PROGRAMS_REDUCER_NAME = "programs";

export interface ProgramsReducer {
  list: TrainingProgramWithCreator[];
  listStatus: null | RequestStatus;
  programStatus: null | RequestStatus;
  creators: { label: string; value: string }[];
}
