import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import { type WorkoutTemplateWithCreator } from "@fitness-app/data-models/entities/WorkoutTemplate";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { getParentTrainerId, getUserRole } from "../../user/selectors";
import { WORKOUT_TEMPLATES_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchWorkoutTemplates = createAsyncThunk<WorkoutTemplateWithCreator[], Payload, AsyncThunkCreator<string>>(
  `${WORKOUT_TEMPLATES_REDUCER_NAME}/fetchWorkoutTemplates`,
  async (_payload, { getState, rejectWithValue, extra: { db, auth } }) => {
    const currentUser = await getLoggedUser(auth);
    const role = getUserRole(getState());
    const parentTrainerId = getParentTrainerId(getState());

    let query = db.from("workout_template").select("*, creator:createdBy(id, firstName, lastName)", { count: "exact" });

    if (role !== UserRole.TRAINER) {
      query = query.or(`createdBy.eq.${currentUser.id}, createdBy.eq.${parentTrainerId}, createdBy.is.${null}`);
    }

    const { data, error } = await query
      .order("createdAt", { ascending: false })
      .returns<WorkoutTemplateWithCreator[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
