import React, { useCallback, useEffect, useRef, useState } from "react";
import { CloudDownloadOutlined, PlayCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Modal, Space, Switch, Table, Typography, type InputRef } from "antd";
import { type ColumnType } from "antd/es/table";
import { type FilterConfirmProps } from "antd/es/table/interface";
import { useTranslation } from "react-i18next";

import { mediaLibraryActions, RequestStatus } from "@fitness-app/app-store";
import {
  VideoUploadStatus,
  type MediaLibraryItemWithLinkedExercise,
} from "@fitness-app/data-models/entities/MediaLibrary";

import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import VideoUploadList from "~/components/VideoUpload/VideoUploadList";
import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface MediaLibraryProps {
  width?: number;
}

export const statusMapper = {
  [VideoUploadStatus.Pending]: {
    badge: "warning",
  },
  [VideoUploadStatus.Queued]: {
    badge: "warning",
  },
  [VideoUploadStatus.Processing]: {
    badge: "processing",
  },
  [VideoUploadStatus.Encoding]: {
    badge: "processing",
  },
  [VideoUploadStatus.Ready]: {
    badge: "success",
  },
  [VideoUploadStatus.Failed]: {
    badge: "error",
  },
} as const;

const MediaLibrary = ({ width }: MediaLibraryProps) => {
  const dispatch = useAppDispatch();
  const { list, listStatus } = useAppSelector((store) => store.mediaLibrary);
  const { t } = useTranslation(["dashboard", "common"]);
  const [modalOpen, setModalOpen] = useState(false);
  const [video, setVideo] = useState<MediaLibraryItemWithLinkedExercise | null>(null);
  const [_searchText, setSearchText] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { isRegularTrainer } = useUserClaims();
  const [showOnlyVideoWithoutExercises, toggleShowOnlyVideoWithoutExercises] = useState(false);

  useEffect(() => {
    dispatch(mediaLibraryActions.subscribeToMediaLibrary());
    return () => {
      dispatch(mediaLibraryActions.unsubscribeFromMediaLibrary());
    };
  }, []);

  useEffect(() => {
    if (modalOpen) {
      void dispatch(mediaLibraryActions.fetchMediaLibraryFiles());
    }
  }, [modalOpen]);

  const handleReset = (clearFilters?: () => void) => {
    clearFilters?.();
    setSearchText("");
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0] ?? "");
  };

  const getColumnSearchProps = useCallback(
    (): ColumnType<MediaLibraryItemWithLinkedExercise> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close, clearFilters }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.search")}
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters);
                confirm({ closeDropdown: false });
              }}
              size="small"
              style={{ width: 90 }}
            >
              {t("common:button.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {t("common:button.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
      onFilter: (value, record) =>
        record.title
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    }),
    [handleSearch, handleReset, t],
  );

  return (
    <>
      <Button onClick={() => setModalOpen(true)} icon={<CloudDownloadOutlined />} disabled={isRegularTrainer}>
        {t("mediaLibrary.openMediaLibrary")}
      </Button>
      <Modal
        title="Biblioteka plików"
        width={width || 950}
        open={modalOpen}
        closable={false}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 240px)" }}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setModalOpen(false)}>
            {t("common:button.close")}
          </Button>,
        ]}
      >
        <Space direction="vertical" size={32} className="w-full">
          <VideoUploadList multiple height={100} />

          <div className="flex gap-x-2">
            <Switch checked={showOnlyVideoWithoutExercises} onChange={toggleShowOnlyVideoWithoutExercises} />
            <span>Pokaż tylko video bez podpiętych ćwiczeń</span>
          </div>

          <Table<MediaLibraryItemWithLinkedExercise>
            dataSource={showOnlyVideoWithoutExercises ? list.filter((item) => !item.exercises?.length) : list}
            loading={listStatus === RequestStatus.SUBSCRIBING}
            rowKey="id"
            pagination={false}
            locale={{ emptyText: t("mediaLibrary.emptyFilesList") }}
          >
            <Table.Column<MediaLibraryItemWithLinkedExercise>
              title="Nazwa"
              dataIndex="title"
              key="title"
              {...getColumnSearchProps()}
              render={(title: string, row) => {
                return (
                  <Space align="center">
                    {row.data.thumbnailUrl && (
                      <div className="relative">
                        <img alt="thumbnail" src={row.data.thumbnailUrl} className="h-auto max-w-[80px]" />
                        <div className="group absolute inset-0 flex items-center justify-center transition-all hover:bg-gray-100/70">
                          <PlayCircleOutlined
                            className="invisible text-green-700 group-hover:visible"
                            onClick={() => setVideo(row)}
                            style={{ fontSize: 24 }}
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      {title}
                      <div>
                        <Typography.Text type="secondary">
                          {row.exercises?.length
                            ? `Ćwiczenia: ${row.exercises?.map((exer) => exer.name).join(", ")}`
                            : "Brak podpiętych ćwiczeń"}
                        </Typography.Text>
                      </div>
                    </div>
                  </Space>
                );
              }}
            />
            <Table.Column
              title="Typ"
              dataIndex="type"
              key="type"
              render={(type: string) => t(`mediaLibrary.type.${type}`)}
            />
            <Table.Column
              title="Status"
              dataIndex={["data", "uploadStatus"]}
              key="status"
              render={(status: VideoUploadStatus) => (
                <Badge status={statusMapper[status].badge} text={t(`mediaLibrary.status.${status}`)} />
              )}
            />
          </Table>

          <VideoPlayer
            hideControl
            videoUrl={video?.data.hlsUrl || video?.data.mp4Url || null}
            visible={!!video}
            onClose={() => setVideo(null)}
          />
        </Space>
      </Modal>
    </>
  );
};

export default MediaLibrary;
