import { uniq } from "lodash";
import { v4 as uuid } from "uuid";

import {
  EmailClientTask,
  TaskType,
  type FieldValuesEmailClient,
  type FieldValuesEmailList,
  type TagsEmailClient,
  type TagsEmailList,
} from "@fitness-app/data-models/entities/Automation";
import { TemplateType } from "@fitness-app/data-models/entities/AutomationTemplate";
import { type EmailClient } from "@fitness-app/data-models/entities/ProductsSettings";
import {
  SurveyAutomationConditionType,
  SurveyQuestionType,
  type SurveyQuestion,
  type SurveyQuestionAutomation,
} from "@fitness-app/data-models/entities/SurveyTemplate";

import { LIST_ITEM_SEPARATOR } from "~/constants/separators";
import { type ISurveyQuestionAutomationForm } from "~/modules/SurveysTemplates/SurveyDetails/SurveyQuestionAutomationForm";

const paramsPattern = /[^{}]+(?=})/g;

export const formatModelIntoSurveyAutomationForm = (
  model: SurveyQuestionAutomation[],
): ISurveyQuestionAutomationForm => {
  const refactored = model
    .map((item) => {
      if (item.condition.type === SurveyAutomationConditionType.SpecificAnswer) {
        return {
          ...item,
          condition: {
            ...item.condition,
            answers: item.condition.answers.map((answer) =>
              typeof answer === "boolean" ? (answer ? "1" : "0") : answer,
            ),
          },
        };
      }

      return item;
    })
    .map((item) => {
      const tasks = item.tasks.map((task) => {
        if (task.type === TaskType.EmailClient) {
          if (task.action.type === EmailClientTask.AddTags || task.action.type === EmailClientTask.RemoveTags) {
            return {
              ...task,
              action: {
                ...task.action,
                emailClients: task.action.emailClients.map((list) =>
                  "id" in list
                    ? `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`
                    : `${list.client}${LIST_ITEM_SEPARATOR}${list.account}`,
                ),
              },
            };
          }

          if (task.action.type === EmailClientTask.AddToLists || task.action.type === EmailClientTask.RemoveFromLists) {
            return {
              ...task,
              action: {
                ...task.action,
                emailLists: task.action.emailLists.map(
                  (list) =>
                    `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`,
                ),
              },
            };
          }

          if (task.action.type === EmailClientTask.AddFieldValues) {
            return {
              ...task,
              action: {
                ...task.action,
                emailLists: task.action.emailLists.map((list) =>
                  "id" in list
                    ? `${list.client}${LIST_ITEM_SEPARATOR}${list.account}${LIST_ITEM_SEPARATOR}${list.id}${LIST_ITEM_SEPARATOR}${list.name}`
                    : `${list.client}${LIST_ITEM_SEPARATOR}${list.account}`,
                ),
                values: Object.entries(task.action.values).map(([key, value]) => ({ key, value })),
              },
            };
          }
        }

        return task;
      });

      return {
        ...item,
        tasks,
      };
    });

  return {
    automation: refactored as ISurveyQuestionAutomationForm["automation"],
    hasAutomation: refactored.length > 0,
  };
};
export const formatSurveyAutomationFormIntoModel = (
  formData: ISurveyQuestionAutomationForm,
  question: SurveyQuestion,
): SurveyQuestionAutomation[] => {
  const refactored = formData.automation
    .map((item) => {
      if (
        item.condition.type === SurveyAutomationConditionType.SpecificAnswer &&
        question.type === SurveyQuestionType.AB
      ) {
        return {
          ...item,
          condition: {
            ...item.condition,
            answers: item.condition.answers.map((answer) => (answer === "1" ? true : false)),
          },
        };
      }
      return item;
    })
    .map((item) => {
      const tasks = item.tasks.map((task) => {
        if (task.type === TemplateType.ScheduledMessages) {
          return {
            ...task,
            id: task.id || uuid(),
          };
        } else {
          // @ts-expect-error ingore
          const { messages, ...rest } = task;
          return {
            ...rest,
            id: task.id || uuid(),
          };
        }
      });

      return {
        ...item,
        tasks,
      };
    })
    .map((item) => {
      const tasks = item.tasks.map((task) => {
        if (task.type === TaskType.EmailClient) {
          if (task.action.type === EmailClientTask.AddTags || task.action.type === EmailClientTask.RemoveTags) {
            const lists: (TagsEmailList | TagsEmailClient)[] = task.action.emailClients.map((listStr) => {
              const [client, account, id, name] = (listStr as unknown as string).split(LIST_ITEM_SEPARATOR);
              if (id && name) {
                return {
                  client: client as EmailClient,
                  account,
                  id,
                  name,
                } as TagsEmailList;
              }
              return {
                client: client as EmailClient,
                account,
              } as TagsEmailClient;
            });
            return {
              ...task,
              action: {
                ...task.action,
                emailClients: lists,
              },
            };
          }

          if (task.action.type === EmailClientTask.AddToLists || task.action.type === EmailClientTask.RemoveFromLists) {
            const lists = task.action.emailLists.map((listStr) => {
              const [client, account, id, name] = (listStr as unknown as string).split(LIST_ITEM_SEPARATOR);
              return {
                client: client as EmailClient,
                account,
                id,
                name,
              };
            });

            return {
              ...task,
              action: {
                ...task.action,
                emailClients: lists,
              },
            };
          }

          if (task.action.type === EmailClientTask.AddFieldValues) {
            const lists: (FieldValuesEmailList | FieldValuesEmailClient)[] = task.action.emailLists.map((listStr) => {
              const [client, account, id, name] = (listStr as unknown as string).split(LIST_ITEM_SEPARATOR);
              if (id && name) {
                return {
                  client: client as EmailClient,
                  account,
                  id,
                  name,
                } as FieldValuesEmailList;
              }
              return {
                client: client as EmailClient,
                account,
              } as FieldValuesEmailClient;
            });

            return {
              ...task,
              action: {
                ...task.action,
                emailClients: lists,
                values: task.action.values
                  ? // @ts-expect-error ignore

                    Object.fromEntries(task.action.values.map((item) => [item.key, item.value]))
                  : {},
              },
            };
          }
        }

        if (task.type === TemplateType.ScheduledEmails) {
          return {
            ...task,
            variables:
              uniq([...(task.content?.match(paramsPattern) || []), ...(task.subject?.match(paramsPattern) || [])]) ||
              [],
          };
        }

        if (task.type === TemplateType.ScheduledMessages) {
          return {
            ...task,
            variables: uniq(
              task.messages
                .flat()
                .map((message) => message?.match(paramsPattern))
                .filter((item) => item)
                .flat() || [],
            ),
          };
        }

        return task;
      });

      return {
        ...item,
        tasks,
      };
    });

  return refactored as SurveyQuestionAutomation[];
};
