import { createAsyncThunk } from "@reduxjs/toolkit";

import { ClientActivityWithCalculatedScore } from "@fitness-app/data-models/domain/services/ClientActivityWithCalculatedScore";
import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import {
  type ClientActivity,
  type ClientActivityWithScoreAndTrainer,
} from "@fitness-app/data-models/entities/ClientActivity";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import { getPageRange } from "../../../helpers/getPageRange";
import { type AsyncThunkCreator } from "../../../index";
import { TRAINEES_REDUCER_NAME } from "../types";

type Payload = {
  page?: number;
  assignedTrainerId?: string | null;
} | void;

export const fetchTraineesActivity = createAsyncThunk<
  {
    page: number;
    list: ClientActivityWithScoreAndTrainer[];
    totalPages: number;
    count: number | null;
  },
  Payload,
  AsyncThunkCreator<string>
>(`${TRAINEES_REDUCER_NAME}/fetchTraineesActivity`, async (payload, { rejectWithValue, extra: { db }, getState }) => {
  const { activitiesMetadata } = getState().trainees;

  const { start, stop, currentPage } = getPageRange(payload?.page, activitiesMetadata.listSize);

  let query = db
    .from("client_activity")
    .select(
      "*, trainee!inner(email, id, createdAt, userId, firstName, lastName, assignedTrainerId, tags, activeTrainingProgramId, hasActiveTrainingProgram, assignedTrainer:assignedTrainerId(id, firstName, lastName, userId, email))",
      {
        count: "exact",
      },
    )
    .eq("accessType", AppAccessType.FullAccess)
    .eq("trainee.status", TraineeStatus.ACTIVE)
    .order("lastActionAt", { ascending: true });

  if (payload?.assignedTrainerId) {
    if (payload.assignedTrainerId === "none") {
      query = query.is("trainee.assignedTrainerId", null);
    } else {
      query = query.eq("trainee.assignedTrainerId", payload.assignedTrainerId);
    }
  } else if (activitiesMetadata.currentAssignedTrainerFilter) {
    query = query.eq("trainee.assignedTrainerId", activitiesMetadata.currentAssignedTrainerFilter);
  }

  const { error, data, count } = await query
    .range(start, stop)
    .limit(activitiesMetadata.listSize)
    .returns<ClientActivity[]>();

  if (error) {
    return rejectWithValue(error.message);
  }

  return {
    list: data.map(
      (activity) =>
        new ClientActivityWithCalculatedScore(activity)
          .calculateScore()
          .getReport() as ClientActivityWithScoreAndTrainer,
    ),
    page: currentPage,
    totalPages: count ? Math.ceil(count / activitiesMetadata.listSize) : 0,
    count,
  };
});
