import React, { useMemo, type FunctionComponent } from "react";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Dropdown, List, Menu, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import localizedFormat from "dayjs/plugin/localizedFormat";
import keyBy from "lodash.keyby";
import truncate from "lodash.truncate";
import { shallowEqual } from "react-redux";

import { chatActions } from "@fitness-app/app-store";
import { UserRole } from "@fitness-app/data-models";
import { ChatStatus, type ChatChannelWithLastMessage, type ChatMember } from "@fitness-app/data-models/entities/Chat";
import { CALENDAR_LABELS } from "@fitness-app/utils/src/constants/calendar";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

import("dayjs/locale/pl");

dayjs.extend(localizedFormat);
dayjs.extend(calendar);

interface OwnProps {
  channel: ChatChannelWithLastMessage;
  archived: boolean;
  changeTab: (key: ChatStatus) => void;
}

type Props = OwnProps;

const renderDate = (timestamp: Date | string) => dayjs(timestamp).locale("pl").calendar(null, CALENDAR_LABELS);

const ChatChannelItem: FunctionComponent<Props> = ({ channel, archived, changeTab }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((store) => store.user.data?.id);
  const { isTrainer, isClient } = useUserRole();
  const updatingChannelId = useAppSelector((store) => store.chat.updatingChannelId);
  const usersArray: ChatMember[] = useMemo(() => Object.values(channel.members), [channel.members]);
  const members = channel.members ? usersArray.filter((chatMember) => chatMember.uid !== userId) : null;

  const unreadForChannel = (
    useAppSelector((store) => store.chat.unreadMessages?.perChannel?.[channel.id], shallowEqual) || []
  ).length;
  const clients = usersArray.filter((user) => user.role === UserRole.CLIENT);
  const trainers = usersArray.filter((user) => user.role === UserRole.TRAINER);
  const teamMembers = usersArray.filter((user) => user.role === UserRole.TEAM_MEMBER && !user.disabled);
  const profilesById = channel.profiles ? keyBy(channel.profiles, "id") : null;

  const renderDescription = () => {
    if (archived) {
      return `Zarchiwizowano ${dayjs(channel.updatedAt).format("DD-MM-YY HH:mm")}`;
    }
    if (!channel.lastMessage?.createdAt) return "Napisz pierwszą wiadomość!";
    return (
      <div>
        <div>{renderDate(channel.lastMessage.createdAt)}</div>
        <div>
          {truncate(channel.lastMessage.content ? channel.lastMessage.content : "Przesłano załącznik", { length: 30 })}
        </div>
      </div>
    );
  };
  const renderTitle = (chatMember?: ChatMember) => {
    if (clients.length === 1) {
      const [member] = chatMember ? [chatMember] : clients;

      const profile = member ? profilesById?.[member.uid] || member : member;

      if (!profile) return "Rozmowa";

      return <>{"name" in profile ? profile.name : `${profile.firstName} ${profile.lastName}`}</>;
    }

    if (members?.length === 1) {
      const [member] = members;

      const profile = member ? profilesById?.[member.uid] || member : member;

      if (!profile) return "Rozmowa";

      return <>{"name" in profile ? profile.name : `${profile.firstName} ${profile.lastName}`}</>;
    }
    return "Rozmowa grupowa";
  };

  if (!members?.length) {
    return null;
  }

  if (members.length === 1 || clients.length === 1) {
    const [member] = isClient ? (teamMembers.length ? teamMembers : trainers) : clients.length ? clients : members;

    return (
      <Tooltip placement="left" title={archived ? "Aktywuj czat aby móc zobaczyć treść i zacząć pisać" : undefined}>
        <List.Item
          onClick={() => {
            if (archived) return;
            dispatch(chatActions.addChannelToList(channel));
          }}
          className="px-2"
          // className={classNames(classes.dropdownMenu, archived && classes.archived)}
        >
          <List.Item.Meta
            avatar={
              <div>
                <Badge count={unreadForChannel}>
                  <Avatar
                    shape="circle"
                    icon={<UserOutlined />}
                    src={member?.uid && profilesById ? profilesById[member.uid]?.avatarUrl : member?.avatarUrl}
                    size={38}
                  />
                </Badge>
              </div>
            }
            title={renderTitle(member)}
            description={<div style={{ fontSize: 13 }}>{renderDescription()}</div>}
          />
          {member?.role !== UserRole.SYSTEM && isTrainer && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <a
                      role="button"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await dispatch(
                          chatActions.updateChannelStatus({
                            channelId: channel.id,
                            status: archived ? ChatStatus.Active : ChatStatus.Archived,
                          }),
                        );
                        if (archived) {
                          changeTab(ChatStatus.Active);
                        }
                      }}
                      // className={classes.menuItem}
                    >
                      {archived ? "Aktywuj" : "Zarchiwizuj"}
                    </a>
                  </Menu.Item>
                </Menu>
              }
              trigger={["hover"]}
            >
              {updatingChannelId === channel.id ? <Spin size="small" /> : <SettingOutlined />}
            </Dropdown>
          )}
        </List.Item>
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="left" title={archived ? "Aktywuj czat aby móc zobaczyć treść i zacząć pisać" : undefined}>
      <List.Item
        onClick={() => {
          if (archived) return;
          dispatch(chatActions.addChannelToList(channel));
        }}
        // className={classNames(classes.dropdownMenu, archived && classes.archived)}
      >
        <List.Item.Meta
          avatar={
            <div>
              <Badge count={unreadForChannel}>
                <Avatar.Group>
                  {members.map((member) => (
                    <Avatar shape="circle" icon={<UserOutlined />} src={member?.avatarUrl} size={38} key={member.uid} />
                  ))}
                </Avatar.Group>
              </Badge>
            </div>
          }
          title={renderTitle()}
          description={<div style={{ fontSize: 13 }}>{renderDescription()}</div>}
        />
        {isTrainer && (
          <Dropdown
            overlay={
              <Menu onClick={({ domEvent }) => domEvent.stopPropagation()}>
                <Menu.Item>
                  <a
                    role="button"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await dispatch(
                        chatActions.updateChannelStatus({
                          channelId: channel.id,
                          status: archived ? ChatStatus.Active : ChatStatus.Archived,
                        }),
                      );
                      if (archived) {
                        changeTab(ChatStatus.Active);
                      }
                    }}
                  >
                    {archived ? "Aktywuj" : "Zarchiwizuj"}
                  </a>
                </Menu.Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            {updatingChannelId === channel.id ? <Spin size="small" /> : <SettingOutlined />}
          </Dropdown>
        )}
      </List.Item>
    </Tooltip>
  );
};

export default ChatChannelItem;
