import React, { useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Collapse, Form, Input, InputNumber, Row, Select, Switch, TreeSelect } from "antd";
import { type FormInstance } from "antd/lib/form";
import omit from "lodash.omit";
import { useTranslation } from "react-i18next";

import { nutrientsModel, type Nutrients } from "@fitness-app/data-models/entities/Ingredient";

import ImageUploadField from "~/components/ImageUploadField/ImageUploadField";
import { useNutritionCategories } from "~/modules/Nutrition/hooks/useNutritionCategories";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { useAppSelector } from "~/store/initializeStore";
import { type AddIngredientFormModel } from "./types";

interface AddIngredientFormProps {
  formController?: FormInstance<AddIngredientFormModel>;
  onSubmit: (formData: AddIngredientFormModel) => void;
  model?: Partial<AddIngredientFormModel> | null;
}

const caloriesUnits = [
  {
    label: "kcal / 100 g",
    value: "grams",
  },
  {
    label: "kcal / 100 ml",
    value: "millilitres",
  },
];

const numberProps = {
  addonAfter: "g",
  min: 0,
  max: 1000,
  step: 0.1,
  precision: 1,
};

const { Option } = Select;

const packageUnits = {
  grams: "g",
  millilitres: "ml",
};

const defaultModel = {
  caloriesUnit: "grams",
  nutritionPer: "grams",
  nutrients: Object.fromEntries(Object.keys(nutrientsModel).map((key) => [key as keyof Nutrients, null])),
  productTypes: [],
  packageSize: null,
  calories: 0,
  name: "",
  brand: null,
  manufacturer: null,
  liquid: false,
  image: null,
  barCode: null,
  measures: [{ value: 9, weightPerUnit: 0, unit: "grams" }],
} as const;

const AddIngredientForm = ({ model, onSubmit, formController }: AddIngredientFormProps) => {
  const { t } = useTranslation(["nutrition", "common"]);
  const userData = useAppSelector((store) => store.user.data);
  const { categories } = useNutritionCategories();
  const { measureOptions } = useNutritionMeasures();

  const [packageUnit, setPackageUnit] = useState<"grams" | "millilitres">("grams");
  const watchedMeasures = Form.useWatch("measures", formController);
  const updateUnits = (value: "grams" | "millilitres") => {
    setPackageUnit(value);
  };

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const handleSubmit = (formData: AddIngredientFormModel) => {
    onSubmit({
      ...defaultModel,
      ...formData,
      nutrients: {
        ...defaultModel.nutrients,
        ...formData.nutrients,
      },
    });
  };

  const hasModel = model !== null;

  return (
    <Form<AddIngredientFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={defaultModel}
      labelWrap
      onFinish={handleSubmit}
    >
      <Form.Item
        tooltip={t<string>("ingredientForm.imageAdditionalInfo")}
        name="image"
        label={t<string>("ingredientForm.image")}
        valuePropName="fileList"
        getValueFromEvent={(e: unknown) => e}
      >
        <ImageUploadField storageRef={`${userData?.id}/ingredients`} multiple={false} />
      </Form.Item>

      <Form.Item
        name="name"
        label={t<string>("ingredientForm.name")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="mainCategory"
        label={t<string>("ingredientForm.category")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <TreeSelect treeData={categories} />
      </Form.Item>

      <Form.Item name="brand" label={t<string>("ingredientForm.brand")}>
        <Input />
      </Form.Item>

      <Form.Item name="manufacturer" label={t<string>("ingredientForm.manufacturer")}>
        <Input />
      </Form.Item>

      <Form.Item name="barCode" label={t<string>("ingredientForm.barCode")}>
        <Input placeholder="np. 5901939103075" />
      </Form.Item>

      <Form.Item name="liquid" label={t<string>("ingredientForm.liquid")} valuePropName="checked">
        <Switch disabled={hasModel} />
      </Form.Item>

      <Form.Item
        name="calories"
        label={t("ingredientForm.calories")}
        rules={[
          {
            required: true,
            message: t<string>("validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <InputNumber
          addonAfter={
            <Form.Item name="caloriesUnit" noStyle>
              <Select onChange={updateUnits} disabled={hasModel}>
                {caloriesUnits.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          }
        />
      </Form.Item>

      <Form.Item
        name="nutritionPer"
        label={t("ingredientForm.nutritionPer")}
        rules={[
          {
            required: true,
            message: t<string>("validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          disabled={hasModel}
          style={{ maxWidth: 200 }}
          options={[
            { label: t("ingredientForm.perGrams"), value: "grams" },
            { label: t("ingredientForm.perMl"), value: "millilitres" },
            { label: t("ingredientForm.piece"), value: "piece" },
          ]}
        />
      </Form.Item>

      <Form.List
        name="measures"
        rules={[
          {
            validator: async (_, levels: string[]) => {
              if (!levels || levels.length < 1) {
                return Promise.reject(new Error("At least 1 level"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <div
                    key={field.key}
                    className="border-rounded-md relative mb-6 flex items-center rounded-md border	 border-dashed border-gray-200 bg-gray-50 pt-4"
                  >
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 24 }}
                      name={[field.name, "value"]}
                      label={t<string>("ingredientForm.measure")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                      className="flex-1"
                    >
                      <Select
                        options={
                          index === 0
                            ? measureOptions.filter((measure) => ["package", "piece"].includes(measure.name))
                            : measureOptions
                        }
                        style={{ maxWidth: "90%" }}
                      />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 14 }}
                      name={[field.name, "weightPerUnit"]}
                      label={t<string>("ingredientForm.weightPerUnit")}
                      rules={[
                        {
                          required: true,
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                      ]}
                      className="flex-1"
                    >
                      {index === 0 ? (
                        <InputNumber
                          min={0}
                          step={1}
                          precision={0}
                          addonAfter={packageUnit in packageUnits ? packageUnits[packageUnit] : "g"}
                        />
                      ) : (
                        <InputNumber
                          min={0}
                          step={1}
                          precision={0}
                          addonAfter={
                            watchedMeasures[index]?.unit
                              ? watchedMeasures[index]?.unit === "grams"
                                ? t("gramsAbv")
                                : t("millilitersAbv")
                              : null
                          }
                        />
                      )}
                    </Form.Item>
                    {index !== 0 && (
                      <Form.Item
                        name={[field.name, "unit"]}
                        label={t<string>("ingredientForm.unit")}
                        rules={[
                          {
                            required: true,
                            message: t<string>("common:validationErrors.fieldIsRequired"),
                          },
                        ]}
                        className="flex-1"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                      >
                        <Select
                          options={[
                            { label: t("gramsAbv"), value: "grams" },
                            { label: t("millilitersAbv"), value: "ml" },
                          ]}
                          style={{ maxWidth: 100 }}
                        />
                      </Form.Item>
                    )}

                    {index !== 0 && (
                      <DeleteOutlined
                        style={{
                          fontSize: 18,
                          marginRight: 10,
                          marginBottom: 24,
                          color: "red",
                        }}
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                );
              })}
              <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
                <Button
                  type="dashed"
                  onClick={() => add({ unit: "grams", energyPerUnit: 0, value: 2 })}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("ingredientForm.addNewMeasure")}
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>

      <Form.Item name="productTypes" label={t("ingredientType.title")}>
        <Checkbox.Group>
          <Row>
            <Col span={8}>
              <Checkbox value="isVegan" style={{ lineHeight: "32px" }}>
                {t("ingredientType.vegan")}
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="isVegetarian" style={{ lineHeight: "32px" }}>
                {t("ingredientType.vegetarian")}
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="isDiaryFree" style={{ lineHeight: "32px" }}>
                {t("ingredientType.dairyFree")}
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="isGlutenFree" style={{ lineHeight: "32px" }}>
                {t("ingredientType.glutenFree")}
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="isHighProtein" style={{ lineHeight: "32px" }}>
                {t("ingredientType.highProtein")}
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="rawIngredients" label={t("ingredientForm.rawIngredients")}>
        <Input.TextArea />
      </Form.Item>

      <Row>
        <Col xs={24} lg={12}>
          <Form.Item
            name={["nutrients", "fat"]}
            label={t("nutrients.fat")}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
          <Form.Item
            name={["nutrients", "saturatedFat"]}
            label={t("nutrients.includesSaturatedFats")}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
          <Form.Item
            name={["nutrients", "carbohydrates"]}
            label={t("nutrients.carbohydrates")}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
          <Form.Item
            name={["nutrients", "sugars"]}
            label={t("nutrients.includesAddedSugars")}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name={["nutrients", "protein"]}
            label={t("nutrients.protein")}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
          <Form.Item
            name={["nutrients", "fiber"]}
            label={t(`nutrients.fiber`)}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
          <Form.Item
            name={["nutrients", "salt"]}
            label={t(`nutrients.salt`)}
            rules={[
              {
                type: "number",
                message: t<string>("common:validationErrors.wrongNumberFormat"),
              },
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <InputNumber {...numberProps} />
          </Form.Item>
        </Col>
      </Row>
      <Collapse ghost>
        <Collapse.Panel key="1" id="1" header={t("ingredientForm.additionalInfo")}>
          <Row>
            {Object.entries(
              omit(nutrientsModel, [
                "protein",
                "calories",
                "salt",
                "fiber",
                "fat",
                "sugars",
                "carbohydrates",
                "saturatedFat",
                "carbohydrateExchangers",
                "digestibleCarbs",
              ]),
            ).map(([ingredientKey, units]) => (
              <Col xs={24} lg={12} key={ingredientKey}>
                <Form.Item
                  name={["nutrients", ingredientKey]}
                  label={t(`nutrients.${ingredientKey}`)}
                  preserve
                  rules={[
                    {
                      type: "number",
                      message: t<string>("common:validationErrors.wrongNumberFormat"),
                    },
                  ]}
                >
                  <InputNumber {...numberProps} addonAfter={Array.isArray(units) ? units[0] : units} />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};

export default AddIngredientForm;
